import { Grid, Typography } from "@mui/material";
import React, { Fragment } from "react";
import ThemeCard from "./ThemeCard";
import "../../App.css";

import { useAppSelector } from "../../hooks";
const ThemesList = () => {
  const { occasionDetails } = useAppSelector((state) => state.Themes);

  return (
    <Fragment>
      <Typography>Themes ({occasionDetails.Themes?.length})</Typography>
      <Grid
        container
        spacing={3}
        height={"100%"}
        overflow={"scroll"}
        className="hide-scrollbar"
      >
        {occasionDetails.Themes?.map((item: any) => (
          <Grid key={item.id} item xs={12} sm={6} md={3}>
            <ThemeCard cardDetails={item} />
          </Grid>
        ))}
      </Grid>
    </Fragment>
  );
};

export default ThemesList;
