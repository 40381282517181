import { Box, TextField, Typography } from "@mui/material";
// import welcome from "../../../assets/welcome.png";
import eye from "../../assets/svgs/eye.svg";
import eyeOff from "../../assets/svgs/eye-off.svg";
import ButtonAuth from "../../components/ButtonAuth";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { loginSchema } from "../../helpers/loginValidatoin";
import HelperModule from "../../helpers/HelperModule";
import ApiServices from "../../helpers/ApiServices";
import { setIsLogin } from "../../store/AuthReducer";
import { useDispatch } from "react-redux";
import { enqueueSnackbar } from "notistack";

type PictureComponentProp = {
  md: boolean;
  sm: boolean;
};

type LoginCredentials = {
  email: string;
  password: string;
};

type TypeloginCredentialsError = {
  email: string | null;
  password: string | null;
};

function LoginScreen({ md, sm }: PictureComponentProp) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [credentials, setCredentials] = useState<LoginCredentials>({
    email: "",
    password: "",
  });

  const [loginCredentialsError, setLoginCredentialsError] =
    useState<TypeloginCredentialsError>({
      email: null,
      password: null,
    });

  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    let enquebar = localStorage.getItem("enqueLogout");
    if (enquebar) {
      enqueueSnackbar("Logged out Successfully", {
        variant: "success",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        style: { fontFamily: "Poppins", fontWeight: "500" },
      });
    }
    localStorage.removeItem("enqueLogout");
  }, []);

  const handleCredentialsChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    setCredentials((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const validation = (credentials: object) => {
    try {
      loginSchema.parse(credentials);
      setLoginCredentialsError({ password: null, email: null });
      return true;
    } catch (error: any) {
      if (error.formErrors) {
        setLoginCredentialsError(error.formErrors.fieldErrors);
        return false;
      }
    }
  };

  const handleLogin = async () => {
    if (validation(credentials)) {
      try {
        const response = await HelperModule().postMethod(
          ApiServices.base_URL + ApiServices.login,
          credentials
        );
        if (response.success === true) {
          dispatch(setIsLogin(true));
          sessionStorage.setItem("token", response.data.token); // this is for authenticated token
          localStorage.setItem("token", response.data.token); // for admin profile change purpose
          localStorage.setItem("permissions", response?.data.Permissions); // for the sidebar options
          localStorage.setItem("enquebar", "1"); // to show the successs alert in the dashboard
          navigate("/");
        } else {
          console.log("response: ", response.response.data.message);
          enqueueSnackbar(response.response.data.message, {
            variant: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
            style: { fontFamily: "Poppins", fontWeight: "500" },
          });
        }
      } catch (error: any) {
        console.log("error: ", error);
      }
    }
  };

  const handleKeyPress = (e: any) => {
    if (e.key === "Enter") {
      // Trigger handleLogin when Enter key is pressed
      handleLogin();
    }
  };
  return (
    <Box
      paddingRight={!md ? 0 : 1}
      minHeight={!md ? "50%" : "77vh"}
      width={!md ? "100%" : "55%"}
      sx={{
        background: "#FFF",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginTop: !sm ? '40px' : !md ? "15px" : "0px",
        paddingY: !sm ? 'auto' : !md ? '10px' : 'auto'
      }}
    >
      <Box
        style={{
          width: !md ? "90%" : "70%",
          padding: !md ? 0 : 5,
          margin: "auto",
        }}
      >
        <Box
          sx={{
            width: "100%",
            textAlignLast: !md ? "center" : "left",
          }}
        >
          <Typography
            style={{
              fontSize: "22px",
              color: "rgba(16, 81, 142, 1)",
            }}
          >
            Sign in
          </Typography>
          <Typography
            sx={{
              color: "#737373",
              marginTop: "0.4rem",
              marginBottom: "1rem",
              fontSize: "0.9rem",
            }}
          >
            Access your account by logging In.
          </Typography>
        </Box>
        <Box
          mt={1}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "0.6rem",
            marginTop: !md ? "10px" : "20px",
            width: "100%",
          }}
        >
          <TextField
            fullWidth
            hiddenLabel
            placeholder="Enter your Email"
            value={credentials.email}
            onChange={handleCredentialsChange}
            variant="outlined"
            name="email"
            sx={{
              // width: !md ? "23rem" : "30rem",
              borderRadius: "0.4rem",
              outlineStyle: "none",
            }}
            InputProps={{
              style: { borderRadius: 14, padding: !sm ? 2 : !md ? 9 : 0 },
            }}
          />
          {loginCredentialsError?.email && (
            <Typography style={{ color: "red", fontSize: "13px" }}>
              {loginCredentialsError?.email[0]}
            </Typography>
          )}
          <span
            style={{ position: "relative", marginTop: "10px", width: "100%" }}
          >
            <TextField
              fullWidth
              hiddenLabel
              placeholder="Enter password"
              name="password"
              type={showPassword ? "text" : "password"}
              value={credentials.password}
              onChange={handleCredentialsChange}
              variant="outlined"
              sx={{
                borderRadius: "0.4rem",
                outlineStyle: "none",
              }}
              InputProps={{
                style: { borderRadius: 14, padding: !sm ? 2 : !md ? 9 : 0 },
              }}
              onKeyDown={handleKeyPress}
            />
            <img
              src={showPassword ? eyeOff : eye}
              onClick={() => setShowPassword(!showPassword)}
              alt="show-password"
              style={{
                objectFit: "contain",
                width: !sm ? "8%" : "5%",
                position: "absolute",
                top: "30%",
                right: "5%",
                cursor: "pointer",
              }}
            />
          </span>
          {loginCredentialsError?.password && (
            <Typography style={{ color: "red", fontSize: "13px" }}>
              {loginCredentialsError?.password[0]}
            </Typography>
          )}
          <Typography
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <Link
              to="/forgot"
              style={{
                textDecoration: "none",
                fontSize: "12px",
                marginTop: "5px",
                color: "rgba(138, 138, 138, 1)",
              }}
            >
              Forgot Password
            </Link>
          </Typography>
        </Box>
        <ButtonAuth onClick={handleLogin} md={md} text="Sign In" />
      </Box>
    </Box>
  );
}

export default LoginScreen;
