import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  setCreateMessageModal,
  setMessageAddedSuccess,
} from "../../store/ThemesReducer";
import HelperModule from "../../helpers/HelperModule";
import ApiServices from "../../helpers/ApiServices";
import { useParams } from "react-router-dom";
import { useMediaQueryHook } from "../../hooks/useMediaQueryHook";

const AddMessageModal = () => {
  const { md } = useMediaQueryHook();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { createMessageModal } = useAppSelector((state) => state.Themes);
  const [messages, setMessages] = useState<string[]>([]);
  const [messageText, setMessageText] = useState<string>("");

  const handleAddMesages = () => {
    if (messageText) {
      setMessages((prev: any) => [...prev, messageText]);
      setMessageText("");
    }
  };

  const handleSubmit = async () => {
    const token = sessionStorage.getItem("token");
    const formData = new FormData();

    messages.forEach((message) => formData.append("messages[]", message));
    formData.append("id", `${id}`);
    formData.append("key", "Message");
    try {
      const response = await HelperModule().postFormDataMethod(
        ApiServices.base_URL + ApiServices.add_theme_and_message,
        formData,
        { Authorization: token }
      );
      if (response.success) {
        dispatch(setCreateMessageModal(false));
        dispatch(setMessageAddedSuccess(true));
        setMessages([]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Modal
      open={createMessageModal}
      onClose={() => dispatch(setCreateMessageModal(false))}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 3,
          minWidth: !md ? 300 : 600,
          maxWidth: 600,
          borderRadius: 2,
        }}
      >
        <Typography>Add Messages</Typography>
        <Box marginTop={2}>
          <Typography fontSize={"12px"}>Enter your Message</Typography>
          <Box display={"flex"} mt={1} gap={1} alignItems={"center"}>
            <TextField
              fullWidth
              type="text"
              placeholder="type here..."
              required
              size="small"
              variant="outlined"
              value={messageText}
              sx={{
                "& fieldset": { border: "none" },
                border: "1px solid lightgrey",
                borderRadius: "10px",
                paddingY: "5px",
              }}
              inputProps={{
                style: {
                  padding: "7px 10px",
                  fontSize: "14px",
                },
              }}
              onChange={(e: any) => setMessageText(e.target.value)}
            />
            <Button
              sx={{
                // marginTop: "10px",
                bgcolor: "rgba(242, 234, 255, 1)",
                textTransform: "none",
                color: "rgb(108,62,185)",
                border: "none",
                //   height: "100",
                padding: "0px",
                height: "35px",
                "&:hover": {
                  bgcolor: "rgb(82, 40, 153)", // Maintain the same background color on hover
                  border: "none",
                  color: "white",
                },
                "&:focus": {
                  outline: "none", // Remove the focus outline
                },
              }}
              onClick={handleAddMesages}
            >
              Add
            </Button>
          </Box>
          <Box display={"flex"} gap={1} mt={2} flexWrap={"wrap"}>
            {messages.map((message) => (
              <Typography
                bgcolor={"rgba(248, 248, 248, 1)"}
                paddingX={2}
                paddingY={"3px"}
                fontSize={"14px"}
                borderRadius={3}
              >
                {message}
              </Typography>
            ))}
          </Box>
          <Box marginTop={2} gap={2} display={"flex"}>
            <Button
              sx={{
                bgcolor: "rgba(242, 234, 255, 1)",
                textTransform: "none",
                color: "rgb(108,62,185)",
                border: "none",
                borderRadius: "15px",
                padding: "5px",
                fontSize: "12px",
                "&:hover": {
                  bgcolor: "rgb(82, 40, 153)", // Maintain the same background color on hover
                  border: "none",
                  color: "white",
                },
                "&:focus": {
                  outline: "none", // Remove the focus outline
                },
              }}
              onClick={handleSubmit}
            >
              save
            </Button>
            <Button
              sx={{
                textTransform: "none",
                color: "red",
                border: "none",
                fontSize: "12px",
                padding: "0px",
                "&:hover": {
                  bgcolor: "rgba(242, 234, 255, 1)", // Maintain the same background color on hover
                  border: "none",
                  color: "red",
                },
                "&:focus": {
                  outline: "none", // Remove the focus outline
                },
              }}
              onClick={() => dispatch(setCreateMessageModal(false))}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddMessageModal;
