const ApiServices = {
    base_URL: "https://adminpanel.gift4day.com/api/v1/admin/",
    section_details: 'web_settings',
    login: 'admin_login',
    send_otp: 'send_otp',
    verify_otp: 'verify_otp',
    update_settings: 'update_settings',
    update_image: 'update_image',
    reset_password: 'reset_password',
    delete_hero_images: "web_settings",
    profile_details: 'getProfile',
    email_change_otp: "email_change_otp",
    update_profile: 'update-profile',
    email_verify: 'email_verify',
    payments_api: 'payments',
    change_password: 'updatePassword',
    create_subAdmin: 'add_user',
    edit_subAdmin: 'edit_user',
    get_sub_admins: "get_all_subadmin",
    create_occasion: 'occasion',
    get_occasions: 'occasions',
    insight_data: 'InsightData',
    overview: 'Overview',
    totalContribution: 'totalContribution',
    update_occasion_status: 'occasion',
    add_theme_and_message: 'addThemeMessage',
    update_theme_and_message: 'theme_message',
    get_users: 'users',
    get_user_detail: 'user',
    user_events: 'users/events',
    userContribution: 'users/total-contribution',
    userDelete: 'delete-User/',
    editUser: 'user',
    userCards: 'users/records',
    event_details: 'users/events-details/',
    event_response: 'event-responses',
    notifications: 'notifications',
    get_transctions:'getTransaction'

};

export default ApiServices;
