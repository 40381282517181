import { Box, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useMediaQueryHook } from "../../hooks/useMediaQueryHook";
import HelperModule from "../../helpers/HelperModule";
import ApiServices from "../../helpers/ApiServices";
import upload from "../../assets/svgs/upload.svg";
const RoadMap = ({
  handleChangeCallback,
  data,
  handleAddFilesCallback,
  handleIdChangeCallback,
}: any) => {
  console.log(data, "roadmap data");
  const { md } = useMediaQueryHook();

  useEffect(() => {
    getData();
  }, []);

  const handleChange = (index: any, key: any, value: any) => {
    handleChangeCallback((prevData: any) => {
      const newData: any = [...prevData];
      newData[index][key] = value;
      return newData;
    });
  };

  const previewImage = (file: any, index: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const imageData = reader.result;
        console.log("imageData: ", imageData);
        resolve(imageData);
        handleChange(index, "Changed_Url", imageData);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const handleImageChange = (index: any, event: any, id: any) => {
    const imageObject = event.target.files[0];
    handleAddFilesCallback((prev: any) => [...prev, imageObject]);
    handleIdChangeCallback((prev: any) => [...prev, id]);
    previewImage(event.target.files[0], index);
  };

  const getData = async () => {
    const token = await sessionStorage.getItem("token");
    try {
      const response = await HelperModule().getMethodWithHeaders(
        ApiServices.base_URL +
        ApiServices.section_details +
        "?search_param=roadmap",
        { Authorization: token }
      );
      handleChangeCallback(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Box
      width={!md ? "89%" : "70%"}
      maxHeight={"64vh"}
      overflow={"scroll"}
      paddingX={!md ? 1 : 2}
      mx={"auto"}
      marginLeft={!md ? 2 : "auto"}
      sx={{
        "&::-webkit-scrollbar": {
          width: "0px", // Remove scrollbar width
          background: "transparent", // Optional: Hide scrollbar track
        },
      }}
    >
      {data?.length > 0 && (
        <Box>
          <Box marginTop={1}>
            <Box display={'flex'} alignItems='center' justifyContent={"space-between"}>
              <Typography
                sx={{ fontSize: "14px", color: "rgba(0, 0, 0, 1)" }}
              >
                Image
              </Typography>
              <Typography fontSize={'12px'}> <span style={{ color: 'red', }}>Image criteria :</span> Dimensions should be 700*500</Typography>
            </Box>
            <Box position={"relative"}>
              <Box
                border={"1px solid grey"}
                justifyContent={"center"}
                display={"flex"}
                bgcolor={"#f8f8f8"}
              >
                {data[0].Changed_Url ? (
                  <img
                    src={data[0].Changed_Url}
                    alt="image"
                    width={!md ? "150px" : "250px"}
                    style={{ margin: "auto" }}
                  />
                ) : (
                  <img
                    src={data[0].ImageUrl}
                    alt="image"
                    width={!md ? "150px" : "250px"}
                    style={{ margin: "auto" }}
                  />
                )}
              </Box>
              <Box position={"absolute"} top={2} right={5}>
                <img
                  src={upload}
                  alt=""
                  style={{ position: "absolute", right: 0, width: "20px" }}
                />
                <TextField
                  type="file"
                  name="imageUrl"
                  onChange={(e) => handleImageChange(0, e, data[0].id)}
                  size="small"
                  variant="outlined"
                  sx={{
                    "& fieldset": { border: "none" },
                    opacity: "0",
                    width: "20px",
                    height: "20px",
                  }}
                  inputProps={{ style: { padding: 0 } }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default RoadMap;
