import { Box, Typography } from "@mui/material";
import React from "react";
import { VictoryPie } from "victory";

import CustomDateRangePicker from "../../components/CustomDateRangePicker";
import { useMediaQueryHook } from "../../hooks/useMediaQueryHook";
interface PieGraphProps {
  overview_data: {
    UsersCount: number;
    EventsCount: number;
  };
}
const PieGraph: React.FC<PieGraphProps> = ({ overview_data }) => {
  const { md } = useMediaQueryHook()
  const data = [
    { x: "Events", y: overview_data.EventsCount, color: "rgba(205, 175, 255, 1)" },
    {
      x: "Users",
      y: overview_data.UsersCount,
      color: "rgba(108, 62, 185, 1)",
    },
  ];
  return (
    <>
      <Box
        padding={!md ? '10px' : "10px 15px"}
        display="flex"
        justifyContent={"space-between"}
        alignItems={"center"}

      >
        <Typography fontSize={18} color="rgba(53, 53, 53, 1)">
          Overview
        </Typography>
        <Box width={"50%"} border={"1px solid #a3a3a3"} borderRadius={3}>
          <CustomDateRangePicker />
        </Box>
      </Box>
      <Box
        height="250px"
        display="flex"
        alignItems={"center"}
        justifyContent={"center"}
        paddingX={!md ? 0 : 7}
      >
        <VictoryPie
          data={data}
          innerRadius={110}
          labelComponent={<div></div>}
          colorScale={["rgba(205, 175, 255, 1)", "rgba(108, 62, 185, 1"]}
        />
        <Box padding={2}>
          {data.map((ele, index) => (
            <Box key={index} mb={"5px"}>
              <Box display="flex" gap={1} alignItems={"center"}>
                <Box
                  height={18}
                  borderRadius={1}
                  width={18}
                  bgcolor={ele.color}
                ></Box>
                <Typography color={'rgba(51, 51, 51, 1)'}  fontSize={20}>{ele.y}</Typography>
              </Box>
              <Typography fontSize={"12px"} color={'rgba(130, 130, 130, 1)'}>{ele.x}</Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </>
  );
};

export default PieGraph;
