import React, { Fragment, useEffect, useState } from "react";
import { Box, Popover, Typography } from "@mui/material";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  setBarGraphEndDate,
  setBarGraphStartDate,
} from "../store/DashboardReducer";
import { useAppDispatch, useAppSelector } from "../hooks";

function SevenDayDateRangePicker() {
  const dispatch = useAppDispatch();
  const { barGraphStartDate, barGraphEndDate } = useAppSelector(
    (state) => state.Dashboard
  );
  const [onRange, setRange] = useState<null | HTMLElement>(null);
  const handleRange = (event: any) => {
    setRange(event.currentTarget);
  };

  const formatDate = (date: any) => {
    return date ? new Intl.DateTimeFormat("en-US").format(date) : "";
  };

  return (
    <Fragment>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          padding: "3px 10px",
        }}
      >
        <Box
          className="OverviewMenu"
          onClick={(event: any) => handleRange(event)}
          display="flex"
          alignItems={"center"}
          justifyContent={"space-between"}
          width={"90%"}
          style={{ cursor: "pointer" }}
        >
          <Typography
            variant="subtitle2"
            sx={{ fontWeight: 500, lineHeight: "16px", color: "#a3a3a3" }}
          >
            {barGraphStartDate && barGraphEndDate
              ? `${formatDate(barGraphStartDate)} - ${formatDate(
                  barGraphEndDate
                )}`
              : "Select Date"}
          </Typography>
          <KeyboardArrowDownRoundedIcon
            sx={{ width: "20px", height: "20px", color: "#a3a3a3" }}
          />
        </Box>
      </div>

      <Popover
        open={Boolean(onRange)}
        anchorEl={onRange}
        onClose={() => setRange(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Box sx={{ p: 0.8, width: "100%" }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar
              onChange={(date) => {
                // Ensure date is a valid Day.js object
                if (date.isValid()) {
                  // Calculate end date as 7 days before the start date
                  const endDate = date.subtract(7, "day");

                  // Dispatch start and end dates
                  dispatch(setBarGraphStartDate(date.toDate())); // Convert Day.js object to JavaScript Date object
                  dispatch(setBarGraphEndDate(endDate.toDate())); // Convert Day.js object to JavaScript Date object
                  setRange(null); // Reset range state if needed
                } else {
                  alert("Invalid date selected");
                }
              }}
              disableFuture={true}
              showDaysOutsideCurrentMonth={true}
              renderLoading={() => (
                <span data-mui-test="loading-progress">...</span>
              )}
            />
          </LocalizationProvider>
        </Box>
      </Popover>
    </Fragment>
  );
}

export default SevenDayDateRangePicker;
