import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
// icons
import editIcon from "../../assets/svgs/pencil.svg";
import preview from "../../assets/svgs/preview.svg";
import editIconActive from "../../assets/svgs/pencilWhite.svg";
import publishIcon from "../../assets/svgs/publish.svg";

// components
import Hero from "./Hero";
import HotItWorks from "./HotItWorks";
import Explore from "./Explore";
import FastAndSecure from "./FastAndSecure";
import FAQ from "./FAQ";
import Banner from "./Banner";
import RoadMap from "./RoadMap";
import Footer from "./Footer";
import CustomModal from "../../components/CustomModal";

// helpers
import ApiServices from "../../helpers/ApiServices";
import HelperModule from "../../helpers/HelperModule";
import { useAppDispatch } from "../../hooks";

const WebSettings = ({ md }: any) => {
  const dispatch = useAppDispatch()
  const [sectionData, setSectionData] = useState<any[]>([]);
  const [files, setFiles] = useState<any>([]);
  const [ids, setIds] = useState<any>([]);
  const [activeParam, setActiveParam] = useState("hero");
  const [activeTitle, setActiveTitle] = useState("Hero");
  const [sectionId, setSectionId] = useState("2");
  const [deleteHeroImageIds, setDeleteHeroImageIds] = useState<any>([]);
  const [modalState, setModalState] = useState(false);
  const token = sessionStorage.getItem("token");
  // Functions Block
  const handleSectionStates = (element: any) => {
    setActiveParam(element.param); // storing the param to send the search_param to the Api to update the contents
    setActiveTitle(element.title); // to show the active component title in the top bar
    setSectionId(element.id); // while selecting the section need to store the Id to send in the editImageApi
    setSectionData([]); // easy to manage to handle all sections data in this state
    setIds([]); // easy to manage to handle all id's array in this state
    setFiles([]); // easy to manage to handle all files to be add in this state
  };

  // separate api for update the images of the particular section
  const handleEditImageApi = async () => {
    try {
      const formData = new FormData();
      // to update the image need to send the form data to the api
      ids.forEach((id: any) => {
        formData.append("id[]", id);
      });
      files.map((file: any) => {
        formData.append("files", file);
      });
      formData.append("web_id", sectionId);

      const response = await HelperModule().postFormDataMethod(
        ApiServices.base_URL + ApiServices.update_image,
        formData,
        { Authorization: token }
      );
      if (response.success) {

        setModalState(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteFAQ = (id: any, index: number) => {
    setSectionData((prevData) => {
      // Create a copy of the previous data array
      const newData = [...prevData];
      // Update the specific item's Question property to null
      newData[index] = { ...newData[index], Question: null };
      return newData;
    });
  };

  const handleDeleteIds = (id: any, index: any) => {
    const newData = [...sectionData];
    newData.splice(index, 1);
    setSectionData(newData);
    setDeleteHeroImageIds((prev: any) => [...prev, id]);
  };

  const handleDeleteHeroImages = async () => {

    try {
      const response = await HelperModule().deleteMethodPayload(
        ApiServices.base_URL + ApiServices.delete_hero_images,
        { id: deleteHeroImageIds },
        token
      );
      if (response.success) {
        setModalState(true);
      }
      setDeleteHeroImageIds([])
    } catch (error) {
      console.log(error);
    }
  };
  const handleSubmit = async (param: string) => {
    //  hitting two api's in this function
    // 1 - update the contents
    // 2 - update the images
    try {
      const response = await HelperModule().postMethodHeaders(
        ApiServices.base_URL + ApiServices.update_settings,
        { data: sectionData, sectionType: param },
        { Authorization: token }
      );
      if (response.success) {
        setModalState(true);
      }
    } catch (error) {
      console.log("error: ", error);
    }

    if (files.length > 0) {
      handleEditImageApi();
    }
    if (deleteHeroImageIds.length > 0) {
      console.log('deleteHeroImageIds: ', deleteHeroImageIds);
      if (deleteHeroImageIds.length === 1 && deleteHeroImageIds[0] === 4) {
        return
      }
      else {
        handleDeleteHeroImages();

      }
    }
  };

  const options: any = [
    {
      id: "2",
      title: "Hero",
      subTitle: "Subtitle",
      component: (
        <Hero
          handleChangeCallback={setSectionData}
          data={sectionData}
          handleAddFilesCallback={setFiles}
          handleIdChangeCallback={setIds}
          handleIdDeleteCallback={handleDeleteIds}
        />
      ),
      param: "hero",
    },
    {
      id: "1",
      title: "How it works",
      subTitle: "subTitle",
      component: (
        <HotItWorks
          handleChangeCallback={setSectionData}
          data={sectionData}
          handleAddFilesCallback={setFiles}
          filesData={files}
          handleIdChangeCallback={setIds}
        />
      ),
      param: "how_it_works",
    },
    {
      id: "3",
      title: "Explore",
      subTitle: "subTitle",
      component: (
        <Explore
          handleChangeCallback={setSectionData}
          data={sectionData}
          handleAddFilesCallback={setFiles}
          handleIdChangeCallback={setIds}
        />
      ),
      param: "explore",
    },
    {
      id: "7",
      title: "Road Map",
      subTitle: "subTitle",
      component: (
        <RoadMap
          handleChangeCallback={setSectionData}
          data={sectionData}
          handleAddFilesCallback={setFiles}
          handleIdChangeCallback={setIds}
        />
      ),
      param: "road_map",
    },
    {
      id: "4",
      title: "Fast & Secure",
      subTitle: "subTitle",
      component: (
        <FastAndSecure
          handleChangeCallback={setSectionData}
          data={sectionData}
          handleAddFilesCallback={setFiles}
          handleIdChangeCallback={setIds}
        />
      ),
      param: "fast_and_secure",
    },
    {
      id: "6",
      title: "FAQ's",
      subTitle: "subTitle",
      component: (
        <FAQ
          handleChangeCallback={setSectionData}
          data={sectionData}
          handleAddFilesCallback={setFiles}
          handleIdChangeCallback={setIds}
          handleDeleteFAQCallback={handleDeleteFAQ}
        />
      ),
      param: "FAQ",
    },
    {
      id: "5",
      title: "Banner",
      subTitle: "subTitle",
      component: (
        <Banner
          handleChangeCallback={setSectionData}
          data={sectionData}
          handleAddFilesCallback={setFiles}
          handleIdChangeCallback={setIds}
        />
      ),
      param: "banner",
    },
    {
      id: "8",
      title: "Footer",
      subTitle: "subTitle",
      component: (
        <Footer
          handleChangeCallback={setSectionData}
          data={sectionData}
          handleAddFilesCallback={setFiles}
        />
      ),
      param: "footer",
    },
  ];

  return (
    <>
      <CustomModal
        open={modalState}
        onClose={() => setModalState(false)}
        title="Saved Successful"
        icon={editIconActive}
        subTitle="Your changes are saved successfully"
      />
      <Box
        display={!md ? "block" : "flex"}
        marginTop={11}
        minHeight={"85vh"}
        bgcolor={"white"}
        borderTop={!md ? "none" : "1px solid grey"}
        padding={!md ? 1 : 0}
      >
        <Box
          width={!md ? "100%" : "20%"}
          display="flex"
          flexDirection={"column"}
          gap={1}
          position={!md ? "relative" : "fixed"}
          borderLeft={!md ? "none" : "1px solid grey"}
          borderRight={!md ? "none" : "1px solid grey"}
          height={"100%"}
        >
          <Box borderBottom={!md ? "none" : "1px solid grey"} padding={1}>
            <Typography >Sections</Typography>
          </Box>
          <Box>
            {options?.map((ele: any, index: number) => (
              <Box
                key={index}
                display={"flex"}
                justifyContent={"space-between"}
                marginBottom={1}
                onClick={() => handleSectionStates(ele)}
                bgcolor={activeParam === ele.param ? "#6C3EB9" : "white"}
                color={activeParam === ele.param ? "white" : "black"}
                alignItems={"center"}
                sx={{ cursor: "pointer" }}
                paddingX={1}
              >
                <Box display="flex" gap={1}>
                  <Box>
                    <img src={preview} alt="" width={30} />
                  </Box>

                  <Box>
                    <Typography fontSize={14} >
                      {ele.title}
                    </Typography>
                    <Typography fontSize={12}>{ele.subTitle}</Typography>
                  </Box>
                </Box>
                <Box>
                  <img
                    src={activeParam === ele.param ? editIconActive : editIcon}
                    alt=""
                  />
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
        <Box
          width={!md ? "90%" : "80%"}
          marginLeft={!md ? "2%" : "24.3%"}
          borderRight={!md ? "none" : "1px solid grey"}
        >
          <Box
            borderBottom={!md ? "none" : "1px solid grey"}
            paddingX={2}
            paddingY={3}
            position={!md ? "relative" : "fixed"}
            width={!md ? "100%" : "60.3%"}
            zIndex={1}
          >
            <Typography>{activeTitle}</Typography>
          </Box>
          <Box marginTop={!md ? 0 : 9}>
            {options.find((ele: any) => ele.param === activeParam)?.component}
          </Box>
          <Box
            borderTop={!md ? "none" : "1px solid grey"}
            borderBottom={!md ? "none" : "1px solid grey"}
            width={!md ? "100%" : "62.7%"}
            position={!md ? "relative" : "fixed"}
            zIndex={1}
            bottom={0}
            bgcolor="white"
          >
            <Box display={"flex"} justifyContent={"flex-end"} paddingY={1}>
              {/* <Button>Nani</Button> */}
              <Button
                sx={{
                  bgcolor: "#6C3EB9",
                  textTransform: "none",
                  color: "white",
                  paddingX: "30px",
                  width: "150px",
                  marginRight: "10px",
                  "&:hover": {
                    bgcolor: "rgb(82, 40, 153)", // Maintain the same background color on hover
                    border: "none",
                  },
                  "&:focus": {
                    outline: "none", // Remove the focus outline
                  },
                }}
                onClick={() => handleSubmit(activeParam)}
                startIcon={<img src={publishIcon} alt="publish" width={22} />}
              >
                Publish
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default WebSettings;
