import React, { useEffect, useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import HelperModule from "../../helpers/HelperModule";
import ApiServices from "../../helpers/ApiServices";
import { enqueueSnackbar } from "notistack";
const HelpAndSupport = ({ sm, md }: any) => {
  const [formData, setFormData] = useState({
    help_support_email: "",
  });
  const [isEdit, setIsEdit] = useState(false);
  useEffect(() => {
    getDetailsPayment();
  }, []);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prev: any) => ({ ...prev, [name]: value }));
  };
  const handleEdit = async (e: any) => {
    const token = sessionStorage.getItem("token");
    setIsEdit(!isEdit);
    if (isEdit) {
      if (formData.help_support_email.length > 0) {
        try {
          const response = await HelperModule().postMethodHeaders(
            ApiServices.base_URL + ApiServices.payments_api,
            formData,
            { Authorization: token }
          );
          if (response.message) {
            enqueueSnackbar(response.message, {
              variant: "success",
              anchorOrigin: { vertical: "top", horizontal: "right" },
              style: { fontFamily: "Poppins", fontWeight: "500" },
            });
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        enqueueSnackbar("Please fill all fields.", {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          style: { fontFamily: "Poppins", fontWeight: "500" },
        });
      }
    }
  };
  // const handleEdit = async (e: any) => {
  //   const token = sessionStorage.getItem("token");
  //   // e.preventDefault();
  //   setIsEdit(!isEdit);
  //   if (isEdit) {
  //     try {
  //       const response = await HelperModule().postMethodHeaders(
  //         ApiServices.base_URL + ApiServices.payments_api,
  //         formData,
  //         { Authorization: token }
  //       );
  //       // alert(response.message);
  //       if (response.message) {
  //         enqueueSnackbar(response.message, {
  //           variant: "success",
  //           anchorOrigin: { vertical: "top", horizontal: "right" },
  //           style: { fontFamily: "Poppins", fontWeight: "500" },
  //         });
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }
  // };
  const getDetailsPayment = async () => {
    const token = sessionStorage.getItem("token");
    try {
      const response = await HelperModule().getMethodWithHeaders(
        ApiServices.base_URL + ApiServices.payments_api,
        { Authorization: token }
      );
      setFormData(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Box
      width={!md ? "88%" : "60%"}
      bgcolor={"white"}
      paddingX={3}
      paddingY={1}
      minHeight={!md ? '90vh' : 'auto'}
      maxHeight={'100vh'}
      sx={{ borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}
    >
      <Typography fontWeight={"bold"}>Help & Support</Typography>
      <Box display="flex" gap={5} paddingY={1}>
        <Box width={!md ? "100%" : "80%"}>
          <Box display={!md ? "block" : "flex"} gap={3}>
            <Box width={!sm ? '98%' : !md ? "50%" : "50%"}>
              <Typography fontSize={"12px"}>Email</Typography>
              <TextField
                fullWidth
                type="email"
                disabled={!isEdit}
                name="help_support_email"
                value={formData?.help_support_email}
                onChange={handleChange}
                required
                size="small"
                variant="outlined"
                sx={{
                  "& fieldset": { border: "none" },
                  border: "1px solid lightgrey",
                  borderRadius: "10px",
                  paddingY: "4px",
                }}
                inputProps={{
                  style: {
                    padding: "3px 10px",
                    fontSize: "14px",
                  },
                }}
              />
            </Box>
            {/* <Box width={!md ? "100%" : "50%"} mt={!md ? 2 : 0}>
              <Typography fontSize={"12px"}>Email</Typography>
              <TextField
                type="email"
                fullWidth
                disabled={!isEdit}
                name="help_support_email"
                value={formData?.help_support_email}
                onChange={handleChange}
                required
                size="small"
                variant="outlined"
                sx={{
                  "& fieldset": { border: "none" },
                  border: "1px solid lightgrey",
                  borderRadius: "10px",
                  paddingY: "4px",
                }}
                inputProps={{
                  style: {
                    padding: "3px 10px",
                    fontSize: "14px",
                  },
                  endAdorement: <Box>%</Box>,
                }}
              />
            </Box> */}
          </Box>
          <Box marginTop={3}>
            <Button
              sx={{
                textTransform: "none",
                bgcolor: "rgba(242, 234, 255, 1)",
                color: "#6C3EB9",
                marginRight: "10px",
                borderRadius: "10px",
                padding: "5px",
                fontSize: "12px",
                "&:hover": {
                  bgcolor: "rgba(242, 234, 255, 1)", // Maintain the same background color on hover
                  border: "none",
                },
                "&:focus": {
                  outline: "none", // Remove the focus outline
                },
              }}
              onClick={handleEdit}
            >
              {isEdit ? "Save" : "Edit"}
            </Button>
            {isEdit && (
              <Button
                sx={{
                  textTransform: "none",
                  color: "#6C3EB9",
                  fontSize: "12px",
                }}
                onClick={() => {
                  isEdit && setIsEdit(false);
                }}
              >
                Cancel
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default HelpAndSupport;
