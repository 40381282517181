import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
} from "@mui/material";
import HelperModule from "../../helpers/HelperModule";
import ApiServices from "../../helpers/ApiServices";
import { setIsLogin } from "../../store/AuthReducer";
import { useAppDispatch } from "../../hooks";
import { enqueueSnackbar } from "notistack";
import eye from "../../assets/svgs/eye.svg";
import eyeOff from '../../assets/svgs/eye-off.svg'
import { useMediaQueryHook } from "../../hooks/useMediaQueryHook";
const ChangePassword = ({ open, onClose }: any) => {
  const dispatch = useAppDispatch();
  const { md, sm } = useMediaQueryHook();
  const [passwords, setPasswords] = useState({
    old_password: "",
    new_password: "",
    confirm_new_password: "",
  });
  const [showHide, setShowHide] = useState<any>({
    old_password: false,
    new_password: false,
    confirm_new_password: false,
  });


  const handleShowHide = (key: string, value: boolean) => {
    setShowHide((prev: any) => ({ ...prev, [key]: value }))
  }
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setPasswords({ ...passwords, [name]: value });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (
      passwords.old_password.length < 8 ||
      passwords.new_password.length < 8 ||
      passwords.confirm_new_password.length < 8
    ) {
      enqueueSnackbar("Password Length Should be above 8", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        style: { fontFamily: "Poppins", fontWeight: "500" },
      });
    }
    const token = sessionStorage.getItem("token");
    // Handle password change submission here
    try {
      const response = await HelperModule().patchMethod(
        ApiServices.base_URL + ApiServices.change_password,
        passwords,
        { Authorization: token }
      );
      if (response.data) {
        enqueueSnackbar("Password Updated Successfully", {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          style: { fontFamily: "Poppins", fontWeight: "500" },
        });
        // Close the modal after submission
        onClose();
        setPasswords({
          old_password: "",
          new_password: "",
          confirm_new_password: "",
        });
        sessionStorage.removeItem("token");
        dispatch(setIsLogin(null));
      } else {
        enqueueSnackbar(response.response.data.message, {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          style: { fontFamily: "Poppins", fontWeight: "500" },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          minWidth: 350,
          maxWidth: 400,
          borderRadius: 2,
        }}
      >
        <Typography
          variant="h6"
          color={"#6C3EB9"}
          fontWeight={"bold"}
          gutterBottom
        >
          Change Password
        </Typography>
        <form
          onSubmit={handleSubmit}
          style={{ display: "flex", flexDirection: "column", gap: 2 }}
        >
          <span
            style={{
              position: "relative",
              marginTop: "10px",
              // width: "100%",
            }}
          >
            <TextField
              fullWidth
              hiddenLabel
              type={showHide.old_password ? "text" : "password"}
              name="old_password"
              placeholder="Old Password"
              variant="outlined"
              onChange={handleChange}
              sx={{
                borderRadius: "0.4rem",
                outlineStyle: "none",
              }}
              InputProps={{
                style: {
                  borderRadius: 14,
                  padding: !sm ? 2 : !md ? 9 : 0,
                  fontSize: "14px",
                },
              }}
            // onKeyDown={handleKeyPress}
            />
            <img
              src={showHide.old_password ?eyeOff  : eye}
              onClick={() => handleShowHide("old_password", !showHide.old_password)}
              alt="show-password"
              style={{
                objectFit: "contain",
                width: !sm ? "8%" : "5%",
                position: "absolute",
                top: "40%",
                right: "5%",
                cursor: "pointer",
              }}
            />
          </span>
          <span
            style={{ position: "relative", marginTop: "20px", width: "100%" }}
          >
            <TextField
              fullWidth
              hiddenLabel
              type={showHide.new_password ? "text" : "password"}
              name="new_password"
              placeholder="New Password"
              variant="outlined"
              onChange={handleChange}
              sx={{
                borderRadius: "0.4rem",
                outlineStyle: "none",
              }}
              InputProps={{
                style: {
                  borderRadius: 14,
                  padding: !sm ? 2 : !md ? 9 : 0,
                  fontSize: "14px",
                },
              }}
            // onKeyDown={handleKeyPress}
            />
            <img
              src={showHide.new_password ? eyeOff : eye}
              onClick={() => handleShowHide("new_password", !showHide.new_password)}
              alt="show-password"
              style={{
                objectFit: "contain",
                width: !sm ? "8%" : "5%",
                position: "absolute",
                top: "40%",
                right: "5%",
                cursor: "pointer",
              }}
            />
          </span>
          <span
            style={{ position: "relative", marginTop: "20px", width: "100%" }}
          >
            <TextField
              fullWidth
              hiddenLabel
              type={showHide.confirm_new_password ? "text" : "password"}
              name="confirm_new_password"
              placeholder="Confirm New Password"
              variant="outlined"
              onChange={handleChange}
              sx={{
                borderRadius: "0.4rem",
                outlineStyle: "none",
              }}
              InputProps={{
                style: {
                  borderRadius: 14,
                  padding: !sm ? 2 : !md ? 9 : 0,
                  fontSize: "14px",
                },
              }}
            // onKeyDown={handleKeyPress}
            />
            <img
              src={showHide.confirm_new_password ? eyeOff : eye}
              onClick={() => handleShowHide("confirm_new_password", !showHide.confirm_new_password)}
              alt="show-password"
              style={{
                objectFit: "contain",
                width: !sm ? "8%" : "5%",
                position: "absolute",
                top: "40%",
                right: "5%",
                cursor: "pointer",
              }}
            />
          </span>

          <Button
            type="submit"
            // variant="contained"
            fullWidth
            disabled={
              !passwords.old_password ||
              !passwords.new_password ||
              !passwords.confirm_new_password
            }
            sx={{
              textTransform: "none",
              bgcolor: "#6C3EB9",
              color: "white",
              marginTop: "15px",
              borderRadius: "10px",
              padding: "10px",
              fontSize: "12px",
              "&:hover": {
                bgcolor: "rgb(82, 40, 153)", // Maintain the same background color on hover
                border: "none",
                // color:'#6C3EB9'
              },
              "&:focus": {
                outline: "none", // Remove the focus outline
              },
            }}
          >
            Change Password
          </Button>
        </form>
      </Box>
    </Modal>
  );
};

export default ChangePassword;
