import React, { Fragment, useRef } from "react";
import { Box, IconButton } from "@mui/material";
import EditImageSVG from "../assets/svgs/profileEdit.svg";
import CustomSuccesfulModal from "./CustomSuccesfulModal";

interface ProfileImageProps {
  imagePrev: string; // URL of the previous image (if any)
  handleSetFormDataCallback: (formData: any) => void; // Function to handle setting form data
  handleImagePrevCallback: (imagePrev: string) => void; // Function to handle setting previous image
  image: File | null; // File object representing the selected image
  isEdit?: boolean; // Indicates whether the component is in edit mode
  type?: string;
}

const ProfileImage = ({
  imagePrev,
  handleSetFormDataCallback,
  handleImagePrevCallback,
  image,
  isEdit,
  type,
}: ProfileImageProps) => {
  const InputRef: any = useRef();
  const onChangeImage = (event: any) => {
    event?.preventDefault();
    let reader = new FileReader();
    let file: any = event?.target?.files[0];
    reader.onloadend = () => {
      handleImagePrevCallback(URL.createObjectURL(file));
      handleSetFormDataCallback((prev: any) => ({ ...prev, imageUrl: file }));
    };
    reader.readAsDataURL(file);
  };
  return (
    <Fragment>
      <Box
        sx={{
          width: "90px",
          height: "90px",
          borderRadius: "80px",
          border: `2px solid #6C3EB9`,
          background: `url(${imagePrev !== null ? imagePrev : image ? image : "https://t4.ftcdn.net/jpg/05/49/98/39/360_F_549983970_bRCkYfk0P6PP5fKbMhZMIb07mCJ6esXL.jpg"})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "100%",
        }}
      >
        {type === "profileSettings" ? (
          isEdit && (
            <IconButton
              onClick={() => InputRef?.current?.click()}
              disableFocusRipple
              sx={{
                position: "relative",
                left: 60,
                top: 50,
                borderRadius: "30px",
                background: "#f8f8f8",
                "&:hover": {
                  background: `#d1cece`,
                },
              }}
            >
              <img src={EditImageSVG} alt="Edit" />
            </IconButton>
          )
        ) : (
          <IconButton
            onClick={() => InputRef?.current?.click()}
            disableFocusRipple
            sx={{
              position: "relative",
              left: 60,
              top: 50,
              borderRadius: "30px",
              background: "#f8f8f8",
              "&:hover": {
                background: `#d1cece`,
              },
            }}
          >
            <img src={EditImageSVG} alt="Edit" />
          </IconButton>
        )}
        <input
          ref={InputRef}
          type="file"
          onChange={(event: any) => onChangeImage(event)}
          style={{ display: "none" }}
        />
      </Box>
    </Fragment>
  );
};

export default ProfileImage;
