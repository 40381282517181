import React, { useState } from "react";
import ButtonAuth from "../../components/ButtonAuth";
import { useMediaQueryHook } from "../../hooks/useMediaQueryHook";
import { Box, TextField, Typography } from "@mui/material";
import PictureComponent from "../../components/PictureComponent";
import friends from "../../assets/freinds.png";
import eye from "../../assets/svgs/eye.svg";
import { useNavigate } from "react-router-dom";
import HelperModule from "../../helpers/HelperModule";
import ApiServices from "../../helpers/ApiServices";
import { enqueueSnackbar } from "notistack";
const ResetPassword = () => {
  const navigate = useNavigate();
  const { md, sm } = useMediaQueryHook();
  const [passwordCred, setPasswordCred] = useState({
    password: "",
    confirmPassword: "",
  });

  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  });

  const handleShowPassword = (id: string, value: boolean) => {
    setShowPassword((prev) => ({ ...prev, [id]: value }));
  };
  const handlePassword = (e: any) => {
    const { name, value } = e.target;
    setPasswordCred((prev) => ({ ...prev, [name]: value }));
  };
  const handleChangePassword = async () => {
    const reset_token = sessionStorage.getItem("reset_token");
    const headers = { Authorization: reset_token };
    try {
      const response = await HelperModule().postMethodHeaders(
        ApiServices.base_URL + ApiServices.reset_password,
        {
          new_password: passwordCred.password,
          confirm_password: passwordCred.confirmPassword,
        },
        headers
      );
      if (response.success) {
        enqueueSnackbar(response?.message, {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          style: { fontFamily: "Poppins", fontWeight: "500" },
        });
        navigate("/login");
      } else alert(response?.response?.data?.message);
    } catch (error) {
      console.log(error);
    }
  };

  const handleKeyPress = (e: any) => {
    if (e.key === "Enter") {
      // Trigger handleLogin when Enter key is pressed
      handleChangePassword();
    }
  };
  return (
    <Box
      display="flex"
      minHeight="100vh"
      flexDirection={!md ? "column" : "row"}
    >
      <PictureComponent md={md} imgSrc={friends} />
      <Box
        paddingRight={!md ? 0 : 1}
        width={!md ? "100%" : "55%"}
        minHeight={!md ? "50%" : "100vh"}
        sx={{
          background: "#FFF",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          mt: !md ? "25px" : "0",
        }}
      >
        <Box width={!md ? "90%" : "70%"}>
          <Box
            sx={{
              width: "100%",
              textAlignLast: !md ? "center" : "left",
            }}
          >
            <Typography
              style={{
                fontSize: "22px",
                color: "rgba(16, 81, 142, 1)",
              }}
            >
              Enter New Password
            </Typography>
            {/* <Typography
              sx={{
                color: "#737373",
                marginTop: "0.4rem",
                marginBottom: "1rem",
                fontSize: "0.9rem",
                width: "90%",
              }}
            >
              Lorem ipsum dolor sit amet consectetur.
            </Typography> */}
          </Box>
          <Box
            mt={1}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "0.6rem",
              marginTop: !md ? "10px" : "20px",
            }}
          >
            <span
              style={{ position: "relative", marginTop: "10px", width: "100%" }}
            >
              <TextField
                fullWidth
                placeholder="Enter your New Password"
                name="password"
                type={showPassword.password ? "text" : "password"}
                value={passwordCred.password}
                onChange={handlePassword}
                variant="outlined"
                // sx={{ width: !md ? "23rem" : "30rem" }}
                InputProps={{
                  style: { borderRadius: 14, padding: !sm ? 2 : !md ? 4 : 0 },
                }}
              />
              <img
                src={eye}
                onClick={() =>
                  handleShowPassword("password", !showPassword.password)
                }
                alt="show-password"
                style={{
                  objectFit: "contain",
                  width: !sm ? "8%" : "5%",
                  position: "absolute",
                  top: "30%",
                  right: "5%",
                  cursor: "pointer",
                }}
              />
            </span>
            {/* {passwordErrors?.password && (
              <Typography style={{ color: "red", fontSize: "13px" }}>
                {passwordErrors?.password[0]}
              </Typography>
            )} */}
            <span
              style={{ position: "relative", marginTop: "10px", width: "100%" }}
            >
              <TextField
                fullWidth
                placeholder="Confirm Password"
                name="confirmPassword"
                type={showPassword.confirmPassword ? "text" : "password"}
                value={passwordCred.confirmPassword}
                onChange={handlePassword}
                variant="outlined"
                // sx={{ width: !md ? "23rem" : "30rem" }}
                InputProps={{
                  style: { borderRadius: 14, padding: !sm ? 2 : !md ? 4 : 0 },
                }}
                onKeyDown={handleKeyPress}
              />
              <img
                src={eye}
                onClick={() =>
                  handleShowPassword(
                    "confirmPassword",
                    !showPassword.confirmPassword
                  )
                }
                alt="show-password"
                style={{
                  objectFit: "contain",
                  width: !sm ? "8%" : "5%",
                  position: "absolute",
                  top: "30%",
                  right: "5%",
                  cursor: "pointer",
                }}
              />
            </span>
            {/* {passwordErrors?.confirmPassword && (
              <Typography style={{ color: "red", fontSize: "13px" }}>
                {passwordErrors?.confirmPassword[0]}
              </Typography>
            )} */}
          </Box>
          <ButtonAuth md={md} text="Submit" onClick={handleChangePassword} />
        </Box>
      </Box>
    </Box>
  );
};

export default ResetPassword;
