import { Box, Button, Pagination, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useMediaQueryHook } from "../hooks/useMediaQueryHook";
import { useNavigate, useParams } from "react-router-dom";
import EventDetailsCard from "../pages/users/EventDetailsCard";
import RsvpTable from "../pages/users/RsvpTable";
import HelperModule from "../helpers/HelperModule";
import ApiServices from "../helpers/ApiServices";
import { CSVLink } from "react-csv"
import downloadIcon from '../assets/svgs/Download.svg'

const EventDetails = () => {
  const { md } = useMediaQueryHook();
  const navigate = useNavigate();
  const columns = [
    { id: "id", label: "S.NO" },
    { id: "name", label: "Host Name" },
    { id: "createdAt", label: "Date & time" },
    { id: "rsvp", label: "RSVP" },
    { id: "contributionsAmount", label: "Total Contributions" },
    { id: "message", label: "Message" }
  ];
  const [rsvpDetails, setRsvpDetails] = useState<any>([]);
  const [totalPages, setTotalPages] = useState<any>(1)
  const [currentPage, setCurrentPage] = useState(1)
  const { id } = useParams();
  useEffect(() => {
    rsvpData();
  }, [currentPage]);
  const handleChange = (event: any, value: any) => {
    setCurrentPage(value);
  };
  const rsvpData = async () => {
    const token = sessionStorage.getItem("token");
    try {
      let response = await HelperModule().getMethodWithHeaders(
        ApiServices.base_URL + ApiServices.event_response + `?event=${id}&page=${currentPage}`,
        { Authorization: token }
      );
      if (response?.success) {
        setRsvpDetails(response?.data?.responses);
        setTotalPages(response?.data?.TotalPages)
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Box
      marginTop={11}
      minHeight={"85vh"}
      padding={!md ? 1 : 0}
    // overflow={"scroll"}
    >
      <Box display={"flex"} alignItems={"center"} gap={1}>
        <Box
          style={{
            background: "white",
            borderRadius: "50%",
            height: "25px",
            width: "20px",
            textAlign: "center",
            cursor: "pointer",
          }}
          onClick={() => navigate(-1)}
        >
          <Typography>&lt;</Typography>
        </Box>
        <Typography fontSize={"14px"}>Back</Typography>
      </Box>
      <Box mt={2} mb={1}>
        <EventDetailsCard />
      </Box>
      <Box width={!md ? "92%" : "81%"} bgcolor={"white"} p={!md ? 1.5 : 3} mt={2} borderRadius={2}>
        <Box display="flex" justifyContent={"space-between"} mb={2}>
          <Typography>RSVP ({rsvpDetails?.length})</Typography>
          <CSVLink data={rsvpDetails}>
            <Button
              variant="outlined"
              sx={{
                // marginTop: "10px",
                bgcolor: "rgb(108,62,185)",
                textTransform: "none",
                color: "#fff",
                border: "none",
                fontSize: "12px",
                paddingX: "10px",
                paddingY: "2px",
                "&:hover": {
                  bgcolor: "rgb(82, 40, 153)", // Maintain the same background color on hover
                  border: "none",
                },
                "&:focus": {
                  outline: "none", // Remove the focus outline
                },
              }}
              startIcon={<img src={downloadIcon} alt="add" width={15} />}
            >
              Export
            </Button>
          </CSVLink>
        </Box>
        <Box width={!md ? '98%' : "100%"} overflow={'scroll'} className="hide-scrollbar">

          <RsvpTable columns={columns} data={rsvpDetails} />
        </Box>
        <Pagination
          count={
            totalPages
          }
          page={currentPage}
          onChange={handleChange}
        />
      </Box>
    </Box>
  );
};

export default EventDetails;
