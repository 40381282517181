import { Box, Button, Typography, Pagination, TextField } from "@mui/material";
import React, { Fragment, useState, useEffect } from "react";
import addIcon from "../../assets/svgs/plus.svg";
import { useMediaQueryHook } from "../../hooks/useMediaQueryHook";
import { useAppDispatch, useAppSelector } from "../../hooks";
import HelperModule from "../../helpers/HelperModule";
import ApiServices from "../../helpers/ApiServices";

import {
  setDeleteSubAdminModal,
  setEditStatusModal,
  setEditStatusSuccessModal,
  setEditSubAdminDetails,
  setSingleSubAdminDetails,
  setSubAdminAddedSuccess,
  setSubAdminDeletedSuccess,
} from "../../store/Gift4dayReducer";
import SubAdminsTable from "./SubAdminsTable";
import UserDetailsModal from "./UserDetailsModal";
import CustomConfirmModal from "../../components/CustomConfirmModal";
import CustomSuccesfulModal from "../../components/CustomSuccesfulModal";
import SearchBar from "../../components/SearchBar";

const Gift4DayUsers = () => {
  const {
    singleSubAdminDetails,
    deleteUserModal,
    subAdminDeletedSuccess,
    subAdminAddeddSuccessModal,
    editSubAdminStatusModal,
    editSubAdminStatusSuccessModal,
    editSubAdminDetails,
  } = useAppSelector((state) => state.Gift4day);
  const [input, setInput] = useState("");
  const handleInput = (query: string) => {
    setInput(query.toLowerCase());
  };
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const columns = [
    { id: "id", label: "S.NO" },
    { id: "first_name", label: "User Name" },
    { id: "email", label: "Email" },
    { id: "phoneNumber", label: "Phone Number" },
    { id: "status", label: "Status" },
    { id: "delete", label: "Delete" },
  ];

  const { md } = useMediaQueryHook();

  const dispatch = useAppDispatch();
  useEffect(() => {
    getSubAdminList();
  }, [
    subAdminAddeddSuccessModal,
    currentPage,
    input,
    editSubAdminStatusSuccessModal,
    editSubAdminDetails,
  ]);
  const handleChange = (event: any, value: any) => {
    setCurrentPage(value);
  };
  const getSubAdminList = async () => {
    const token = sessionStorage.getItem("token");
    try {
      let response = await HelperModule().getMethodWithHeaders(
        ApiServices.base_URL +
        ApiServices.get_sub_admins +
        `?page=${currentPage}&search_param=${input}`,
        { Authorization: token }
      );

      if (response?.success) {
        console.log("usersss", response?.data?.allUsers);
        setData(response?.data?.allUsers);
        setTotalPages(response?.data?.TotalPages);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteUser = async () => {
    const token = sessionStorage.getItem("token");
    try {
      let response = await HelperModule().deleteMethod(
        ApiServices.base_URL + `delete_user?id=${deleteUserModal.id}`,
        { Authorization: token }
      );
      if (response?.success) {
        dispatch(setDeleteSubAdminModal({ isOpen: false }));
        dispatch(setSubAdminDeletedSuccess(true));
        getSubAdminList();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const editUserStatus = async () => {
    const status =
      editSubAdminStatusModal.status === "Active" ? "Inactive" : "Active";
    const newFormData = new FormData();
    newFormData.append("id", editSubAdminStatusModal.id);
    newFormData.append("status", status);
    console.log(status, "status");
    const token = sessionStorage.getItem("token");
    try {
      const response = await HelperModule().patchMethodFormData(
        ApiServices.base_URL + ApiServices.edit_subAdmin,
        newFormData,
        { Authorization: token }
      );
      if (response.success) {
        dispatch(setEditStatusModal({ isOpen: false, ...response.data }));
        dispatch(setEditStatusSuccessModal(true));
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Fragment>
      <UserDetailsModal
        userDetails={singleSubAdminDetails}
        isOpen={singleSubAdminDetails.isOpen}
        onClose={() => {
          dispatch(setSingleSubAdminDetails({ isOpen: false, isEdit: false }));
        }}
      />
      <CustomSuccesfulModal
        isOpen={subAdminAddeddSuccessModal}
        onClose={() => dispatch(setSubAdminAddedSuccess(false))}
        title="Success"
        content="The User has been Added successfully."
      />
      <CustomSuccesfulModal
        isOpen={editSubAdminDetails.isOpen}
        onClose={() => dispatch(setEditSubAdminDetails({ isOpen: false }))}
        title="Success"
        content="The User has been Updated successfully."
      />

      <CustomConfirmModal
        open={deleteUserModal.isOpen}
        onClose={() => dispatch(setDeleteSubAdminModal({ isOpen: false }))}
        title="Confirm"
        confirmText="Yes"
        cancelText="No"
        content="Are you sure you want to delete?"
        onConfirm={deleteUser}
      />
      <CustomConfirmModal
        open={editSubAdminStatusModal.isOpen}
        onClose={() => dispatch(setEditStatusModal({ isOpen: false }))}
        title="Confirm"
        confirmText="Yes"
        cancelText="No"
        content={`Are you sure ${editSubAdminStatusModal.status} this sub-admin`}
        onConfirm={editUserStatus}
      />
      <CustomSuccesfulModal
        isOpen={editSubAdminStatusSuccessModal}
        onClose={() => dispatch(setEditStatusSuccessModal(false))}
        title="Success"
        content={`The sub-admin has been ${editSubAdminStatusModal.status}`}
      />
      <CustomSuccesfulModal
        isOpen={subAdminDeletedSuccess}
        onClose={() => dispatch(setSubAdminDeletedSuccess(false))}
        title="Success"
        content="The User has been deleted successfully."
      />
      <Box
        marginTop={11}
        minHeight={"85vh"}
        padding={!md ? 1 : 0}
        width={'100%'}
      >
        <Button
          variant="outlined"
          sx={{
            // marginTop: "10px",
            bgcolor: "rgb(108,62,185)",
            textTransform: "none",
            color: "#fff",
            border: "none",
            "&:hover": {
              bgcolor: "rgb(82, 40, 153)", // Maintain the same background color on hover
              border: "none",
            },
            "&:focus": {
              outline: "none", // Remove the focus outline
            },
          }}
          onClick={() => dispatch(setSingleSubAdminDetails({ isOpen: true }))}
          startIcon={<img src={addIcon} alt="add" width={20} />}
        >
          Add User
        </Button>
        <Box width={!md ? '99%' : "95%"} mt={2} bgcolor={"white"} p={!md ? 1 : 2} borderRadius={1.5} >
          <Box display="flex" justifyContent={"space-between"}>
            <Typography>User List ({data.length})</Typography>
            <Box>
              {" "}
              <SearchBar placeholder="search..." onChange={handleInput} />
            </Box>
          </Box>
          <Box width={'99%'} overflow={'scroll'} className='hide-scrollbar'>

            <SubAdminsTable columns={columns} data={data} title="subAdmins" />
          </Box>
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handleChange}
          />
        </Box>
      </Box>
    </Fragment>
  );
};

export default Gift4DayUsers;
