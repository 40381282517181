import { createTheme, useMediaQuery } from "@mui/material";

export const useMediaQueryHook = () => {
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 830,
        lg: 1300,
        xl: 1536,
      },
    },
  });

  const lg = useMediaQuery(theme.breakpoints.up("lg"));
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  const xs = useMediaQuery(theme.breakpoints.only("xs"));

  return { lg, md, sm, xs };
};
