import { createSlice } from '@reduxjs/toolkit';

interface initialStateProps {
    confirmStatusModal: boolean;
    successfullModal: boolean;
    singleSubAdminDetails: any;
    subAdminAddeddSuccessModal: boolean;
    editSubAdminDetails: any;
    editSubAdminStatusModal: any;
    editSubAdminStatusSuccessModal: boolean;
    detailsModal: boolean;
    deleteUserModal: any;
    subAdminDeletedSuccess: boolean
}

const initialState: initialStateProps = {
    confirmStatusModal: false,
    successfullModal: false,
    singleSubAdminDetails: {},
    subAdminAddeddSuccessModal: false,
    editSubAdminStatusSuccessModal: false,
    editSubAdminDetails: {},
    editSubAdminStatusModal: {},
    detailsModal: false,
    deleteUserModal: {},
    subAdminDeletedSuccess: false
}

const Gift4dayUsers = createSlice({
    name: 'Gift4dayUsers',
    initialState,
    reducers: {
        setConfirmStatusModal: (state, action) => {
            state.confirmStatusModal = action?.payload
        },
        setSuccessfullModal: (state, action) => {
            state.successfullModal = action?.payload
        },

        setSingleSubAdminDetails: (state, action) => {
            state.singleSubAdminDetails = action?.payload
        },
        setSubAdminAddedSuccess: (state, action) => {
            state.subAdminAddeddSuccessModal = action?.payload
        },
        setEditSubAdminDetails: (state, action) => {
            state.editSubAdminDetails = action?.payload
        },
        setEditStatusModal: (state, action) => {
            state.editSubAdminStatusModal = action?.payload
        },
        setEditStatusSuccessModal: (state, action) => {
            state.editSubAdminStatusSuccessModal = action?.payload
        },
        setDeleteSubAdminModal: (state, action) => {
            state.deleteUserModal = action?.payload
        },
        setSubAdminDeletedSuccess: (state, action) => {
            state.subAdminDeletedSuccess = action?.payload
        }
    },
});

export const {
    setConfirmStatusModal,
    setSuccessfullModal,
    setSingleSubAdminDetails,
    setSubAdminAddedSuccess,
    setEditSubAdminDetails,
    setDeleteSubAdminModal,
    setSubAdminDeletedSuccess,
    setEditStatusModal,
    setEditStatusSuccessModal
} = Gift4dayUsers.actions;
export default Gift4dayUsers.reducer;