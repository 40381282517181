import axios from "axios";

import Apiservices from "./ApiServices";

const HelperModule = () => {
  const instance = axios.create({
    baseURL: Apiservices.base_URL,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      // 'Access-Control-Allow-Origin': '*'
    },
  });

  const getMethod = (url: any) => {
    return instance
      .get(url)
      .then((response: any) => {
        return response.data;
      })
      .catch((err: any) => {
        return err;
      });
  };

  const postMethod = (url: any, params: any) => {
    return instance
      .post(url, params)
      .then((response: any) => {
        return response.data;
      })
      .catch((err: any) => {
        return err;
      });
  };
  const postMethodHeaders = (url: any, params: any, headers: any) => {
    return instance
      .post(url, params, { headers: { ...headers } })
      .then((response: any) => {
        return response.data;
      })
      .catch((err: any) => {
        return err;
      });
  };
  const patchMethod = (url: any, params: any, headers: any) => {
    return instance
      .patch(url, params, { headers: { ...headers } })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  const patchMethodFormData = (url: any, params: any, headers: any) => {
    return instance
      .patch(url, params, {
        headers: {
          ...headers,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  const patchMethodHeaders = (url: any, params: any, headers: any) => {
    return instance
      .patch(url, params, {
        headers: {
          ...headers,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  const postFormDataMethod = (url: any, formData: any, headers: any) => {
    return instance
      .post(url, formData, {
        headers: {
          ...headers,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response: any) => {
        return response.data;
      })
      .catch((err: any) => {
        return err;
      });
  };

  const deleteMethod = (url: any, headers: any) => {
    return instance
      .delete(url, { headers: { ...headers } })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  const deleteMethodPayload = (
    url: string,
    params: any,
    token: string | null
  ) => {
    return instance
      .delete(url, {
        headers: {
          Authorization: token,
        },
        data: params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  const getMethodWithHeaders = (url: any, headers: any) => {
    return instance
      .get(url, { headers })
      .then((response: any) => {
        return response.data;
      })
      .catch((err: any) => {
        return err;
      });
  };

  const getMethodWithBodyAndHeaders = (url: any, body: any, headers: any) => {
    return instance
      .get(url, {
        data: body,
        headers: headers,
      })
      .then((response: any) => {
        return response.data;
      })
      .catch((err: any) => {
        return err;
      });
  };

  return {
    postMethod,
    getMethod,
    patchMethod,
    patchMethodHeaders,
    patchMethodFormData,
    deleteMethod,
    deleteMethodPayload,
    postMethodHeaders,
    postFormDataMethod,
    getMethodWithHeaders,
    getMethodWithBodyAndHeaders,
  };
};

export default HelperModule;
