import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useMediaQueryHook } from "../../hooks/useMediaQueryHook";
import deleteIcon from "../../assets/svgs/Delete_frame.svg";
import clockIcon from "../../assets/svgs/clock.svg";
import locationIcon from "../../assets/svgs/location.svg";
import boyImage from "../../assets/svgs/eventBoy.svg";
import HelperModule from "../../helpers/HelperModule";
import ApiServices from "../../helpers/ApiServices";
import { useParams } from "react-router-dom";
import moment from "moment";
const EventDetailsCard = () => {
  const { sm, md } = useMediaQueryHook();
  const { id } = useParams();
  const [eventData, setEventData] = useState<any>({});

  function date_formatter(eventDateTime: any, eventEndDate: any) {
    const start = moment(eventDateTime);
    const end = moment(eventEndDate);
    const formattedStart = start.format("DD MMM, hh:mma");
    const formattedEnd = end.format("hh:mma");
    return `${formattedStart} to ${formattedEnd}`;
  }
  const EventDetailsData = async () => {
    const token = sessionStorage.getItem("token");
    try {
      let response = await HelperModule().getMethodWithHeaders(
        ApiServices.base_URL + ApiServices.event_details + `${id}`,
        { Authorization: token }
      );
      if (response?.success) {
        setEventData(response?.data?.eventDetails);
      }
    } catch (error) {
      console.log(error);
    }
  };
  function truncateString(str: string, maxLength: number) {
    if (str?.length > maxLength) {
      return str?.slice(0, maxLength - 3) + "...";
    } else {
      return str;
    }
  }
  useEffect(() => {
    EventDetailsData();
  }, []);

  return (
    <Box>
      <Box
        bgcolor={"white"}
        p={!md ? 1.5 : 4}
        width={!sm?'92%':!md ? "96%" : "80%"}
        borderRadius={2}
        display={!md ? "block" : "flex"}
        gap={3}
        position={"relative"}
      >
        <Box>
          <img
            src={eventData?.imageUrl || null}
            alt="thumnail"
            width={!md ? "100%" : "200px"}
            height={!md ? "300px" : "200px"}
            style={{
              borderRadius: 7,
            }}
          />
        </Box>
        <Box
          display={!md ? "block" : "flex"}
          gap={3}
          alignItems={"center"}
          bgcolor={"#f8f8f8"}
          p={!md ? 1 : 2}
          borderRadius={2}
          width={!sm ? '95%' : !md ? "97%" : "75%"}
        >
          <Box mt={!md ? 2 : 0}>
            <Typography color={"#6C3EB9"} fontSize={!sm ? 18 : 22} fontWeight={"bold"}>
              {eventData.eventName}
            </Typography>
            <Box display={!sm ? 'block' : "flex"} gap={2} >
              <Box display={"flex"} gap={1}>
                <img src={clockIcon} alt="clock" width={"18px"} />
                <Typography fontSize={"12px"} fontWeight={100}>
                  {date_formatter(
                    eventData.eventDateTime,
                    eventData.eventEndDate
                  )}
                </Typography>
              </Box>
              <Box display={"flex"} gap={1} alignItems={'center'} mt={!sm ? 1 : 0}>
                <img src={locationIcon} alt="location" width={"18px"} />
                <Typography fontSize={"12px"} fontWeight={100}>
                  {eventData?.venue}
                </Typography>
              </Box>
            </Box>
            <Typography fontSize={"12px"} color={"#767980"} pt={1}>
              {truncateString(eventData?.userMessage, 40)}
            </Typography>
            <Typography fontWeight={"bold"} fontSize={"14px"} pt={1}>
              Total Contributes
            </Typography>
            <Typography color={"#6C3EB9"} fontSize={"20px"} fontWeight={"bold"}>
              ${" "}
              {eventData?.totalContributions
                ? eventData?.totalContributions
                : 0}
            </Typography>
          </Box>
        </Box>
        {!md ? null : (
          <Box position={"absolute"} right={0} top={22}>
            <img src={boyImage} alt="boyImage" width={"150px"} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default EventDetailsCard;
