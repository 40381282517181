import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import AuthRoutes from "./routes/AuthRoutes";
import { useAppDispatch, useAppSelector } from "./hooks";
import { setIsLogin } from "./store/AuthReducer";
import AppRoutes from "./routes/AppRoutes";

function App() {
  const dispatch = useAppDispatch();
  const { IsLogin } = useAppSelector((state) => state?.Auth);
  useEffect(() => {
    if (sessionStorage.getItem("token")) {
      dispatch(setIsLogin(true));
    } else {
      dispatch(setIsLogin(false));
    }
  }, [IsLogin]);
  return (
    <>
      <Router>{IsLogin ? <AppRoutes /> : <AuthRoutes />}</Router>
    </>
  );
}

export default App;
