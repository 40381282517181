import React, { Fragment } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Dashboard from "../pages/dashboard/Dashboard";
import Users from "../pages/users/Users";
import Navbar from "../components/Navbar";
import WebSettings from "../pages/webSettings/WebSettings";
import Sidebar from "../components/Sidebar";
import { Box } from "@mui/material";
import { useMediaQueryHook } from "../hooks/useMediaQueryHook";
import Themes from "../pages/themes/Themes";
import ProfileSettings from "../pages/profileSettings/ProfileSettings";
import Transactions from "../pages/transactions/Transactions";
import Gift4DayUsers from "../pages/gift4dayUsers/Gift4DayUsers";
import OccationDetails from "../pages/themes/OccationDetails";
import UserDetails from "../pages/users/UserDetails";
import EventDetails from "../components/EventDetails";
import Notifications from "../pages/notifications/Notifications";
const AppRoutes = () => {
  const { md } = useMediaQueryHook();

  return (
    <Fragment>
      <Box display="flex" width={"100%"} bgcolor={"#f8f8f8"} height={"100%"} >
        {md && <Sidebar />}
        <Box width={!md ? "100%" : "83%"} marginLeft={!md ? "0%" : "16%"}>
          <Navbar md={md} />
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/users" element={<Users />} />
            <Route path="/user/:id" element={<UserDetails />} />
            <Route path="/web_settings" element={<WebSettings md={md} />} />
            <Route path="/themes" element={<Themes />} />
            <Route path="/theme/:id" element={<OccationDetails />} />
            <Route path="/event/:id" element={<EventDetails />} />
            <Route path="/profile_settings" element={<ProfileSettings />} />
            <Route path="/transactions" element={<Transactions />} />
            <Route path="/gift4day_users" element={<Gift4DayUsers />} />
            <Route path="/notifications" element={<Notifications />} />
            <Route path="*" element={<Navigate to={"/"} replace={true} />} />
          </Routes>
        </Box>
      </Box>
    </Fragment>
  );
};

export default AppRoutes;
