import { configureStore } from '@reduxjs/toolkit'
import AuthReducer from './AuthReducer'
import ProfileReducer from './ProfileReducer'
import Gift4dayUsers from './Gift4dayReducer'
import ThemesReducer from './ThemesReducer'
import UserReducer from './UserReducer'
import WebSettingsReducer from './WebSettingsReducer'
import DashboardReducer from './DashboardReducer'



export const store = configureStore({
    reducer: {
        Auth: AuthReducer,
        Profile: ProfileReducer,
        Gift4day: Gift4dayUsers,
        Themes: ThemesReducer,
        Users: UserReducer,
        WebSettings: WebSettingsReducer,
        Dashboard: DashboardReducer
    },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch