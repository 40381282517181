import { Box, Button, Drawer, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import logo from "../assets/svgs/Gift4day.svg";
import dashboardIcon from "../assets/svgs/home.svg";
import user from "../assets/svgs/user.svg";
import users from "../assets/svgs/users.svg";
import settings from "../assets/svgs/settings.svg";
import themes from "../assets/svgs/edit.svg";
import { useLocation, useNavigate } from "react-router-dom";
import transactions from "../assets/svgs/transactions.svg";
import activeSettings from "../assets/svgs/activeSettings.svg";
import activeUser from "../assets/svgs/activeUser.svg";
import activeUsers from "../assets/svgs/activeUsers.svg";
import activeThemes from "../assets/svgs/activeEdit.svg";
import activeTransactions from "../assets/svgs/activeCoinshand.svg";
import activeDashboard from "../assets/svgs/activeHome.svg";
import bgImage from "../assets/svgs/sidebarBG.svg";
import logout from "../assets/svgs/logout.svg";
import { setIsLogin } from "../store/AuthReducer";
import { useDispatch } from "react-redux";
import CustomConfirmModal from "./CustomConfirmModal";
import HelperModule from "../helpers/HelperModule";
import ApiServices from "../helpers/ApiServices";

const permittedOptions = [
    {
        icon: user,
        activeIcon: activeUser,
        route: "/users",
        title: "Users",
    },
    {
        icon: themes,
        activeIcon: activeThemes,
        route: "/themes",
        title: "Themes",
    },
    {
        icon: users,
        activeIcon: activeUsers,
        route: "/gift4day_users",
        title: "Gift4Day Users",
    },
    {
        icon: transactions,
        activeIcon: activeTransactions,
        route: "/transactions",
        title: "Transactions",
    },
    {
        icon: settings,
        activeIcon: activeSettings,
        route: "/web_settings",
        title: "Web Settings",
    },
];
const MobileSideBar = ({ drawerOpen, setDrawerOpen }: any) => {

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const location = useLocation();

    const [sidebarOptions, setSidebarOptions] = useState([...permittedOptions]);
    const [permissions, setPermissions] = useState<any>([]);
    const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);

    const currentPath = location.pathname;
    useEffect(() => {
        getProfileDetails();
    }, []);

    useEffect(() => {
        if (permissions.length > 0) {
            const filteredOptions = permittedOptions?.filter((option) =>
                permissions.includes(option?.title?.toLowerCase())
            );
            setSidebarOptions([...filteredOptions]);
        } else {
            setSidebarOptions([...permittedOptions]);
        }
    }, [permissions]);

    const getProfileDetails = async () => {
        const token = sessionStorage.getItem("token");
        try {
            const response = await HelperModule().getMethodWithHeaders(
                ApiServices.base_URL + ApiServices.profile_details,
                { Authorization: token }
            );
            if (response.message === "Success") {
                localStorage.setItem("email", response?.data?.email);
                if (response.data.Role === "SubAdmin") {
                    setPermissions(response?.data?.Permissions.split(","));
                } else setSidebarOptions([...permittedOptions]);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleLogout = () => {
        sessionStorage.removeItem("token");
        dispatch(setIsLogin(null));
        localStorage.setItem("enqueLogout", "1");
        navigate("/login");
    };

    return (
        <>
            <CustomConfirmModal
                open={isLogoutModalOpen}
                onConfirm={handleLogout}
                title="Logout"
                onClose={() => setIsLogoutModalOpen(false)}
                confirmText="Yes"
                cancelText="No"
                content="Are you sure want to logout"
            />
            <Drawer open={drawerOpen} onClose={() => setDrawerOpen(false)} >
                <Box
                    width="fit-content"
                    height="100vh"
                    bgcolor="white"
                    position={"fixed"}
                    // display={'flex'}
                    // flexDirection={'column'}
                    justifyContent={'space-between'}
                    style={{
                        backgroundImage: `url(${bgImage})`,
                        backgroundSize: "contain",
                    }}
                >
                    <Box>

                        {/* logo */}
                        <Box width={"60%"} ml={2} mt={5}>
                            <img src={logo} alt="logo" width={"100%"} />
                        </Box>
                        {/* lists */}
                        <Box
                            style={{
                                marginTop: "20px",
                                padding: "0px 10px",
                            }}
                        >
                            <Box
                                style={{
                                    display: "flex",
                                    padding: "8px 15px",
                                    marginBottom: "20px",
                                    alignItems: "center",
                                    gap: "10px",
                                    cursor: "pointer",
                                    backgroundColor:
                                        "/" === currentPath ? "rgba(242, 234, 255, 1)" : "white",
                                    color: "/" === currentPath ? "rgba(108, 62, 185, 1)" : "black",
                                    borderRadius: 2,
                                }}
                                onClick={() => { setDrawerOpen(false); navigate(`${"/"}`) }}
                            >
                                <img
                                    src={"/" === currentPath ? activeDashboard : dashboardIcon}
                                    alt={"Dashboard"}
                                    width={"20px"}
                                />
                                <Typography style={{ fontSize: "16px" }}>Dashboard</Typography>
                            </Box>
                            {sidebarOptions?.map((option, ind) => {
                                return (
                                    <Box
                                        key={ind}
                                        style={{
                                            display: "flex",
                                            padding: "8px 15px",
                                            marginBottom: "20px",
                                            alignItems: "center",
                                            gap: "10px",
                                            cursor: "pointer",
                                            backgroundColor:
                                                option.route === currentPath
                                                    ? "rgba(242, 234, 255, 1)"
                                                    : "white",
                                            color:
                                                option.route === currentPath
                                                    ? "rgba(108, 62, 185, 1)"
                                                    : "black",
                                            borderRadius: 2,
                                        }}
                                        onClick={() => { setDrawerOpen(false); navigate(`${option.route}`) }}
                                    >
                                        <img
                                            src={
                                                option.route === currentPath
                                                    ? option.activeIcon
                                                    : option.icon
                                            }
                                            alt={option.title}
                                            width={"20px"}
                                        />
                                        <Typography style={{ fontSize: "16px" }}>
                                            {option.title}
                                        </Typography>
                                    </Box>
                                );
                            })}
                            <Box
                                style={{
                                    display: "flex",
                                    padding: "8px 15px",
                                    marginBottom: "10px",
                                    alignItems: "center",
                                    gap: "10px",
                                    cursor: "pointer",
                                    backgroundColor:
                                        "/profile_settings" === currentPath
                                            ? "rgba(242, 234, 255, 1)"
                                            : "white",
                                    color:
                                        "/profile_settings" === currentPath
                                            ? "rgba(108, 62, 185, 1)"
                                            : "black",
                                    borderRadius: 2,
                                }}
                                onClick={() => { setDrawerOpen(false); navigate(`${"/profile_settings"}`) }}
                            >
                                <img
                                    src={
                                        "/profile_settings" === currentPath ? activeSettings : settings
                                    }
                                    alt={"profile_settings"}
                                    width={"20px"}
                                />
                                <Typography style={{ fontSize: "16px" }}>
                                    Profile Settings
                                </Typography>
                            </Box>
                            <Button
                                onClick={() => setIsLogoutModalOpen(true)}
                                startIcon={<img src={logout} alt="add" width={20} />}
                                sx={{
                                    textTransform: "none",
                                    color: "black",
                                    marginLeft: 5,
                                    position: "absolute",
                                    bottom: 20,
                                    fontSize:'16px'
                                }}
                            >
                                Logout
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Drawer>
        </>
    );
};

export default MobileSideBar;
