import { Box, Button, TextField, Typography } from "@mui/material";
import React, { useEffect } from "react";
import ApiServices from "../../helpers/ApiServices";
import HelperModule from "../../helpers/HelperModule";
import { useMediaQueryHook } from "../../hooks/useMediaQueryHook";
import trash from "../../assets/svgs/trash.svg";
import upload from "../../assets/svgs/upload.svg";
import addIcon from "../../assets/svgs/plus.svg";
import { enqueueSnackbar } from "notistack";
const FAQ = ({
  handleChangeCallback,
  data,
  handleAddFilesCallback,
  handleIdChangeCallback,
  handleDeleteFAQCallback,
}: any) => {
  const { md } = useMediaQueryHook();
  useEffect(() => {
    getData();
  }, []);

  // Functions Block

  // Adding the new feild with dummy Q&A
  const handleAddQuestion = () => {
    handleChangeCallback((prev: any) => [
      ...prev,
      { Question: "", Answer: "" },
    ]);
  };

  //  to change the question and answer values
  const handleChange = (index: any, key: any, value: any) => {
    handleChangeCallback((prevData: any) => {
      const newData: any = [...prevData];
      newData[index][key] = value;
      return newData;
    });
  };
  // to store the deleting ID's in the array of sectionData which is related the FAQ's
  const handleDeleteIds = (id: any, index: any) => {
    handleDeleteFAQCallback((prev: any) => [...prev, id], index);
  };

  //  to preview the images when selected by taking file and the index of that file and by changing obj to base64
  const previewImage = (file: any, index: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const imageData = reader.result;
        resolve(imageData);
        handleChange(index, "Changed_Url", imageData); // to handle the base64 url maded the dummy changed URL from the backend to show image properly in the frontend
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  // while changing the image catching the actual image file object to send backend to store that in s3
  // const handleImageChange = (index: any, event: any, id: any) => {
  //   const imageObject = event.target.files[0];
  //   handleAddFilesCallback((prev: any) => [...prev, imageObject]); // adding the file in the sectionData of the FAQ
  //   handleIdChangeCallback((prev: any) => [...prev, id]); // in the same, catching the id's of the image to send backend
  //   previewImage(event.target.files[0], index);
  // };

  const handleImageChange = (index: any, event: any, id: any) => {
    const imageObject = event.target.files[0];
    console.log("imageObject: ", imageObject);

    if (!imageObject) return;

    const reader = new FileReader();
    reader.onload = (e: any) => {
      const img = new Image();
      img.onload = () => {
        if (img.naturalWidth == 360 || img.naturalHeight == 430) {
          // Image size is within limits, you can proceed with uploading or processing
          alert("Image size is within limits.");
          handleAddFilesCallback((prev: any) => [...prev, imageObject]);
          handleIdChangeCallback((prev: any) => [...prev, id]);
          previewImage(event.target.files[0], index);
        } else {
          enqueueSnackbar(
            "Image size exceeded. Maximum size allowed is 360x430.",
            {
              variant: "error",
              anchorOrigin: { vertical: "top", horizontal: "right" },
              style: { fontFamily: "Poppins", fontWeight: "500" },
            }
          );
        }
      };
      img.src = e.target.result;
    };

    reader.readAsDataURL(imageObject);
  };
  const getData = async () => {
    const token =  sessionStorage.getItem("token");
    try {
      const response = await HelperModule().getMethodWithHeaders(
        ApiServices.base_URL +
        ApiServices.section_details +
        "?search_param=FAQ",
        { Authorization: token }
      );
      if (response.success) {

        handleChangeCallback(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Box
      width={!md ? "89%" : "70%"}
      maxHeight={"64vh"}
      overflow={"scroll"}
      paddingX={!md ? 1 : 2}
      mx={"auto"}
      marginLeft={!md ? 2 : "auto"}
      sx={{
        "&::-webkit-scrollbar": {
          width: "0px", // Remove scrollbar width
          background: "transparent", // Optional: Hide scrollbar track
        },
      }}
    >
      <Box>
        <Box marginTop={1}>
          <Typography fontSize={"14px"}>Paragraph</Typography>
          <TextField
            name="Paragraph"
            fullWidth
            onChange={(e) => handleChange(0, "Paragraph", e.target.value)}
            value={data[0]?.Paragraph}
            size="small"
            variant="outlined"
            sx={{
              "& fieldset": { border: "none" },
              border: "1px solid grey",
            }}
            inputProps={{
              style: {
                padding: "3px 10px",
                fontSize: "14px",
              },
              maxLength: 20,
            }}
          />
        </Box>
        <Box position={"relative"} marginTop={1.5}>
        <Box display={'flex'} alignItems='center' justifyContent={"space-between"}>
                  <Typography
                    sx={{ fontSize: "14px", color: "rgba(0, 0, 0, 1)" }}
                  >
                    Image
                  </Typography>
                  <Typography fontSize={'12px'}> <span style={{ color: 'red', }}>Image criteria :</span> Dimensions should be 360*430</Typography>
                </Box>
          <Box position={"relative"}>
            <Box
              border={"1px solid grey"}
              justifyContent={"center"}
              display={"flex"}
              bgcolor={"#f8f8f8"}
            >
              {data[0]?.Changed_Url ? (
                <img
                  src={data[0]?.Changed_Url}
                  alt="image"
                  width={!md ? "150px" : "250px"}
                  style={{ margin: "auto" }}
                />
              ) : (
                <img
                  src={data[0]?.ImageUrl}
                  alt="image"
                  width={!md ? "150px" : "250px"}
                  style={{ margin: "auto" }}
                />
              )}
            </Box>
            <Box position={"absolute"} top={2} right={5}>
              <img
                src={upload}
                alt=""
                style={{ position: "absolute", right: 0, width: "20px" }}
              />
              <TextField
                type="file"
                name="imageUrl"
                onChange={(e) => handleImageChange(0, e, data[0]?.id)}
                size="small"
                variant="outlined"
                sx={{
                  "& fieldset": { border: "none" },
                  opacity: "0",
                  width: "20px",
                  height: "20px",
                }}
                inputProps={{ style: { padding: 0 } }}
              />
            </Box>
          </Box>
        </Box>
        {data?.map((ele: any, index: any) => (
          <Box key={index}>
            {ele?.Question !== null && (
              <Box marginTop={1.5}>
                <Typography fontSize={"14px"}>Question</Typography>
                <Box display="flex" gap={1} alignItems={"center"}>
                  <TextField
                    onChange={(e) =>
                      handleChange(index, "Question", e.target.value)
                    }
                    size="small"
                    value={ele?.Question}
                    variant="outlined"
                    sx={{
                      "& fieldset": { border: "none" },
                      border: "1px solid grey",
                      top: 0,

                      width: "90%",
                    }}
                    inputProps={{
                      style: {
                        padding: "3px 10px",
                        fontSize: "14px",
                      },
                      // maxLength: 200,
                    }}
                  />
                </Box>
                <Box marginTop={1}>
                  <Typography fontSize={"14px"}>Answer</Typography>
                  <Box display="flex" gap={1} alignItems={"center"}>
                    <TextField
                      onChange={(e) =>
                        handleChange(index, "Answer", e.target.value)
                      }
                      size="small"
                      fullWidth
                      value={ele?.Answer}
                      variant="outlined"
                      sx={{
                        "& fieldset": { border: "none" },
                        border: "1px solid grey",
                        top: 0,

                        width: "90%",
                      }}
                      inputProps={{
                        style: {
                          padding: "3px 10px",
                          fontSize: "14px",
                        },
                        // maxLength: 200,
                      }}
                    />
                    <Box style={{ cursor: "pointer" }}>
                      <img
                        src={trash}
                        alt=""
                        width={"25px"}
                        onClick={() => handleDeleteIds(ele.id, index)}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        ))}
        <Button
          variant="outlined"
          sx={{
            marginTop: "10px",
            bgcolor: "rgb(108,62,185)",
            textTransform: "none",
            color: "#fff",
            border: "none",
            "&:hover": {
              bgcolor: "rgb(82, 40, 153)", // Maintain the same background color on hover
              border: "none",
            },
            "&:focus": {
              outline: "none", // Remove the focus outline
            },
          }}
          onClick={handleAddQuestion}
          startIcon={<img src={addIcon} alt="add" width={20} />}
        >
          Add FAQ
        </Button>
      </Box>
    </Box>
  );
};

export default FAQ;
