import { Box, Typography } from "@mui/material";
import React, { Fragment, useState } from "react";
import Profile from "./Profile";
import PaymentSettings from "./PaymentSettings";
import HelpAndSupport from "./HelpAndSupport";
import { useMediaQueryHook } from "../../hooks/useMediaQueryHook";

const ProfileSettings = () => {
  const { sm, md } = useMediaQueryHook();
  const tabs = [
    { title: "Edit Profile", id: 1 },
    { title: "Payment Settings", id: 2 },
    { title: "Help & Support", id: 4 },
  ];
  const [value, setValue] = React.useState<number>(1);

  const handleChange = (newValue: any) => {
    setValue(newValue);
  };
  return (
    <Box>
      <Box width={"99%"} gap={2} height={!md ? "100%" : "85vh"} marginTop={11}>
        <Box
          display="flex"
          paddingX={!md ? 2 : 3}
          gap={!md ? 3 : 5}
          bgcolor={"white"}
          width={!md ? "90%" : "60%"}
          whiteSpace={'nowrap'}
          paddingY={2}
          sx={{ borderTopLeftRadius: 10, borderTopRightRadius: 10,overflowX:'scroll' }}
          className="hide-scrollbar"
        >
          {tabs.map((ele, index) => (
            <Typography
              key={index}
              onClick={() => handleChange(ele.id)}
              style={{
                borderBottom:
                  ele.id === value
                    ? "2px solid rgba(108, 62, 185, 1)"
                    : "2px solid transparent",
                padding: "15px 0px 5px 0px",
                height: "fit-content",
                cursor: "pointer",
                fontSize: "14px",
                fontWeight: 500,
                color:
                  ele.id === value
                    ? "rgba(108, 62, 185, 1)"
                    : "rgba(115, 115, 115, 1)",
              }}
            >
              {ele.title}
            </Typography>
          ))}
        </Box>
        {value === 1 && <Profile md={md} sm={sm} />}
        {value === 2 && <PaymentSettings md={md} sm={sm} />}
        {value === 4 && <HelpAndSupport md={md} sm={sm} />}
      </Box>
    </Box>
  );
};

export default ProfileSettings;
