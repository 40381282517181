import React, { Fragment } from "react";
import "./customTable.css";
import { Typography } from "@mui/material";

import editIcon from "../assets/svgs/Frame772543656.svg";
import deleteIcon from "../assets/svgs/Delete_frame.svg";

interface TableColumn {
  id: string;
  label: string;
  sortable?: boolean;
}

interface DynamicTableProps {
  columns: TableColumn[];
  data: any;
  title?: string;
  callback?: any;
  statusCallback?: any;
}
const CustomTable: React.FC<DynamicTableProps> = ({
  columns,
  data,
  title,
  callback,
  statusCallback,
}) => {
  return (
    <Fragment>
      <table>
        <thead>
          <tr style={{ background: "white" }}>
            {columns.map((column) => (
              <th key={column.id} style={{ textAlign: "center",whiteSpace:'nowrap' }}>
                <Typography fontSize={14}>{column.label}</Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="tableBody">
          {data.map((row: any, rowIndex: any) => (
            <tr key={rowIndex}>
              {columns.map((column) => (
                <td
                  key={column.id}
                  style={{
                    textAlign: "center",
                  }}
                >
                  {title === "users" && column.id === "status" ? (
                    <Typography
                      textAlign={"center"}
                      fontSize={12}
                      padding="4px"
                      borderRadius={3}
                      bgcolor={
                        row[column.id] === "Active"
                          ? "rgba(230, 255, 233, 1)"
                          : "rgba(255, 74, 74, 0.08)"
                      }
                      color={
                        row[column.id] === "Active"
                          ? "rgba(0, 171, 17, 1)"
                          : "rgba(255, 74, 74, 1)"
                      }
                      style={{ cursor: "pointer" }}
                      onClick={() => statusCallback({ isOpen: true, ...row })}
                    >
                      {row[column.id]}
                    </Typography>
                  ) : title === "users" && column.id === "name" ? (
                    <div
                      onClick={() => callback(`/user/${row.id}`)}
                      style={{
                        textDecoration: "none",
                        color: "rgba(108, 62, 185, 1)",
                        cursor: "pointer",
                      }}
                    >
                      <Typography fontSize={13}>{row[column.id]}</Typography>
                    </div>
                  ) : title === "users" && column.id === "edit" ? (
                    <div>
                      {/* <span style={{ cursor: "pointer" }}>
                        {row[column.id]}
                      </span> */}
                      <img src={editIcon} style={{ cursor: "pointer" }} />
                    </div>
                  ) : title === "users" && column.id === "delete" ? (
                    <>
                      <img src={deleteIcon} style={{ cursor: "pointer" }} />
                    </>
                  ) : (
                    <Typography fontSize={13}>{row[column.id]}</Typography>
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </Fragment>
  );
};

export default CustomTable;
