import { createSlice } from '@reduxjs/toolkit';

interface initialStateProps {
    profileData: any;
    updatedSuccessModal: boolean;
    notifications: any
}

const initialState: initialStateProps = {
    profileData: [],
    updatedSuccessModal: false,
    notifications: [],
}

const ProfileReducer = createSlice({
    name: 'ProfileReducer',
    initialState,
    reducers: {
        setProfileData: (state, action) => {
            state.profileData = action?.payload
        },
        setUpdateSuccessModal: (state, action) => {
            state.updatedSuccessModal = action?.payload
        },
        setNotifications: (state, action) => {
            state.notifications = action?.payload
        },

    },
});

export const {
    setProfileData,
    setUpdateSuccessModal,
    setNotifications
} = ProfileReducer.actions;
export default ProfileReducer.reducer;