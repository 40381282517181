import React, { useState } from "react";
import { makeStyles, createStyles } from "@mui/styles";
import { InputBase, IconButton, Paper } from "@mui/material";
import SearchIcon from "../assets/svgs/search.svg";

const useStyles: any = makeStyles((theme: any) =>
  createStyles({
    root: {
      padding: "0px 4px",
      display: "flex",
      alignItems: "center",
      width: '100%',
      borderRadius: '20px',
      backgroundColor: "#F8F8F8",
      boxShadow: 'none',
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
      fontSize: '14px'

    },
  })
);

interface SearchBarProps {
  placeholder: string;
  onChange: (query: string) => void;
}

const SearchBar: React.FC<SearchBarProps> = ({ placeholder, onChange }) => {
  const classes = useStyles();
  const [query, setQuery] = useState("");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
    onChange(event.target.value);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onChange(query);
  };

  return (
    <Paper component="form" className={classes.root} onSubmit={handleSubmit}>
      <IconButton type="submit" aria-label="search" style={{ width: "20px" }}>
        <img src={SearchIcon} alt="search" width={16} />
      </IconButton>
      <InputBase
        className={classes.input}
        placeholder={placeholder}
        value={query}
        onChange={handleChange}
      />
    </Paper>
  );
};

export default SearchBar;
