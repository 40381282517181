import { Box, Typography, Pagination, Grid } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMediaQueryHook } from "../../hooks/useMediaQueryHook";
import UserDetailsCard from "./UserDetailsCard";
import EventsTable from "../../components/EventsTable";
import BarGraph from "../dashboard/BarGraph";
import CustomSuccesfulModal from "../../components/CustomSuccesfulModal";
import CustomConfirmModal from "../../components/CustomConfirmModal";
import { useAppDispatch, useAppSelector } from "../../hooks";
import HelperModule from "../../helpers/HelperModule";
import ApiServices from "../../helpers/ApiServices";
import SearchBar from "../../components/SearchBar";
import moment from "moment";
import {
  setUserDeleteModal,
  setUserDeleteSuccessModal,
  setUserStatusModal,
  setUserStatusSuccessModal,
} from "../../store/UserReducer";
import { setEmailChange } from "../../store/AuthReducer";
import edit from "../../assets/svgs/edit.svg";
import Card from "../../components/Card";
import SevenDayDateRangePicker from "../../components/SevenDayDateRangePicker";
import dayjs from "dayjs";
import DateRangePicker from "./DateRangePicker";
import UserCardsGrid from "../../components/UserCardsGrid";
const UserDetails = () => {
  const { id } = useParams();
  const { md } = useMediaQueryHook();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [user, setUser] = useState({});
  const [barData, setBardata] = useState([]);
  const [eventData, setEventData] = useState([]);
  const [totalPages, setTotalPages] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [input, setInput] = useState("");
  const [userCards, setUserCards] = useState({});
  console.log('userCards: ', userCards);

  const handleChange = (event: any, value: any) => {
    setCurrentPage(value);
  };
  const {
    userStatusModal,
    userStatusSuccessModal,
    userDeleteModal,
    userDeleteSuccessModal,
    barGraphStartDate,
    barGraphEndDate,
  } = useAppSelector((state) => state.Users);
  const columns = [
    { id: "SI_No", label: "S.NO" },
    { id: "eventName", label: "Event name" },
    { id: "eventDateTime", label: "Date & time" },
    { id: "attendees", label: "Attendees" },
    { id: "totalContribution", label: "Total Contributions" },
    { id: "view", label: "" },
  ];
  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([getUserDetails(), UserCardsData()]);
    };
    fetchData();
  }, []);

  useEffect(() => {
    getUserContribution();
  }, [barGraphEndDate]);

  useEffect(() => {
    getUserEvents();
  }, [currentPage, input]);

  const handleInput = (query: any) => {
    setInput(query.toLowerCase());
  };

  const navigateUserDetails = (path: string) => {
    navigate(path);
  };

  const getUserDetails = async () => {
    const token = sessionStorage.getItem("token");
    try {
      let response = await HelperModule().getMethodWithHeaders(
        ApiServices.base_URL + ApiServices.get_user_detail + `/${id}`,
        { Authorization: token }
      );
      if (response?.success) {
        setUser(response?.data?.userDetails);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getUserEvents = async () => {
    const token = sessionStorage.getItem("token");
    try {
      let response = await HelperModule().getMethodWithHeaders(
        ApiServices.base_URL +
        ApiServices.user_events +
        `/${id}?page=${currentPage}&searchData=${input}`,
        { Authorization: token }
      );
      if (response?.success) {
        setEventData(response?.data?.totalEvents);
        setTotalPages(response?.data?.TotalPages);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getUserContribution = async () => {
    const token = sessionStorage.getItem("token");
    const startDate = barGraphStartDate
      ? dayjs(barGraphEndDate).format("YYYY-MM-DD")
      : "";
    const endDate = barGraphEndDate
      ? dayjs(barGraphStartDate).format("YYYY-MM-DD")
      : "";
    try {
      let response = await HelperModule().getMethodWithHeaders(
        ApiServices.base_URL +
        ApiServices.userContribution +
        `?user=${id}&startDate=${startDate}&endDate=${endDate}`,
        { Authorization: token }
      );
      if (response?.success) {
        let bar_data = response?.data?.map((item: any) => ({
          x: moment(item.date).format("ddd"),
          y:
            parseFloat(item?.totalContribution) === null
              ? 0
              : parseFloat(item?.totalContribution),
        }));
        console.log("bar_data: ", bar_data);
        setBardata(bar_data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const editUserStatus = async () => {
    const status = userStatusModal.status === "Active" ? "Inactive" : "Active";
    let data = {
      id: id,
      status: status,
    };
    const token = sessionStorage.getItem("token");
    try {
      let response = await HelperModule().patchMethod(
        ApiServices.base_URL + ApiServices.editUser,
        data,
        { Authorization: token }
      );
      console.log("edit PAi response", response);
      if (response?.success) {
        dispatch(setUserStatusSuccessModal(true));
        dispatch(setUserStatusModal({ isOpen: false }));
        getUserDetails();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const deleteUser = async () => {
    const token = sessionStorage.getItem("token");
    try {
      let response = await HelperModule().deleteMethod(
        ApiServices.base_URL + ApiServices.userDelete + `${id}`,
        { Authorization: token }
      );
      if (response?.success) {
        dispatch(setUserDeleteModal({ isOpen: false }));
        dispatch(setUserDeleteSuccessModal(true));
        getUserDetails();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const UserCardsData = async () => {
    const token = sessionStorage.getItem("token");
    try {
      let response = await HelperModule().getMethodWithHeaders(
        ApiServices.base_URL + ApiServices.userCards + `/${id}`,
        { Authorization: token }
      );
      if (response) {
        let res = {
          "Total Events": response?.data?.totalEvents,
          "Ongoing Events": response?.data?.totalOngoingEvents,
          "Completed events": response?.data?.totalCompletedEvents,
          "Total Contributions": response?.data?.totalContributions,
        };
        setUserCards(response?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Fragment>
      <CustomConfirmModal
        open={userStatusModal.isOpen}
        onClose={() => dispatch(setUserStatusModal({ isOpen: false }))}
        title="Confirm"
        content={`Are you sure ${userStatusModal.status === "Active" ? "inactive" : "active"
          } this User?`}
        confirmText="Yes"
        cancelText="No"
        onConfirm={() => {
          editUserStatus();
          console.log("api call for update the user");
        }} // trigger change status of the user API
      />

      {/* update status Success modal */}
      <CustomSuccesfulModal
        isOpen={userStatusSuccessModal}
        onClose={() => dispatch(setUserStatusSuccessModal(false))}
        title="Successful"
        content={`The User has been ${userStatusModal.status}`}
      />

      {/* for delete the occasion */}
      <CustomConfirmModal
        open={userDeleteModal.isOpen}
        onClose={() => dispatch(setUserDeleteModal({ isOpen: false }))}
        title="Delete"
        content="Are you sure you want to delete?"
        confirmText="Yes"
        cancelText="No"
        onConfirm={() => {
          deleteUser();
          console.log("delete the user from here");
        }}
      />

      {/* Delete User success modal */}
      <CustomSuccesfulModal
        isOpen={userDeleteSuccessModal}
        onClose={() => dispatch(setUserDeleteSuccessModal(false))}
        title="Successful"
        content={`The User has been deleted`}
      />
      <Box
        marginTop={11}
        minHeight={"85vh"}
        padding={!md ? 1 : 0}
        width={!md ? '97%' : "100%"}
      >
        <Box display={"flex"} alignItems={"center"} gap={1}>
          <Box
            style={{
              background: "white",
              borderRadius: "50%",
              height: "25px",
              width: "20px",
              textAlign: "center",
              cursor: "pointer",
            }}
            onClick={() => navigate(-1)}
          >
            <Typography>&lt;</Typography>
          </Box>
          <Typography fontSize={"14px"}>Back</Typography>
        </Box>

        <Box mt={2} mb={1}>
          <UserDetailsCard data={user} />
        </Box>

        <Grid container mt={2}>
          <Grid item xs={12} md={5} mr={1} bgcolor={"white"} borderRadius={3} >
            <Box
              padding={!md ? "0px 10px" : "15px 15px"}
              display="flex"
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography fontSize={!md?12:18} color="rgba(53, 53, 53, 1)">
                Total Contributions
              </Typography>
              <Box border={"1px solid #a3a3a3"} width={"50%"} borderRadius={3}>
                <DateRangePicker />
              </Box>
            </Box>
            <BarGraph md={md} barData={barData} />
          </Grid>

          <UserCardsGrid data={userCards} />
        </Grid>
        <Box width={!md ? "95%" : "97%"} bgcolor={"white"} borderRadius={2} p={!md ? 1 : 2} mt={2} >
          <Box display="flex" justifyContent={"space-between"} alignItems={'center'}>
            <Typography width={!md ? '30%' : '20%'} fontSize={!md ? '12px' : '16px'}>Event List ({eventData?.length})</Typography>
            <Box width={!md ? '60%' : '20%'}>
              <SearchBar placeholder={!md ? 'by name...' : "search by name..."} onChange={handleInput} />
            </Box>
          </Box>
          <Box width="100%" overflow={'scroll'} className="hide-scrollbar">
            <EventsTable columns={columns} data={eventData} title="Events" callback={navigateUserDetails} />
          </Box>
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handleChange}
          />
        </Box>
      </Box>
    </Fragment>
  );
};

export default UserDetails;
