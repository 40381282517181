import React from "react";

export const DateOrTime = ({ timestamp }: any) => {
  const getTimeOrDate = () => {
    const timestampDate = new Date(timestamp);
    const today = new Date();

    if (
      timestampDate.getDate() === today.getDate() &&
      timestampDate.getMonth() === today.getMonth() &&
      timestampDate.getFullYear() === today.getFullYear()
    ) {
      // If it's today, return the time
      return timestampDate.toLocaleTimeString();
    } else {
      // Otherwise, return the date
      return timestampDate.toLocaleDateString();
    }
  };

  return <div>{getTimeOrDate()}</div>;
};
