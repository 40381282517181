import { Box, Typography } from "@mui/material";
import React from "react";

type CardProps = {
  icon: any;
  title: string;
  data: any;
};
const Card = ({ icon, title, data }: CardProps) => {
  return (
    <Box
      height={"155px"}
      //   width={"250px"}
      display="flex"
      justifyContent={"center"}
      alignItems={"center"}
      borderRadius={3}
      bgcolor={"white"}
    >
      <Box
        display="flex"
        alignItems={"center"}
        width={"80%"}
        justifyContent={"space-between"}
      >
        <Box>
          <Typography style={{ fontSize: "12px" }}>{title}</Typography>
          <Typography
            style={{
              fontSize: "25px",
              marginTop: "8px",
              color: "rgba(108, 62, 185, 1)",
            }}
          >
            {data}
          </Typography>
        </Box>
        <Box
          height={"45px"}
          width={"45px"}
          borderRadius={50}
          bgcolor={"rgba(242, 234, 255, 1)"}
          display="flex"
          justifyContent={"center"}
          alignItems={"center"}
        >
          <img src={icon} alt="icon" width="23px" />
        </Box>
      </Box>
    </Box>
  );
};

export default Card;
