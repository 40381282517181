import { Box, Button, TextField, Typography } from "@mui/material";
import React, { Fragment, useEffect } from "react";
import { useMediaQueryHook } from "../../hooks/useMediaQueryHook";
import HelperModule from "../../helpers/HelperModule";
import ApiServices from "../../helpers/ApiServices";
import upload from "../../assets/svgs/upload.svg";
import deleteIcon from "../../assets/svgs/trash.svg";
import addIcon from "../../assets/svgs/plus.svg";
import { enqueueSnackbar } from "notistack";

const Hero = ({
  handleChangeCallback,
  data,
  handleAddFilesCallback,
  handleIdChangeCallback,
  handleIdDeleteCallback,
}: any) => {
  const { md } = useMediaQueryHook();

  useEffect(() => {
    getData();
  }, []);

  const handleChange = (index: any, key: any, value: any) => {
    handleChangeCallback((prevData: any) => {
      const newData: any = [...prevData];
      newData[index][key] = value;
      return newData;
    });
  };

  const previewImage = (file: any, index: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const imageData = reader.result;
        resolve(imageData);
        handleChange(index, "Changed_Url", imageData);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  // need an id while image change not for adding
  // for adding images not need to add id
  const handleImageChange = (index: any, event: any, id: any) => {
    const imageObject = event.target.files[0];

    if (!imageObject) return;

    const reader = new FileReader();
    reader.onload = (e: any) => {
      const img = new Image();
      img.onload = () => {
        if (img.naturalWidth == 750 || img.naturalHeight == 700) {
          // Image size is within limits, you can proceed with uploading or processing
          handleAddFilesCallback((prev: any) => [...prev, imageObject]);
          handleIdChangeCallback((prev: any) => [...prev, id]); // 
          previewImage(event.target.files[0], index);
        } else {
          enqueueSnackbar(
            "Image size exceeded. Maximum size allowed is 750x700.",
            {
              variant: "error",
              anchorOrigin: { vertical: "top", horizontal: "right" },
              style: { fontFamily: "Poppins", fontWeight: "500" },
            }
          );
        }
      };
      img.src = e.target.result;
    };

    reader.readAsDataURL(imageObject);
  };

  const handleAddImage = () => {
    handleChangeCallback((prev: any) => [...prev, { id: 4, imageUrl: "", Heading: data[0]?.Heading, Paragraph: data[0]?.Paragraph }]); // id 4 is for add the image
  };

  const getData = async () => {
    const token = sessionStorage.getItem("token");
    try {
      const response = await HelperModule().getMethodWithHeaders(
        ApiServices.base_URL +
        ApiServices.section_details +
        "?search_param=hero",
        { Authorization: token }
      );
      if (response.success) {
        handleChangeCallback(response.data);
        // dispatch()
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Fragment>

      <Box
        width={!md ? "89%" : "70%"}
        maxHeight={"64vh"}
        overflow={"scroll"}
        paddingX={!md ? 1 : 2}
        mx={"auto"}
        marginLeft={!md ? 2 : "auto"}
        sx={{
          "&::-webkit-scrollbar": {
            width: "0px", // Remove scrollbar width
            background: "transparent", // Optional: Hide scrollbar track
          },
        }}
      >
        <Box marginTop={1}>
          <Box>
            <Typography sx={{ fontSize: "14px", color: "rgba(0, 0, 0, 1)" }}>
              Heading
            </Typography>
            <TextField
              fullWidth
              onChange={(e) => handleChange(0, "Heading", e.target.value)}
              value={data[0]?.Heading}
              name="Heading"
              size="small"
              variant="outlined"
              sx={{
                "& fieldset": { border: "none" },
                border: "1px solid grey",
              }}
              inputProps={{
                style: {
                  padding: "3px 10px",
                  fontSize: "14px",
                  fontWeight: "lighter",
                },
                maxLength: 20,
              }}
            />
          </Box>
          <Box marginTop={1.5}>
            <Typography sx={{ fontSize: "14px", color: "rgba(0, 0, 0, 1)" }}>
              Paragraph
            </Typography>
            <TextField
              // multiline
              name="Paragraph"
              fullWidth
              onChange={(e) => handleChange(0, "Paragraph", e.target.value)}
              value={data[0]?.Paragraph}
              size="small"
              variant="outlined"
              sx={{
                "& fieldset": { border: "none" },
                border: "1px solid grey",
                fontSize: "12px",
              }}
              inputProps={{
                style: {
                  padding: "3px 10px",
                  fontSize: "14px",
                  fontWeight: "lighter",
                },
                maxLength: 200,
              }}
            />
          </Box>
          {data?.map((ele: any, index: any) => (
            <Fragment key={index}>
              {/* {index !== 0 && ( */}
              <Box marginTop={1.5}>
                <Box display={'flex'} alignItems='center' justifyContent={"space-between"}>
                  <Typography
                    sx={{ fontSize: "14px", color: "rgba(0, 0, 0, 1)" }}
                  >
                    Image
                  </Typography>
                  <Typography fontSize={'12px'}> <span style={{ color: 'red', }}>Image criteria :</span> Dimensions should be 750*700</Typography>
                </Box>
                <Box position={"relative"}>
                  <Box
                    border={"1px solid grey"}
                    justifyContent={"center"}
                    display={"flex"}
                    bgcolor={"#f8f8f8"}
                  >
                    {ele.Changed_Url ? (
                      <img
                        src={ele.Changed_Url}
                        alt="image"
                        width={!md ? "150px" : "250px"}
                        style={{ margin: "auto" }}
                      />
                    ) : (
                      <img
                        src={ele.ImageUrl}
                        alt="image"
                        width={!md ? "150px" : "250px"}
                        style={{ margin: "auto" }}
                      />
                    )}
                  </Box>

                  <Box position={"absolute"} top={2} right={25} >
                    <img
                      src={upload}
                      alt=""
                      style={{
                        position: "absolute",
                        right: 0,
                        width: "20px",
                      }}
                    />
                    <TextField
                      type="file"
                      name="imageUrl"
                      onChange={(e) => handleImageChange(index, e, ele.id)}
                      size="small"
                      variant="outlined"
                      sx={{
                        "& fieldset": { border: "none" },
                        opacity: "0",
                        width: "20px",
                        height: "20px",
                      }}
                    />
                  </Box>
                  {index > 0 ? <Box position={"absolute"} top={2} right={0}>
                    <img
                      onClick={() => handleIdDeleteCallback(ele.id, index)}
                      src={deleteIcon}
                      alt=""
                      style={{
                        position: "absolute",
                        right: 0,
                        width: "20px",
                        cursor: "pointer"
                      }}
                    />
                  </Box> : null}
                </Box>
              </Box>
              {/* )} */}
            </Fragment>
          ))}
          {data?.length < 5 && <Button
            variant="outlined"
            sx={{
              marginTop: "10px",
              bgcolor: "rgb(108,62,185)",
              textTransform: "none",
              color: "#fff",
              border: "none",
              "&:hover": {
                bgcolor: "rgb(82, 40, 153)", // Maintain the same background color on hover
                border: "none",
              },
              "&:focus": {
                outline: "none", // Remove the focus outline
              },
            }}
            onClick={handleAddImage}
            startIcon={<img src={addIcon} alt="add" width={20} />}
          >
            Add Image
          </Button>}
        </Box>
        {/* ))} */}
      </Box>
      {/* // )} */}
    </Fragment>
  );
};

export default Hero;
