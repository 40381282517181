import { Box, Typography } from "@mui/material";
import React from "react";
import moment from "moment";
import { useMediaQueryHook } from "../../hooks/useMediaQueryHook";
import deleteIcon from "../../assets/svgs/Delete_frame.svg";
import boyImage from "../../assets/svgs/usersBoy.svg";
import { useAppDispatch } from "../../hooks";

import {
  setUserDeleteModal,
  setUserStatusModal,
} from "../../store/UserReducer";
const UserDetailsCard = ({ data }: any) => {
  console.log("datataa", data);
  const { sm, md } = useMediaQueryHook();
  const dispatch = useAppDispatch();
  return (
    <Box>
      <Box
        bgcolor={"white"}
        p={!md ? 1.5 : 3}
        width={!sm ? '92%' : !md ? "98%" : "80%"}
        borderRadius={2}
        display={!md ? "block" : "flex"}
        gap={3}
        position={"relative"}
      >
        <Box margin={!md ? "auto" : '0'} display={'flex'} justifyContent={'center'} >
          <img
            src={data?.imageUrl}
            alt="thumnail"
            width={!md ? "80%" : "120px"}
            height={!sm ? '200px' : !md ? "300px" : "120px"}
            style={{
              borderRadius: 7,
            }}
          />
        </Box>
        <Box
          display={!md ? "block" : "flex"}
          gap={3}
          alignItems={"center"}
          bgcolor={"#f8f8f8"}
          p={!md ? 1 : 3}
          borderRadius={2}
          width={!sm ? '95%' : !md ? "98%" : "68%"}

        >
          <Box p={1}>
            <Typography fontSize={18} fontWeight={"bold"}>
              User ID :{data?.id}
            </Typography>

            <Box display={"flex"} gap={3}>
              <Typography color={"#6C3EB9"} fontSize={20} fontWeight={"bold"}>
                {data?.name}
              </Typography>
              <Box
                display={"flex"}
                alignItems={"center"}
                px={"15px"}
                py={"1px"}
                fontSize={"14px"}
                borderRadius={3}
                bgcolor={
                  data?.status === "Active"
                    ? "rgba(230, 255, 233, 1)"
                    : "rgba(255, 74, 74, 0.08)"
                }
                color={
                  data?.status === "Active"
                    ? "rgba(0, 171, 17, 1)"
                    : "rgba(255, 74, 74, 1)"
                }
                style={{ cursor: "pointer" }}
                onClick={() => {
                  dispatch(
                    setUserStatusModal({ isOpen: true, status: data.status })
                  );
                }}
              >
                {data?.status}
              </Box>
              <Box
                bgcolor={"rgba(255, 74, 74, 0.08)"}
                display={"flex"}
                alignItems={"center"}
                p={"2px"}
                borderRadius={1}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  dispatch(setUserDeleteModal({ isOpen: true }));
                }}
              >
                <img src={deleteIcon} alt="trash" width={22} />
              </Box>
            </Box>
            <Box display={"flex"} gap={4} py={1} flexWrap={'wrap'}>
              <Box>
                <Typography fontSize={"12px"} color={"rgba(48, 48, 48, 0.48)"}>
                  Member Since
                </Typography>

                <Typography fontSize={"12px"}>
                  {moment(data?.createdAt).format("MMM YY")}
                </Typography>
              </Box>
              <Box>
                <Typography fontSize={"12px"} color={"rgba(48, 48, 48, 0.48)"}>
                  Email
                </Typography>
                <Typography fontSize={"12px"}>{data?.email}</Typography>
              </Box>
              <Box>
                <Typography fontSize={"12px"} color={"rgba(48, 48, 48, 0.48)"}>
                  Phone Number
                </Typography>
                <Typography fontSize={"12px"}>{data?.phoneNumber}</Typography>
              </Box>
              <Box>
                <Typography fontSize={"12px"} color={"rgba(48, 48, 48, 0.48)"}>
                  Date of Birth
                </Typography>

                <Typography fontSize={"12px"}>
                  {moment(data?.dateOfBirth).format("DD-MM-YYYY")}
                </Typography>
              </Box>
              <Box>
                <Typography fontSize={"12px"} color={"rgba(48, 48, 48, 0.48)"}>
                  Gender
                </Typography>
                <Typography fontSize={"12px"}> {data?.gender}</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        {!md ? null : (
          <Box position={"absolute"} right={0} top={78}>
            <img src={boyImage} alt="boyImage" width={"150px"} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default UserDetailsCard;
