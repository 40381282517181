import React, { useState, useEffect } from "react";
import { Box, Button, Grid, Typography, Pagination } from "@mui/material";
import banner from "../../assets/svgs/banner.svg";
import edit from "../../assets/svgs/edit.svg";
import Card from "../../components/Card";
import { useMediaQueryHook } from "../../hooks/useMediaQueryHook";
import HelperModule from "../../helpers/HelperModule";
import ApiServices from "../../helpers/ApiServices";
import BarGraph from "./BarGraph";
import PieGraph from "./PieGraph";
import CustomTable from "../../components/CustomTable";
import { useNavigate } from "react-router-dom";
import EventsTable from "../../components/EventsTable";
import {
  setUserStatusModal,
  setUserStatusSuccessModal,
} from "../../store/UserReducer";
import { useAppDispatch, useAppSelector } from "../../hooks";
import CustomConfirmModal from "../../components/CustomConfirmModal";
import CustomSuccesfulModal from "../../components/CustomSuccesfulModal";
import { enqueueSnackbar } from "notistack";
import dayjs from "dayjs";
import SevenDayDateRangePicker from "../../components/SevenDayDateRangePicker";
import CardsGrid from "../../components/CardsGrid";
const moment = require("moment");
// import  from '@mui/material/Pagination';

const userColumns = [
  { id: "id", label: "U.NO" },
  { id: "name", label: "Name" },
  { id: "email", label: "Email" },
  { id: "phoneNumber", label: "Phone Number" },
  { id: "status", label: "Status" },
];
//  create the columns as you want
const eventColums = [
  { id: "SI_No", label: "S.NO" },
  { id: "eventName", label: "Event Name" },
  { id: "eventDateTime", label: "Date & Time" },
  { id: "totalContribution", label: "Total Contributes" },
  { id: "view", label: "status" },
];
const Dashboard = () => {
  const dispatch = useAppDispatch();
  const { userStatusModal, userStatusSuccessModal } = useAppSelector(
    (state) => state.Users
  );
  const { barGraphStartDate, barGraphEndDate } = useAppSelector(
    (state) => state.Dashboard
  );

  const { pieGraphStartDate, pieGraphEndDate } = useAppSelector(
    (state) => state.Dashboard
  );
  const [buttonStatus, setButtonStatus] = useState("Users");
  const [insightData, setInsightdata] = useState({});
  const [OverviewData, setOverviewData] = useState({
    UsersCount: 0,
    EventsCount: 0,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [UserList, setUserList] = useState([]);
  const [EventList, setEventList] = useState([]);
  const { md, sm } = useMediaQueryHook();
  const navigate = useNavigate();
  useEffect(() => {
    getInsightData();
    let enquebar = localStorage.getItem("enquebar");
    if (enquebar) {
      enqueueSnackbar("Logged in Successfully", {
        variant: "success",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        style: { fontFamily: "Poppins", fontWeight: "500" },
      });
    }
    localStorage.removeItem("enquebar");
  }, []);

  useEffect(() => {
    dashboardData();
  }, [buttonStatus, currentPage, pieGraphEndDate]);

  useEffect(() => {
    Bargraph_data();
  }, [barGraphStartDate]);

  const navigateUserDetails = (path: string) => {
    navigate(path);
  };

  const handleChange = (event: any, value: any) => {
    setCurrentPage(value);
  };
  const handleButtonStatus = (x: any) => {
    setButtonStatus(x);
    setCurrentPage(1);
    if (x === "Users") {
      setEventList([]);
    } else {
      setUserList([]);
    }
  };
  const [barData, setBardata] = useState([]);
  const Bargraph_data = async () => {
    const token = sessionStorage.getItem("token");
    const startDate = barGraphStartDate
      ? dayjs(barGraphEndDate).format("YYYY-MM-DD")
      : "";
    const endDate = barGraphEndDate
      ? dayjs(barGraphStartDate).format("YYYY-MM-DD")
      : "";
    try {
      let response = await HelperModule().getMethodWithHeaders(
        ApiServices.base_URL +
        ApiServices.totalContribution +
        `?startDate=${startDate}&endDate=${endDate}`,
        { Authorization: token }
      );
      let bar_data = response.data.map((item: any) => ({
        x: moment(item.date).format("ddd"),
        y:
          parseFloat(item?.totalContributors) === 0
            ? 0
            : parseFloat(item?.totalContributors),
      }));
      setBardata(bar_data);
    } catch (error) {
      console.log(error);
    }
  };
  const dashboardData = async () => {
    const token = sessionStorage.getItem("token");
    const startDate = pieGraphStartDate
      ? dayjs(pieGraphStartDate).format("YYYY-MM-DD")
      : "";
    const endDate = pieGraphEndDate
      ? dayjs(pieGraphEndDate).format("YYYY-MM-DD")
      : "";
    try {
      let response = await HelperModule().getMethodWithHeaders(
        ApiServices.base_URL +
        ApiServices.overview +
        `?page=${currentPage}&start_date=${startDate}&end_date=${endDate}`,
        { Authorization: token }
      );
      if (response) {
        let res = {
          UsersCount: response.data.UsersCount,
          EventsCount: response.data.EventsCount,
        };

        setUserList(response.data.AllUsers);
        setEventList(response.data.AllEvents);
        setOverviewData(res);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getInsightData = async () => {
    const token = sessionStorage.getItem("token");
    try {
      let response = await HelperModule().getMethodWithHeaders(
        ApiServices.base_URL + ApiServices.insight_data,
        { Authorization: token }
      );

      if (response) {
        // let res = {
        //   "Total Events": response.data.totalEvents,
        //   "Ongoing Events": response.data.ongoingEvents,
        //   "Completed events": response.data.completedEvents,
        //   "Total Contributions": response.data.totalContributions,
        // };
        setInsightdata(response?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const editUserStatus = async (userStatusModal: any) => {
    const status = userStatusModal.status === "Active" ? "Inactive" : "Active";
    let data = {
      id: userStatusModal.id,
      status: status,
    };
    const token = sessionStorage.getItem("token");
    try {
      let response = await HelperModule().patchMethod(
        ApiServices.base_URL + ApiServices.editUser,
        data,
        { Authorization: token }
      );
      console.log("edit PAi response", response);
      if (response?.success) {
        dispatch(setUserStatusSuccessModal(true));
        dispatch(setUserStatusModal({ isOpen: false }));
        dashboardData();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleUserStatusModal = (row: any) => {
    dispatch(setUserStatusModal(row));
  };

  return (
    <>
      {/* for update the status of the User */}
      <CustomConfirmModal
        open={userStatusModal.isOpen}
        onClose={() => dispatch(setUserStatusModal({ isOpen: false }))}
        title="Confirm"
        content={`Are you sure ${userStatusModal.status === "Active" ? "inactive" : "active"
          } this User?`}
        confirmText="Yes"
        cancelText="No"
        onConfirm={() => {
          editUserStatus(userStatusModal);
        }} // trigger change status of the user API
      />

      {/* update status Success modal */}
      <CustomSuccesfulModal
        isOpen={userStatusSuccessModal}
        onClose={() => dispatch(setUserStatusSuccessModal(false))}
        title="Successful"
        content={`The Occasion has been ${userStatusModal.status}`}
      />
      <Box
        display="flex"
        width={!md ? "97.5%" : "100%"}
        // gap={2}
        bgcolor="#f8f8f8"
        marginTop={8}
        // height={"88vh"}
        className="hide-scrollbar"
      >
        <Box width={"100%"}>
          <Box
            sx={{
              height: "120px",
              marginTop: "20px",
              paddingLeft: !md ? '10px' : 'auto'
            }}
          >
            <img src={banner} alt="" width={"100%"} />
          </Box>
          <Grid
            container
            rowGap={2}
            width={!md ? "100%" : "99%"}
            margin={"auto"}
            xs={12}
            md={12}
            // maxWidth={'100%'}
            paddingLeft={!md ? '10px' : '0px'}
          >
            <Grid item xs={12} lg={6} paddingRight={!md ? 0 : 2} >
              <CardsGrid data={insightData} />
            </Grid>
            <Grid item lg={6} xs={12}>
              <Box bgcolor={"white"} borderRadius={2}>
                <Box
                  padding={!md ? "0px 10px" : "15px 15px"}
                  display="flex"
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Typography fontSize={18} color="rgba(53, 53, 53, 1)">
                    Total Contributes
                  </Typography>
                  <Box
                    border={"1px solid #a3a3a3"}
                    width={"50%"}
                    borderRadius={3}
                  >
                    <SevenDayDateRangePicker />
                  </Box>
                </Box>
                <BarGraph md={md} barData={barData} />
              </Box>
            </Grid>

            <Grid
              item
              lg={5}
              xs={12}
              sx={{
                borderTopLeftRadius: "12px",
                borderBottomLeftRadius: "12px",
              }}
              bgcolor={"white"}
            >
              <Box py={2} pr={!md ? 0 : 3}>
                <PieGraph overview_data={OverviewData} />
              </Box>
            </Grid>
            <Grid
              item
              lg={7}
              xs={12}
              bgcolor={"white"}
              sx={{
                borderTopRightRadius: "12px",
                borderBottomRightRadius: "12px",
              }}
            >
              <Box display="flex" gap={2} marginY={1} py={2} >
                <Button
                  sx={{
                    fontSize: "12px",
                    background: buttonStatus === "Users" ? "white" : "#f2eaff",
                    border:
                      buttonStatus === "Users" ? "1px solid #6c3eb9" : "white",
                    color: "#6c3eb9",
                    textTransform: "none",
                  }}
                  onClick={() => handleButtonStatus("Users")}
                >
                  Users
                </Button>
                <Button
                  sx={{
                    fontSize: "12px",
                    background: buttonStatus === "Events" ? " white" : "#f2eaff",
                    border:
                      buttonStatus === "Events" ? " 1px solid #6c3eb9" : "white",
                    textTransform: "none",
                    color: "#6c3eb9",
                  }}
                  onClick={() => handleButtonStatus("Events")}
                >
                  Events
                </Button>
              </Box>
              <Box>
                <Typography paddingY={1} fontSize={"14px"} fontWeight={'bold'}>
                  {buttonStatus === "Users" ? "User List" : "Events List"}{" "}
                  <span>
                    (
                    {buttonStatus === "Users"
                      ? UserList.length
                      : EventList.length}
                    )
                  </span>
                </Typography>
                <Box
                  height={200}
                  overflow={"scroll"}
                  className="hide-scrollbar"
                  px={1}
                  bgcolor={'white'}
                >
                  {buttonStatus === "Users" ? (
                    <CustomTable
                      columns={userColumns}
                      data={UserList}
                      title="users"
                      callback={navigateUserDetails}
                      statusCallback={handleUserStatusModal}
                    />
                  ) : (
                    <EventsTable
                      columns={eventColums}
                      callback={navigateUserDetails}
                      data={EventList}
                      title="Events"
                    />
                  )}
                </Box>
                <Pagination
                  count={
                    buttonStatus === "Users"
                      ? Math.ceil(OverviewData.UsersCount / 10)
                      : Math.ceil(OverviewData.EventsCount / 10)
                  }
                  page={currentPage}
                  onChange={handleChange}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default Dashboard;
