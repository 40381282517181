import { Grid } from '@mui/material'
import React from 'react'
import Card from './Card'
import totoalContributionsIcon from '../assets/svgs/totalContributions.svg'
import calendarEventsIcon from '../assets/svgs/calendarEvents.svg'

const UserCardsGrid = ({ data }: any) => {
    return (
        <Grid container spacing={2} xs={12} md={7} mt={1} justifyContent={"center"}>
            {/* {Object.entries(insightData).map((ele, ind) => ( */}
            <Grid item md={6} xs={12}>
                <Card
                    icon={calendarEventsIcon}
                    title={"Total Events"}
                    data={data?.totalEvents}
                />
            </Grid>
            <Grid item md={6} xs={12}>
                <Card
                    icon={calendarEventsIcon}
                    title={"Ongoing Events"}
                    data={data?.ongoingEvents}
                />
            </Grid>
            <Grid item md={6} xs={12}>
                <Card
                    icon={calendarEventsIcon}
                    title={"Completed events"}
                    data={data?.completedEvents}
                />
            </Grid>
            <Grid item md={6} xs={12}>
                <Card
                    icon={totoalContributionsIcon}
                    title={"Total Contributions"}
                    data={data?.totalContributions}
                />
            </Grid>
            {/* ))} */}
        </Grid>
    )
}

export default UserCardsGrid