import { Box, Typography } from "@mui/material";
import React from "react";
import { DateOrTime } from "./DateOrTime";
import { useMediaQueryHook } from "../../hooks/useMediaQueryHook";

const NotificationCard = ({ notification }: any) => {
  const { md } = useMediaQueryHook()
  return (
    <Box
      mt={1}
      p={!md ? 1 : 2}
      bgcolor={notification.isRead ? "#f8f8f8" : "rgba(242, 242, 242, 1)"}
      borderRadius={2}
      width={!md?'94%':'97%'}
    >
      <Box display={"flex"} justifyContent={"space-between"} width={'100%'}>
        <Typography
          fontSize={"15px"}
          color={
            notification.isRead
              ? "rgba(115, 115, 115, 1)"
              : "rgba(108, 62, 185, 1)"
          }
          fontWeight={"bold"}
        >
          {notification.title}
        </Typography>
        <DateOrTime timestamp={notification.createdAt} />
      </Box>
      <Typography
        fontSize={"13px"}
        color={notification.isRead ? "#737373" : "#353535"}
        style={{ width: '100%', textWrap: 'wrap', overflow: 'hidden' }}
      >
        {notification.message}
      </Typography>
    </Box>
  );
};

export default NotificationCard;
