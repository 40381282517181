import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  setCreateThemeModal,
  setSuccessAddedThemes,
} from "../../store/ThemesReducer";
import uploadImgIcon from "../../assets/svgs/uploadImg.svg";
import HelperModule from "../../helpers/HelperModule";
import ApiServices from "../../helpers/ApiServices";
import { useParams } from "react-router-dom";
import { useMediaQueryHook } from "../../hooks/useMediaQueryHook";
const AddThemeModal = () => {
  const { id } = useParams();
  const { md } = useMediaQueryHook();
  const dispatch = useAppDispatch();
  const { createThemeModal } = useAppSelector((state) => state.Themes);
  const [themes, setThemes] = useState<any>([]);
  const handleAddThemes = (e: any) => {
    let files = e.target.files;
    setThemes((prev: any) => [...prev, ...files]);
  };
  const handleSubmit = async () => {
    const token = sessionStorage.getItem("token");
    const formData = new FormData();

    themes.forEach((theme: any) => formData.append("files", theme));
    formData.append("id", `${id}`);
    formData.append("key", "Themes");
    try {
      const response = await HelperModule().postFormDataMethod(
        ApiServices.base_URL + ApiServices.add_theme_and_message,
        formData,
        { Authorization: token }
      );
      if (response.success) {
        dispatch(setCreateThemeModal(false));
        dispatch(setSuccessAddedThemes(true));
        setThemes([]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Modal
      open={createThemeModal}
      onClose={() => dispatch(setCreateThemeModal(false))}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 3,
          minWidth: !md ? 300 : 500,
          maxWidth: 400,
          borderRadius: 2,
        }}
      >
        <Typography>Add Themes</Typography>
        <Box>
          <Box width={!md?"100%":"50%"}>
            <Typography fontSize={"12px"}>Upload Theme</Typography>
            <Box position={"relative"} mt={1}>
              <Box
                sx={{
                  border: "1px dashed grey",
                  borderRadius: 1,
                  height: "50px",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  paddingY: "5px",
                }}
              >
                <Box
                  display="flex"
                  justifyContent={"center"}
                  alignItems={"center"}
                  width="100%"
                >
                  <Box textAlign={"center"} width={"100%"}>
                    <img
                      src={uploadImgIcon}
                      alt=""
                      width={"40pxs"}
                      height={"30px"}
                    />
                    <Typography fontSize={"10px"} mt={-1}>
                      Upload Theme
                    </Typography>
                  </Box>
                </Box>
                <TextField
                  type="file"
                  sx={{
                    position: "absolute",
                    //   height: "100px",
                    opacity: "0",
                  }}
                  inputProps={{ multiple: true }}
                  onChange={handleAddThemes}
                />
              </Box>
            </Box>
          </Box>
          <Box mt={1} width={"100%"}>
            <Typography fontSize={10} mt={1}>
              Total ({themes.length})
            </Typography>
            {themes.length > 0 ? (
              <Box
                display={"flex"}
                width={"100%"}
                gap={1}
                mt={0.5}
                sx={{ overflowX: "scroll" }}
                className="hide-scrollbar"
                whiteSpace={"nowrap"}
              >
                {themes?.map((theme: any) => (
                  <Box
                    width={"90px"}
                    sx={{
                      flexShrink: 0,
                    }}
                  >
                    <img
                      src={URL.createObjectURL(theme)}
                      alt=""
                      width={"100%"}
                      height={"50px"}
                      style={{ display: "inline-block" }}
                    />
                  </Box>
                ))}
              </Box>
            ) : (
              <Box
                border={"1px solid lightgrey"}
                textAlign={"center"}
                py={2}
                mt={0.5}
              >
                <Typography color={"lightgrey"}>No Selected Themes</Typography>
              </Box>
            )}
          </Box>
        </Box>
        <Box marginTop={2} gap={2} display={"flex"}>
          <Button
            sx={{
              bgcolor: "rgba(242, 234, 255, 1)",
              textTransform: "none",
              color: "rgb(108,62,185)",
              border: "none",
              borderRadius: "15px",
              padding: "2px",
              fontSize: "12px",
              "&:hover": {
                bgcolor: "rgb(82, 40, 153)", // Maintain the same background color on hover
                border: "none",
                color: "white",
              },
              "&:focus": {
                outline: "none", // Remove the focus outline
              },
            }}
            onClick={handleSubmit}
          >
            save
          </Button>
          <Button
            sx={{
              textTransform: "none",
              color: "red",
              border: "none",
              fontSize: "12px",
              padding: "0px",
              "&:hover": {
                bgcolor: "rgba(242, 234, 255, 1)", // Maintain the same background color on hover
                border: "none",
                color: "red",
              },
              "&:focus": {
                outline: "none", // Remove the focus outline
              },
            }}
            onClick={() => dispatch(setCreateThemeModal(false))}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddThemeModal;
