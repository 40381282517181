import { Typography } from "@mui/material";
import moment from "moment";
import React from "react";

interface TableColumn {
  id: string;
  label: string;
  sortable?: boolean;
}
interface DynamicTableProps {
  columns: TableColumn[];
  data: any;
  title?: string;
  callback?: () => void;
  statusCallback?: () => void;
}
const RsvpTable: React.FC<DynamicTableProps> = ({ columns, data }: any) => {
  return (
    <table>
      <thead>
        <tr style={{ background: "white" }}>
          {columns.map((column: any) => (
            <th key={column.id} style={{ textAlign: "center",whiteSpace:'nowrap' }}>
              <Typography fontSize={14}>{column.label}</Typography>
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="tableBody">
        {data.map((row: any, rowIndex: any) => (
          <tr key={rowIndex}>
            {columns.map((column: any) => (
              <td
                key={column.id}
                style={{
                  textAlign: "center",
                  color: "#A3A3A3",
                }}
              >
                {column.id === "createdAt" ? (
                  <Typography fontSize={13}>
                    {moment(row[column.id]).format("DDMMM, hhA").toUpperCase()}
                  </Typography>
                ) : (
                  <Typography fontSize={13}>{row[column.id]}</Typography>
                )}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default RsvpTable;
