import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { Box, Typography } from "@mui/material";
import { useMediaQueryHook } from "../../hooks/useMediaQueryHook";
import HelperModule from "../../helpers/HelperModule";
import ApiServices from "../../helpers/ApiServices";
import { useNavigate } from "react-router-dom";
import ButtonAuth from "../../components/ButtonAuth";
import { enqueueSnackbar } from "notistack";

interface OtpProps {
  email: string;
}
function Otp({ email }: OtpProps) {
  const emailChange = localStorage.getItem("emailChange"); // this is from LS either admin, email changing or not
  const { md, sm } = useMediaQueryHook();
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [timeLeft, setTimeLeft] = useState(60);
  const [formData, setFormDataLocal] = useState<any>({})

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [timeLeft]);
  
  React.useEffect(() => {
    let formDataLocal = localStorage.getItem("profile_details");
    if (formDataLocal) {

      setFormDataLocal(JSON.parse(formDataLocal))
    }
  }, [])

  const formatTime = (time: any) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const VerifyOtp = async () => {
    const token = localStorage.getItem("token"); // need token when admin can changing the email from the profile settings
    if (otp.length === 6)
      try {
        const headers = emailChange ? { Authorization: token } : {}; // this check is for headers, if email change giving headers
        // this check is for Url, if email change Url also need to change
        const url = emailChange
          ? ApiServices.base_URL + ApiServices.email_verify
          : ApiServices.base_URL + ApiServices.verify_otp;
        const response = await HelperModule().postMethodHeaders(
          url,
          { email, otp },
          headers
        );
        if (response.success) {
          enqueueSnackbar(response?.message, {
            variant: "success",
            anchorOrigin: { vertical: "top", horizontal: "right" },
            style: { fontFamily: "Poppins", fontWeight: "500" },
          });
          sessionStorage.setItem("reset_token", response.data.reset_token);
          updateProfileDetails();
          if (emailChange) {
            navigate("/login");
          } else navigate("/reset_password");
        }
        localStorage.removeItem("emailChange"); // removing the emailChange because, when we changing the password while loggin in the email_verify otp triggering instead of verify_otp
      } catch (error) {
        console.log(error);
      }
  };

  const updateProfileDetails = async () => {
    const token = localStorage.getItem("token");
    // const formData: any = JSON.parse(localStorage.getItem("profile_details") || "");
    let newFormData = new FormData();
    newFormData.append("first_name", formData.first_name);
    newFormData.append("last_name", formData.last_name);
    newFormData.append("phoneNumber", formData.phoneNumber);
    newFormData.append("email", formData.email);


    if (typeof formData.imageUrl === "object") {
      newFormData.append("file", formData.imageUrl);
    }
    try {
      const response = await HelperModule().patchMethodFormData(
        ApiServices.base_URL + ApiServices.update_profile,
        newFormData,
        { Authorization: token }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleResendOtp = async () => {
    const token = sessionStorage.getItem("token");
    const url = emailChange
      ? ApiServices.base_URL + ApiServices.email_change_otp
      : ApiServices.base_URL + ApiServices.send_otp;
    try {
      const response = await HelperModule().postMethodHeaders(
        url,
        { email },
        { Authorization: token }
      );
      setTimeLeft(60);
      enqueueSnackbar(response?.data, {
        variant: "success",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        style: { fontFamily: "Poppins", fontWeight: "500" },
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Box
        style={{
          width: !md ? "100%" : "fit-content",
          marginTop: "30px",
        }}
      >
        <Typography
          style={{
            textAlign: "right",
            color: "rgba(29, 47, 82, 1)",
            fontSize: "13px",
            marginBottom: "5px",
          }}
        >
          {formatTime(timeLeft)}
        </Typography>
        <OtpInput
          value={otp}
          onChange={setOtp}
          numInputs={6}
          inputType="tel"
          skipDefaultStyles={false}
          renderSeparator={
            <span style={{ padding: !sm ? "5px" : !md ? "10px" : "20px" }}> </span>
          }
          renderInput={(props: any) => (
            <input
              {...props}
              onKeyDown={() => {
                if (otp.length === 6) VerifyOtp();
              }}
            />
          )}
          inputStyle={{
            width: !sm ? "13%" : !md ? "80px" : "50px",
            height: !sm ? "45px" : !md ? "80px" : "50px",
            borderRadius: "8px",
            border: "1px solid #f1f1f1",
            outline: "none",
            backgroundColor: "#f1f1f1",
          }}
        />
        {timeLeft > 0 ? null : (
          <Typography
            onClick={handleResendOtp}
            style={{
              color: "rgba(29, 47, 82, 1)",
              cursor: "pointer",
              fontSize: "14px",
              marginTop: "8px",
              textDecoration: "underline",
              textAlign: "right",
            }}
          >
            Resend
          </Typography>
        )}
        <Box width={"100%"}>
          <ButtonAuth md={md} text="Next" onClick={VerifyOtp} otp={true} />
        </Box>
      </Box>
    </>
  );
}

export default Otp;
