import { createSlice } from '@reduxjs/toolkit';

interface initialStateProps {
    userStatusModal: any;
    userStatusSuccessModal: boolean;
    userDeleteModal: any;
    userDeleteSuccessModal: any
    barGraphStartDate: any,
    barGraphEndDate: any
}

const initialState: initialStateProps = {
    userStatusModal: {},
    userStatusSuccessModal: false,
    userDeleteModal: {},
    userDeleteSuccessModal: false,
    barGraphStartDate: '',
    barGraphEndDate: ''
}

const Users = createSlice({
    name: 'Users',
    initialState,
    reducers: {
        setUserStatusModal: (state, action) => {
            state.userStatusModal = action?.payload
        },
        setUserStatusSuccessModal: (state, action) => {
            state.userStatusSuccessModal = action?.payload
        },
        setUserDeleteModal: (state, action) => {
            state.userDeleteModal = action?.payload
        },
        setUserDeleteSuccessModal: (state, action) => {
            state.userDeleteSuccessModal = action?.payload
        },
        setBarGraphStartDate: (state, action) => {
            state.barGraphStartDate = action?.payload
        },
        setBarGraphEndDate: (state, action) => {
            state.barGraphEndDate = action?.payload
        }
    },
});

export const {
    setUserStatusModal,
    setUserStatusSuccessModal,
    setUserDeleteModal,
    setUserDeleteSuccessModal,
    setBarGraphStartDate,
    setBarGraphEndDate
} = Users.actions;
export default Users.reducer;