import { Box } from "@mui/material";
import LoginScreen from "./LoginScreen";
import friends from "../../assets/freinds.png";
import { useMediaQueryHook } from "../../hooks/useMediaQueryHook";
import PictureComponent from "../../components/PictureComponent";

// type Props = {};

function Login() {
  const { md, sm } = useMediaQueryHook();

  return (
    <Box
      display="flex"
      minHeight="100vh"
      flexDirection={!md ? "column" : "row"}
    >
      <PictureComponent sm={sm} md={md} imgSrc={friends} />
      <LoginScreen md={md} sm={sm} />
    </Box>
  );
}

export default Login;
