import { z } from "zod";

const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{};:,<.>]).{8,}$/;

export const loginSchema = z.object({
  email: z.string().min(5, { message: "Email must be at least 5 characters long" }).email({ message: "Please enter a valid email" }),
  password: z
    .string()
    .min(8, { message: "Password must be at least 8 characters long" }),
});

export const emailSchema = z.object({
  email: z.string().email({ message: "Please enter a valid email" })
})

export const confirmPasswordSchema = z
  .object({
    password: z.string().refine((password) => passwordRegex.test(password), {
      message:
        "Password must contain 1 uppercase letter,1 lowercase letter,1 number, and 1 special character and should be atleast 8 characters",
    }),
    confirm_paddword: z.string(),
  })
  .refine((data) => data.password === data.confirm_paddword, {
    message: "Passwords don't match",
  });