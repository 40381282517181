import { Box, Button, Pagination, Typography } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useMediaQueryHook } from "../../hooks/useMediaQueryHook";
import TransactionsTable from "./TransactionsTable";
import HelperModule from "../../helpers/HelperModule";
import ApiServices from "../../helpers/ApiServices";
import { CSVLink } from "react-csv";
import downloadIcon from '../../assets/svgs/Download.svg'
const Transactions = () => {
  const { md } = useMediaQueryHook();
  const [transactions, setTransactions] = useState([])
  const [pagination, setPagination] = useState<any>({})
  const [currentPage, setCurrentPage] = useState(1)
  const columns = [
    { id: "id", label: "S.NO" },
    { id: "host_name", label: "Host Name" },
    { id: "eventname", label: "Event name" },
    { id: "eventEnd_DateTime", label: "Date & time" },
    { id: "total_contribution", label: "Total Contributions" },

    // { id: "age", label: "Age" },
    // Add more columns as needed
  ];
  useEffect(() => {
    getTransactions()
  }, [currentPage])

  const handleChange = (event: any, value: any) => {
    setCurrentPage(value);
  };
  console.log(currentPage, 'currentPage')
  const getTransactions = async () => {
    let token = sessionStorage.getItem('token')
    try {
      let response = await HelperModule().getMethodWithHeaders(ApiServices.base_URL + ApiServices.get_transctions + `?page=${currentPage}&limit=10`, {});
      if (response.success) {
        setTransactions(response.data)
        setPagination(response.pagination)
      }
      console.log(response);
    } catch (error) {

    }

  }

  return (
    <Fragment>
      <Box
        // display={!md ? "block" : "flex"}
        marginTop={11}
        minHeight={"85vh"}
        bgcolor={"white"}
        width={!md ? '100%' : "95%"}
        // borderTop={!md ? "none" : "1px solid grey"}
        padding={2}
        overflow={"scrollable"}
        borderRadius={3}
      >
        <Box display="flex" justifyContent={"space-between"} mb={2}>
          <Typography>Transactions</Typography>
          <CSVLink data={transactions}>
            <Button
              variant="outlined"
              sx={{
                // marginTop: "10px",
                bgcolor: "rgb(108,62,185)",
                textTransform: "none",
                color: "#fff",
                border: "none",
                fontSize: "12px",
                paddingX: "10px",
                paddingY: "2px",
                "&:hover": {
                  bgcolor: "rgb(82, 40, 153)", // Maintain the same background color on hover
                  border: "none",
                },
                "&:focus": {
                  outline: "none", // Remove the focus outline
                },
              }}
              startIcon={<img src={downloadIcon} alt="add" width={15} />}
            >
              Export
            </Button>
          </CSVLink>
        </Box>
        <Box width={'100%'} overflow={'scroll'} className='hide-scrollbar'>

          <TransactionsTable columns={columns} data={transactions} />
        </Box>
        <Pagination
          count={
            pagination.totalPages
          }
          page={currentPage}
          onChange={handleChange}
        />
      </Box>
    </Fragment>
  );
};

export default Transactions;
