import { Box, Drawer, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import notificationIcon from "../assets/svgs/notifications.svg";
import { useAppDispatch, useAppSelector } from "../hooks";
import { useNavigate } from "react-router-dom";
import HelperModule from "../helpers/HelperModule";
import ApiServices from "../helpers/ApiServices";
import { setNotifications, setProfileData } from "../store/ProfileReducer";
import menuIcon from '../assets/svgs/menu.svg'
import MobileSideBar from "./MobileSideDrawer";
const Navbar = ({ md }: any) => {
  const { profileData } = useAppSelector((state) => state.Profile);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [unReadNotifyCount, setUnReadNotifyCount] = useState<any>(0);
  const [drawerOpen, setDrawerOpen] = useState<any>(false);

  useEffect(() => {
    getProfileDetails();
    getNotifications();
  }, []);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen)
  }

  const getProfileDetails = async () => {
    const token = sessionStorage.getItem("token");
    try {
      const response = await HelperModule().getMethodWithHeaders(
        ApiServices.base_URL + ApiServices.profile_details,
        { Authorization: token }
      );
      localStorage.setItem("email", response?.data?.email);
      dispatch(setProfileData(response.data));
    } catch (error) {
      console.log(error);
    }
  };
  const sortData = (data: any) => {
    const sortedData = data.sort((a: any, b: any) => {
      const dateA: any = new Date(a.createdAt);
      const dateB: any = new Date(b.createdAt);
      return dateB - dateA;
    });
    return sortedData;
  };
  const getNotifications = async () => {
    const token = sessionStorage.getItem("token");
    try {
      const response = await HelperModule().getMethodWithHeaders(
        ApiServices.base_URL + ApiServices.notifications,
        { Authorization: token }
      );
      if (response.success) {
        dispatch(setNotifications(sortData(response.data)));
        let filterIsNotReadNotification = response.data.filter(
          (ele: any) => ele.isRead === false
        );
        setUnReadNotifyCount(filterIsNotReadNotification?.length);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box
      width={!md ? "100%" : "83%"}
      height={"50px"}
      borderRadius={2}
      display="flex"
      justifyContent={!md ? "space-between" : 'end'}
      bgcolor={"white"}
      paddingY={1}
      position={"fixed"}
      zIndex={2}
      alignItems={'center'}
    >
      {!md && (
        <>
          <Box marginLeft={2} onClick={toggleDrawer}>
            <img src={menuIcon} alt="" width={30} />
          </Box>
          <MobileSideBar drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
        </>
      )}
      <Box display="flex" gap={2} marginRight={3} alignItems={"center"}>
        <Box
          borderRadius={50}
          bgcolor={"#f8f8f8"}
          width={35}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          onClick={() => navigate("/notifications")}
          position={"relative"}
        >
          <img
            src={notificationIcon}
            alt="notification"
            width={20}
            height={25}
            style={{ padding: "5px" }}
          />
          <Box
            position={"absolute"}
            bgcolor={"rgba(108, 62, 185, 1)"}
            borderRadius={"100%"}
            height={15}
            width={15}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            top={0}
            right={0}
            fontSize={12}
            color="white"
          >
            {unReadNotifyCount}
          </Box>
        </Box>
        <Box
          style={{
            display: "flex",
            gap: "5px",
            alignItems: "center",
          }}
        >
          <Box
            height={"45px"}
            width={"45px"}
            borderRadius={50}
            border={1}
            overflow={"hidden"}
            onClick={() => navigate("/profile_settings")}
          >
            <img src={profileData?.imageUrl} alt="" width={"45px"} />
          </Box>
          <Box>
            <Typography style={{ fontSize: "12px" }}>
              {profileData?.first_name}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Navbar;
