import React from "react";
import {
  Dialog,
  DialogContent,
  Typography,
  IconButton,
  Box,
} from "@mui/material";
import saved from "../assets/svgs/saved.svg";
interface CustomModalProps {
  open: boolean;
  onClose?: () => void;
  title: string;
  icon: any;
  subTitle: string;
}

const CustomModal: React.FC<CustomModalProps> = ({
  open,
  onClose,
  title,
  icon,
  subTitle,
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <Box padding={2}>
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              // marginBottom: "20px",
            }}
          >
            <img src={saved} alt="" width={40} />
          </Box>
          <Box padding={2}>
            <Typography
              style={{
                textAlign: "center",
                fontSize: "22px",
                color: "#6C3EB9",
              }}
            >
              {title}
            </Typography>
            <Typography fontSize={15}>{subTitle}</Typography>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default CustomModal;
