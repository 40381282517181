import { Box } from "@mui/material";
import gift4DayLogo from "../assets/svgs/Gift4day.svg";
type PictureComponentProp = {
  sm?: boolean;
  md: boolean;
  imgSrc: string;
};
function PictureComponent({ sm, md, imgSrc }: PictureComponentProp) {
  return (
    <Box
      p={!md ? 0 : 2}
      width={!md ? "100%" : "45%"}
      sx={{
        background: "#F2EAFF",
        paddingTop: !md ? 0 : "4rem",
      }}
      display="flex"
      flexDirection={"column"}
    >
      <div
        style={{
          height: !md ? "50%" : "85%",
          width: !sm ? '100%' : !md ? "70%" : "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "auto",
        }}
      >
        <img
          src={imgSrc}
          style={{
            objectFit: "contain",
            maxHeight: "100%",
            maxWidth: !md ? "100%" : "80%",
          }}
          alt="friends"
        />
      </div>
      <Box
        display="flex"
        justifyContent={"center"}
        alignItems={"center"}
        marginTop={3}
        pb={!md ? 2 : 0}
      >
        <img
          src={gift4DayLogo}
          style={{
            objectFit: "contain",
            maxWidth: !sm ? '40%' : "20%",
          }}
          alt="icon"
        />
      </Box>
    </Box>
  );
}

export default PictureComponent;
