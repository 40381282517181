import { Box, Typography, TextField, Pagination } from "@mui/material";
import React, { useEffect, useState, Fragment } from "react";
import CustomTable from "../../components/CustomTable";
import { useMediaQueryHook } from "../../hooks/useMediaQueryHook";
import { useNavigate } from "react-router-dom";

import CustomConfirmModal from "../../components/CustomConfirmModal";
import {
  setUserStatusModal,
  setUserStatusSuccessModal,
} from "../../store/UserReducer";
import { useAppDispatch, useAppSelector } from "../../hooks";
import CustomSuccesfulModal from "../../components/CustomSuccesfulModal";
import HelperModule from "../../helpers/HelperModule";
import ApiServices from "../../helpers/ApiServices";
import SearchBar from "../../components/SearchBar";

const Users = () => {
  const { md } = useMediaQueryHook();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { userStatusModal, userStatusSuccessModal } = useAppSelector(
    (state) => state.Users
  );
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [input, setInput] = useState("");
  const handleInput = (query: any) => {
    setInput(query.toLowerCase());
  };
  const handleChange = (event: any, value: any) => {
    setCurrentPage(value);
  };

  const columns = [
    { id: "id", label: "U.NO" },
    { id: "name", label: "Name" },
    { id: "email", label: "Email" },
    { id: "phoneNumber", label: "Phone Number" },
    { id: 'totalEvents', label: 'Events' },
    { id: 'totalContribution', label: 'Contributions' },
    { id: "status", label: "Status" },
  ];

  const navigateUserDetails = (path: string) => {
    navigate(path);
  };
  useEffect(() => {
    getUsersList();
  }, [currentPage, input]);
  const getUsersList = async () => {
    const token = sessionStorage.getItem("token");
    try {
      let response = await HelperModule().getMethodWithHeaders(
        ApiServices.base_URL +
        ApiServices.get_users +
        `?page=${currentPage}&searchData=${input}`,
        { Authorization: token }
      );
      if (response?.success) {
        setUsers(response?.data?.usersData);
        setTotalPages(response?.data?.TotalPages);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const editUserStatus = async (userStatusModal: any) => {
    const status = userStatusModal.status === "Active" ? "Inactive" : "Active";
    let data = {
      id: userStatusModal.id,
      status: status,
    };
    const token = sessionStorage.getItem("token");
    try {
      let response = await HelperModule().patchMethod(
        ApiServices.base_URL + ApiServices.editUser,
        data,
        { Authorization: token }
      );
      console.log("edit PAi response", response);
      if (response?.success) {
        dispatch(setUserStatusSuccessModal(true));
        dispatch(setUserStatusModal({ isOpen: false, ...response.data }));
        getUsersList();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleUserStatusModal = (row: any) => {
    dispatch(setUserStatusModal(row));
  };
  return (
    <Fragment>
      {/* for update the status of the User */}
      <CustomConfirmModal
        open={userStatusModal.isOpen}
        onClose={() => dispatch(setUserStatusModal({ isOpen: false }))}
        title="Confirm"
        content={`Are you sure ${userStatusModal.status === "Active" ? "inactive" : "active"
          } this User?`}
        confirmText="Yes"
        cancelText="No"
        onConfirm={() => {
          editUserStatus(userStatusModal);
        }} // trigger change status of the user API
      />

      {/* update status Success modal */}
      <CustomSuccesfulModal
        isOpen={userStatusSuccessModal}
        onClose={() => dispatch(setUserStatusSuccessModal(false))}
        title="Successful"
        content={`The User has been ${userStatusModal?.status}`}
      />
      <Box
        marginTop={11}
        minHeight={"100xh"}
        // padding={!md ? 0 : 0}
        overflow={"scroll"}
        className="hide-scrollbar"
      >
        <Box width={!md ? "100%" : "95%"} mt={2} bgcolor={"white"} p={!md ? 0 : 2} py={2} borderRadius={1.5}>
          <Box display="flex" justifyContent={"space-between"} alignItems={'center'} px={1}>
            <Typography fontSize={!md ? '14px' : '16px'}>User List ({users.length})</Typography>
            <Box width={!md ? '50%' : '25%'}>

              <SearchBar placeholder="search by name..." onChange={handleInput} />
            </Box>
          </Box>
          <Box width={'100%'} overflow={'scroll'} className="hide-scrollbar">

            <CustomTable
              columns={columns}
              data={users}
              title="users"
              callback={navigateUserDetails}
              statusCallback={handleUserStatusModal}
            />
          </Box>
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handleChange}
          />
        </Box>
      </Box>
    </Fragment>
  );
};

export default Users;
