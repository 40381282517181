import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import HelperModule from "../../helpers/HelperModule";
import ApiServices from "../../helpers/ApiServices";
import { enqueueSnackbar } from "notistack";

const PaymentSettings = ({ sm,md }: any) => {
  const [formData, setFormData] = useState({
    payment_gateway: "",
    payment_fee: "",
  });
  const [isEdit, setIsEdit] = useState(false);
  useEffect(() => {
    getDetailsPayment();
  }, []);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prev: any) => ({ ...prev, [name]: value }));
  };
  const handleEdit = async (e: any) => {
    const token = sessionStorage.getItem("token");
    setIsEdit(!isEdit);
    if (isEdit) {
      if (
        formData.payment_gateway.length > 0 &&
        formData.payment_fee.length > 0
      ) {
        try {
          const response = await HelperModule().postMethodHeaders(
            ApiServices.base_URL + ApiServices.payments_api,
            formData,
            { Authorization: token }
          );
          if (response.message) {
            enqueueSnackbar(response.message, {
              variant: "success",
              anchorOrigin: { vertical: "top", horizontal: "right" },
              style: { fontFamily: "Poppins", fontWeight: "500" },
            });
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        enqueueSnackbar("Please fill all fields.", {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          style: { fontFamily: "Poppins", fontWeight: "500" },
        });
      }
    }
  };

  const getDetailsPayment = async () => {
    const token = sessionStorage.getItem("token");
    try {
      const response = await HelperModule().getMethodWithHeaders(
        ApiServices.base_URL + ApiServices.payments_api,
        { Authorization: token }
      );
      console.log(response, "response get profile");
      setFormData(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Box
      width={!md ? "87.5%" : "60%"}
      bgcolor={"white"}
      paddingX={3}
      paddingY={1}
      minHeight={!md ? '90vh' : 'auto'}
      maxHeight={'100vh'}
      sx={{ borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}
    >
      <Typography fontWeight={"bold"}>Payment Settings</Typography>
      <Box display="flex" gap={5} paddingY={1}>
        <Box width={!md ? "100%" : "80%"}>
          <Box
            display={!md ? "block" : "flex"}
            gap={3}
            flexDirection={!md ? "column" : "row"}
          >
            <Box width={!sm ? '98%' : !md ? "50%" : "50%"}>
              <Typography fontSize={"12px"}>Payment Gateway</Typography>
              <TextField
                id="input-with-icon-textfield"
                fullWidth
                disabled={!isEdit}
                name="payment_gateway"
                value={formData?.payment_gateway}
                onChange={handleChange}
                required
                size="small"
                variant="outlined"
                sx={{
                  "& fieldset": { border: "none" },
                  border: "1px solid lightgrey",
                  borderRadius: "10px",
                  paddingY: "4px",
                }}
                inputProps={{
                  style: {
                    padding: "3px 10px",
                    fontSize: "14px",
                  },
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box width={!sm ? '98%' : !md ? "50%" : "50%"} mt={!md ? 2 : 0}>
              <Typography fontSize={"12px"}>
                Platform Fee (in percentage)
              </Typography>
              <TextField
                fullWidth
                disabled={!isEdit}
                name="payment_fee"
                value={formData?.payment_fee}
                onChange={handleChange}
                required
                size="small"
                variant="outlined"
                sx={{
                  "& fieldset": { border: "none" },
                  border: "1px solid lightgrey",
                  borderRadius: "10px",
                  paddingY: "4px",
                }}
                inputProps={{
                  style: {
                    padding: "3px 10px",
                    fontSize: "14px",
                  },
                  endAdorement: <Box>%</Box>,
                }}
              />
            </Box>
          </Box>
          <Box marginTop={3}>
            <Button
              sx={{
                textTransform: "none",
                bgcolor: "rgba(242, 234, 255, 1)",
                color: "#6C3EB9",
                marginRight: "10px",
                borderRadius: "10px",
                padding: "5px",
                fontSize: "12px",
                "&:hover": {
                  bgcolor: "rgba(242, 234, 255, 1)", // Maintain the same background color on hover
                  border: "none",
                },
                "&:focus": {
                  outline: "none", // Remove the focus outline
                },
              }}
              onClick={handleEdit}
            >
              {isEdit ? "Save" : "Edit"}
            </Button>
            {isEdit && (
              <Button
                sx={{
                  textTransform: "none",
                  color: "#6C3EB9",
                  fontSize: "12px",
                }}
                onClick={() => {
                  isEdit && setIsEdit(false);
                }}
              >
                Cancel
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PaymentSettings;
