import { Box, Button, Grid, Modal, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  setAddOccasionModal,
  setSuccessfullAddModal,
} from "../../store/ThemesReducer";
import uploadImgIcon from "../../assets/svgs/uploadImg.svg";
import "../../App.css";
import HelperModule from "../../helpers/HelperModule";
import ApiServices from "../../helpers/ApiServices";
import { useMediaQueryHook } from "../../hooks/useMediaQueryHook";
import { enqueueSnackbar } from "notistack";
const AddOccasionModal = () => {
  const { addOccasionModal } = useAppSelector((state) => state.Themes);
  const dispatch = useAppDispatch();
  const { sm, md } = useMediaQueryHook();
  const [occasionName, setOccasionName] = useState<string>("");
  const [themes, setThemes] = useState<any>([]);
  const [thumbnail, setThumbnail] = useState<any>('');
  const [messages, setMessages] = useState<string[]>([]);
  const [messageText, setMessageText] = useState<string>("");

  const handleAddThemes = (e: any) => {
    let files = e.target.files;
    setThemes((prev: any) => [...prev, ...files]);
  };
  const handleAddMesages = () => {
    if (messageText) {
      setMessages((prev: any) => [...prev, messageText]);
      setMessageText("");
    }
  };
  const handleSubmit = async () => {
    const token = sessionStorage.getItem("token");
    const formData = new FormData();

    formData.append("occasion_name", occasionName);

    messages.forEach((message) => formData.append("messages[]", message));
    [thumbnail, ...themes].forEach((theme: any) =>
      formData.append("files", theme)
    );

    try {
      const response = await HelperModule().postFormDataMethod(
        ApiServices.base_URL + ApiServices.create_occasion,
        formData,
        { Authorization: token }
      );
      console.log(response, 'response')

      if (response.success) {
        dispatch(setAddOccasionModal(false));
        dispatch(setSuccessfullAddModal(true));
        setThumbnail(null);
        setThemes([]);
      }
      else {
        enqueueSnackbar(response.response.data.message, {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          style: { fontFamily: "Poppins", fontWeight: "500" },
        });
      }
    } catch (error: any) {
      console.log(error);
    }
  };
  return (
    <Modal
      open={addOccasionModal}
      onClose={() => { dispatch(setAddOccasionModal(false)); setThumbnail('') }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 3,
          minWidth: !sm ? 300 : 500,
          maxWidth: 500,
          borderRadius: 2,
        }}
      >
        <Typography fontSize={14}>Add Occasion</Typography>
        <Box mt={1}>
          <Box>
            <Typography fontSize={"10px"}>Occasion Name</Typography>
            <TextField
              //   fullWidth
              // disabled={!isEdit}
              type="text"
              onChange={(e: any) => setOccasionName(e.target.value)}
              placeholder="type here..."
              required
              size="small"
              variant="outlined"
              sx={{
                "& fieldset": { border: "none" },
                border: "1px solid lightgrey",
                borderRadius: "10px",
                paddingY: "4px",
              }}
              inputProps={{
                style: {
                  padding: "3px 10px",
                  fontSize: "14px",
                },
              }}
            />
          </Box>
          <Box mt={2} display={!md ? "block" : "flex"} gap={2}>
            <Box width={!md ? "99%" : "50%"}>
              <Typography fontSize={"12px"}>Thumbnail</Typography>
              <Box position={"relative"} mt={1}>
                <Box
                  sx={{
                    border: "1px dashed grey",
                    borderRadius: 1,
                    height: "50px",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    paddingY: "5px",
                  }}
                >
                  <Box
                    display="flex"
                    justifyContent={"center"}
                    alignItems={"center"}
                    width="100%"
                  >
                    {thumbnail ? <Box sx={{
                      overflow: 'hidden',
                      width: '100%',
                      height: "60px",
                    }}>
                      <img src={window.URL.createObjectURL(thumbnail)} alt="" width="100%" height={'60px'} style={{ objectFit: 'cover' }} />
                    </Box> : <Box textAlign={"center"} width={"100%"}>
                      <img
                        src={uploadImgIcon}
                        alt=""
                        width={"40px"}
                        height={"30px"}
                      />
                      <Typography mt={-1} fontSize={"10px"}>
                        Upload Thumbnail
                      </Typography>
                    </Box>}
                  </Box>
                  <TextField
                    type="file"
                    sx={{
                      position: "absolute",
                      //   height: "100px",
                      opacity: "0",
                    }}
                    onChange={(e: any) => setThumbnail(e.target.files[0])}
                  />
                </Box>
              </Box>
            </Box>
            <Box width={!md ? "100%" : "50%"}>
              <Typography fontSize={"12px"}>Upload Theme</Typography>
              <Box position={"relative"} mt={1}>
                <Box
                  sx={{
                    border: "1px dashed grey",
                    borderRadius: 1,
                    height: "50px",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    paddingY: "5px",
                  }}
                >
                  <Box
                    display="flex"
                    justifyContent={"center"}
                    alignItems={"center"}
                    width="100%"
                  >
                    <Box textAlign={"center"} width={"100%"}>
                      <img
                        src={uploadImgIcon}
                        alt=""
                        width={"40pxs"}
                        height={"30px"}
                      />
                      <Typography fontSize={"10px"} mt={-1}>
                        Upload Theme
                      </Typography>
                    </Box>
                  </Box>
                  <TextField
                    type="file"
                    sx={{
                      position: "absolute",
                      //   height: "100px",
                      opacity: "0",
                    }}
                    inputProps={{ multiple: true }}
                    onChange={handleAddThemes}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box mt={1} width={"100%"}>
            <Typography fontSize={10} mt={1}>
              Total ({themes.length})
            </Typography>
            {themes.length > 0 ? (
              <Box
                display={"flex"}
                width={"100%"}
                gap={1}
                mt={0.5}
                sx={{ overflowX: "scroll" }}
                className="hide-scrollbar"
                whiteSpace={"nowrap"}
              >
                {themes?.map((theme: any) => (
                  <Box
                    width={"90px"}
                    // height={"60px"}
                    // border={1}
                    sx={{
                      //   backgroundImage: `url(${URL.createObjectURL(theme)})`,
                      //   backgroundPosition: "center",
                      //   backgroundSize: "contain",
                      //   backgroundRepeat: "no-repeat",
                      //   marginRight: "2px",
                      flexShrink: 0,
                      //   border:'1px solid'
                    }}
                  >
                    <img
                      src={URL.createObjectURL(theme)}
                      alt=""
                      width={"100%"}
                      height={"50px"}
                      style={{ display: "inline-block" }}
                    />
                  </Box>
                ))}
              </Box>
            ) : (
              <Box
                border={"1px solid lightgrey"}
                textAlign={"center"}
                py={2}
                mt={0.5}
              >
                <Typography color={"lightgrey"}>No Selected Themes</Typography>
              </Box>
            )}
          </Box>
          <Box marginTop={2}>
            <Typography fontSize={"12px"}>Enter your Message</Typography>
            <Box display={"flex"} mt={1} gap={1} alignItems={"center"}>
              <TextField
                fullWidth
                type="text"
                placeholder="type here..."
                required
                size="small"
                variant="outlined"
                value={messageText}
                sx={{
                  "& fieldset": { border: "none" },
                  border: "1px solid lightgrey",
                  borderRadius: "10px",
                  paddingY: "4px",
                }}
                inputProps={{
                  style: {
                    padding: "7px 10px",
                    fontSize: "14px",
                  },
                  maxLength: 200
                }}
                onChange={(e: any) => setMessageText(e.target.value)}
              />
              <Button
                sx={{
                  // marginTop: "10px",
                  bgcolor: "rgba(242, 234, 255, 1)",
                  textTransform: "none",
                  color: "rgb(108,62,185)",
                  border: "none",
                  //   height: "100",
                  padding: "0px",
                  height: "35px",
                  "&:hover": {
                    bgcolor: "rgb(82, 40, 153)", // Maintain the same background color on hover
                    border: "none",
                    color: "white",
                  },
                  "&:focus": {
                    outline: "none", // Remove the focus outline
                  },
                }}
                onClick={handleAddMesages}
              >
                Add
              </Button>
            </Box>
          </Box>
          <Box display={"flex"} gap={1} mt={2} flexWrap={"wrap"}>
            {messages.map((message) => (
              <Typography
                bgcolor={"rgba(248, 248, 248, 1)"}
                paddingX={2}
                paddingY={"3px"}
                fontSize={"13px"}
                borderRadius={3}
              >
                {message}
              </Typography>
            ))}
          </Box>
          <Box marginTop={2} gap={2} display={"flex"}>
            <Button
              sx={{
                bgcolor: "rgba(242, 234, 255, 1)",
                textTransform: "none",
                color: "rgb(108,62,185)",
                border: "none",
                borderRadius: "15px",
                padding: "2px",
                fontSize: "12px",
                "&:hover": {
                  bgcolor: "rgb(82, 40, 153)", // Maintain the same background color on hover
                  border: "none",
                  color: "white",
                },
                "&:focus": {
                  outline: "none", // Remove the focus outline
                },
              }}
              onClick={handleSubmit}
            >
              save
            </Button>
            <Button
              sx={{
                textTransform: "none",
                color: "red",
                border: "none",
                fontSize: "12px",
                padding: "0px",
                "&:hover": {
                  bgcolor: "rgba(242, 234, 255, 1)", // Maintain the same background color on hover
                  border: "none",
                  color: "red",
                },
                "&:focus": {
                  outline: "none", // Remove the focus outline
                },
              }}
              onClick={() => { dispatch(setAddOccasionModal(false)); setThumbnail('') }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal >
  );
};

export default AddOccasionModal;
