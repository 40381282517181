import React from "react";
import { Button } from "@mui/material";

type ButtonProps = {
  md: boolean;
  text: string;
  onClick: () => void;
  otp?: boolean;
};
function ButtonAuth({ md, text, onClick, otp }: ButtonProps) {
  const getWidth = () => {
    if (otp) {
      if (!md) return "100%";
      return "100%";
    }
    if (!md) return "100%";
    return "100%";
  };
  return (
    <Button
      onClick={onClick}
      variant="contained"
      sx={{
        borderRadius: "0.4rem",
        background: "#F2EAFF",
        color: "rgba(108, 62, 185, 1)",
        marginTop: !md ? "1rem" : "2rem",
        textTransform: "none",
        //width: !md ? "20rem" : "30rem",
        boxShadow: "none",
        padding: "12px",
        width: "100%",
        fontSize: "15px",
        "&:hover": {
          backgroundColor: "#c5a1ff",
          color: "white",
        },
      }}
    >
      {text}
    </Button>
  );
}

export default ButtonAuth;
