import { createSlice } from '@reduxjs/toolkit';

interface initialStateProps {
    IsLogin: null | boolean;
    emailChange: null | boolean
}

const initialState: initialStateProps = {
    IsLogin: null,
    emailChange: null,
}

const AuthReducer = createSlice({
    name: 'AuthReducer',
    initialState,
    reducers: {
        setIsLogin: (state, action) => {
            state.IsLogin = action?.payload
        },
        setEmailChange: (state, action) => {
            state.emailChange = action?.payload
        },
    },
});

export const {
    setIsLogin,
    setEmailChange,
} = AuthReducer.actions;
export default AuthReducer.reducer;