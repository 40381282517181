import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ProfileImage from "../../components/ProfileImage";
import HelperModule from "../../helpers/HelperModule";
import ApiServices from "../../helpers/ApiServices";
import { useNavigate } from "react-router-dom";
import { setIsLogin } from "../../store/AuthReducer";
import { useDispatch } from "react-redux";
import {
  setProfileData,
} from "../../store/ProfileReducer";
import ChangePassword from "./ChangePassword";
import { enqueueSnackbar } from "notistack";

const Profile = ({ md }: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState<any>({});
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [changePassword, setChangePassword] = useState<boolean>(false);
  useEffect(() => {
    getProfileDetails();
  }, []);
  const [imagePrev, setImagePrev]: any = useState(null);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleEdit = (e: any) => {
    e.preventDefault();
    setIsEdit(!isEdit);
    let email: any = localStorage.getItem("email");
    if (isEdit) {
      if (email === formData.email) {
        updateProfileDetails();
      } else {
        handleSendOtp();
      }
    }
  };

  const handleChangePassword = () => {
    if (!isEdit) {
      setChangePassword(true);
    } else setIsEdit(!isEdit);
  };

  const handleSendOtp = async () => {
    const token = sessionStorage.getItem("token");
    try {
      const response = await HelperModule().postMethodHeaders(
        ApiServices.base_URL + ApiServices.email_change_otp,
        { email: formData.email },
        { Authorization: token }
      );
      console.log(response, 'handleSend REsponse')
      if (response.message === "Request received, processing email sending.") {
        localStorage.setItem("profile_details", JSON.stringify(formData)); // using in the case of email updation in the two factor authentication

        sessionStorage.removeItem("token");
        dispatch(setIsLogin(null));
        localStorage.setItem("emailChange", "emailChanging");
        // Navigate to the "otp_verification" route before removing the token and updating the login state
        navigate("/otp_verification");

        // Remove token and update login state after navigation
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getProfileDetails = async () => {
    const token = sessionStorage.getItem("token");
    try {
      const response = await HelperModule().getMethodWithHeaders(
        ApiServices.base_URL + ApiServices.profile_details,
        { Authorization: token }
      );
      if (response.message === "Success") {
        localStorage.setItem("email", response?.data?.email);
        setFormData(response?.data);
        dispatch(setProfileData(response.data));
      }
    } catch (error) {
      console.log(error);
    }
  };
  const formValidation = () => {
    const { first_name, last_name, email, phoneNumber } = formData;

    // Check if any of the required fields are empty or phone number is less than 10 digits
    if (
      first_name.trim().length === 0 ||
      last_name.trim().length === 0 ||
      email.trim().length === 0 ||
      phoneNumber.trim().length < 10
    ) {
      return false;
    }

    return true;
  };

  const updateProfileDetails = async () => {
    alert(formData.last_name.length);
    const token = sessionStorage.getItem("token");
    let newFormData = new FormData();
    newFormData.append("first_name", formData.first_name);
    newFormData.append("last_name", formData.last_name);
    newFormData.append("phoneNumber", formData.phoneNumber);
    newFormData.append("email", formData.email);
    if (typeof formData.imageUrl === "object") {
      newFormData.append("file", formData.imageUrl);
    }
    if (formValidation()) {
      try {
        const response = await HelperModule().patchMethodFormData(
          ApiServices.base_URL + ApiServices.update_profile,
          newFormData,
          { Authorization: token }
        );
        // if response success
        if (response.data.id) {
          enqueueSnackbar(response?.message, {
            variant: "success",
            anchorOrigin: { vertical: "top", horizontal: "right" },
            style: { fontFamily: "Poppins", fontWeight: "500" },
          });
          getProfileDetails();
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      enqueueSnackbar("Please fill all fields", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        style: { fontFamily: "Poppins", fontWeight: "500" },
      });
    }
  };

  return (
    <>
      <ChangePassword
        open={changePassword}
        onClose={() => setChangePassword(false)}
      />
      <Box
        width={!md ? "90%" : "60%"}
        bgcolor={"white"}
        paddingX={!md ? 2 : 3}
        paddingY={1}
        minHeight={!md ? '90vh' : 'auto'}
        maxHeight={'100vh'}
        sx={{ borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}
      >
        <Typography fontWeight={"bold"}>Profile</Typography>
        <Box display={!md ? "block" : "flex"} gap={5} padding={1}>
          {/* image */}
          <ProfileImage
            imagePrev={imagePrev}
            handleSetFormDataCallback={setFormData}
            handleImagePrevCallback={setImagePrev}
            image={formData.imageUrl}
            isEdit={isEdit}
            type="profileSettings"
          />
          {/* feilds */}
          <Box width={!md ? "100%" : "80%"} marginTop={!md ? 2 : 0}>
            <form>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Typography fontSize={"12px"}>First Name</Typography>
                  <TextField
                    fullWidth
                    disabled={!isEdit}
                    name="first_name"
                    value={formData.first_name}
                    onChange={handleChange}
                    required
                    size="small"
                    variant="outlined"
                    sx={{
                      "& fieldset": { border: "none" },
                      border: "1px solid lightgrey",
                      borderRadius: "10px",
                      paddingY: "4px",
                    }}
                    inputProps={{
                      style: {
                        padding: "3px 10px",
                        fontSize: "14px",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography fontSize={"12px"}>Last Name</Typography>
                  <TextField
                    fullWidth
                    disabled={!isEdit}
                    name="last_name"
                    value={formData.last_name}
                    onChange={handleChange}
                    required
                    size="small"
                    variant="outlined"
                    sx={{
                      "& fieldset": { border: "none" },
                      border: "1px solid lightgrey",
                      borderRadius: "10px",
                      paddingY: "4px",
                    }}
                    inputProps={{
                      style: {
                        padding: "3px 10px",
                        fontSize: "14px",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography fontSize={"12px"}>Email</Typography>
                  <TextField
                    fullWidth
                    disabled={!isEdit}
                    type="email"
                    name="email"
                    size="small"
                    variant="outlined"
                    sx={{
                      "& fieldset": { border: "none" },
                      border: "1px solid lightgrey",
                      borderRadius: "10px",
                      paddingY: "4px",
                    }}
                    inputProps={{
                      style: {
                        padding: "3px 10px",
                        fontSize: "14px",
                      },
                    }}
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography fontSize={"12px"}>Phone Number</Typography>
                  <TextField
                    fullWidth
                    disabled={!isEdit}
                    type="tel"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    required
                    size="small"
                    variant="outlined"
                    sx={{
                      "& fieldset": { border: "none" },
                      border: "1px solid lightgrey",
                      borderRadius: "10px",
                      paddingY: "4px",
                    }}
                    inputProps={{
                      style: {
                        padding: "3px 10px",
                        fontSize: "14px",
                      },
                      maxLength: 10,
                    }}
                  />
                </Grid>
              </Grid>
              <Box marginTop={3}>
                <Button
                  sx={{
                    textTransform: "none",
                    bgcolor: "rgba(242, 234, 255, 1)",
                    color: "#6C3EB9",
                    marginRight: "10px",
                    borderRadius: "10px",
                    padding: "5px",
                    fontSize: "12px",
                    "&:hover": {
                      bgcolor: "rgba(242, 234, 255, 1)", // Maintain the same background color on hover
                      border: "none",
                    },
                    "&:focus": {
                      outline: "none", // Remove the focus outline
                    },
                  }}
                  onClick={handleEdit}
                >
                  {isEdit ? "Save" : "Edit"}
                </Button>
                <Button
                  sx={{
                    textTransform: "none",
                    color: "#6C3EB9",
                    fontSize: "12px",
                  }}
                  onClick={handleChangePassword}
                >
                  {isEdit ? "Cancel" : "Change Password"}
                </Button>
              </Box>
            </form>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Profile;
