import { createSlice } from '@reduxjs/toolkit';

interface initialStateProps {
    // occasions
    occasionStatusModal: any;
    addOccasionModal: boolean;
    editOccasionModal: boolean;
    successfullEditModal: boolean,
    successfullAddModal: boolean;
    occasionSuccessStatusModal: boolean;
    occasionList: any;
    occasionDetails: any
    deleteOccasionModal: any;
    deleteOccasionSuccessModal: boolean
    // Themes
    deleteThemeOrMessageModal: any;
    updateThemeOrMessageModal: any;
    updateThemeOrMessageModalSuccess: boolean
    successDeleteThemeModal: boolean;
    createThemeModal: boolean;
    createMessageModal: boolean;
    successfullAddedTheme: boolean;
    messageAddedSuccess: boolean;

}

const initialState: initialStateProps = {
    // occasions
    occasionStatusModal: {}, // to store the id of the occasion
    occasionSuccessStatusModal: false,
    addOccasionModal: false,
    editOccasionModal: false,
    successfullEditModal: false,
    successfullAddModal: false,
    occasionList: [],
    occasionDetails: {},
    deleteOccasionModal: {}, // to store the id of the occasion
    deleteOccasionSuccessModal: false,
    // Themes
    deleteThemeOrMessageModal: {},
    updateThemeOrMessageModal: {},
    updateThemeOrMessageModalSuccess: false,
    successDeleteThemeModal: false,
    createThemeModal: false,
    createMessageModal: false,
    successfullAddedTheme: false,
    messageAddedSuccess: false,

}

const Themes = createSlice({
    name: 'Themes',
    initialState,
    reducers: {
        // occasions
        setoccasionStatusModal: (state, action) => {
            state.occasionStatusModal = action?.payload
        },
        setAddOccasionModal: (state, action) => {
            state.addOccasionModal = action?.payload
        },
        setEditOccasionModal: (state, action) => {
            state.editOccasionModal = action?.payload
        },
        setSuccessfullEditModal: (state, action) => {
            state.successfullEditModal = action?.payload
        },
        setSuccessfullAddModal: (state, action) => {
            state.successfullAddModal = action?.payload
        },
        setOccasionSuccessStatusModal: (state, action) => {
            state.occasionSuccessStatusModal = action?.payload
        },
        setOccasionList: (state, action) => {
            state.occasionList = action?.payload
        },
        setDeleteOccasionModal: (state, action) => {
            state.deleteOccasionModal = action?.payload
        },
        setDeleteOccasionSuccessModal: (state, action) => {
            state.deleteOccasionSuccessModal = action?.payload
        },
        setOccationDetails: (state, action) => {
            state.occasionDetails = action?.payload
        },
        // themes
        setDeleteThemeOrMessageModal: (state, action) => {
            state.deleteThemeOrMessageModal = action?.payload
        },
        setDeleteThemeSuccess: (state, action) => {
            state.successDeleteThemeModal = action?.payload
        },
        setUpdateThemeOrMessageModal: (state, action) => {
            state.updateThemeOrMessageModal = action?.payload
        },
        setUpdateThemeOrMessageModalSuccess: (state, action) => {
            state.updateThemeOrMessageModalSuccess = action?.payload
        },

        setCreateThemeModal: (state, action) => {
            state.createThemeModal = action?.payload
        },
        setSuccessAddedThemes: (state, action) => {
            state.successfullAddedTheme = action?.payload;
        },

        setCreateMessageModal: (state, action) => {
            state.createMessageModal = action?.payload
        },
        setMessageAddedSuccess: (state, action) => {
            state.messageAddedSuccess = action?.payload;
        },

    },
});

export const {
    setoccasionStatusModal,
    setAddOccasionModal,
    setEditOccasionModal,
    setSuccessfullEditModal,
    setSuccessfullAddModal,
    setOccasionSuccessStatusModal,
    setOccasionList,
    setOccationDetails,
    setDeleteOccasionModal,
    setDeleteOccasionSuccessModal,
    setDeleteThemeOrMessageModal,
    setUpdateThemeOrMessageModal,
    setUpdateThemeOrMessageModalSuccess,
    setDeleteThemeSuccess,
    setCreateThemeModal,
    setCreateMessageModal,
    setSuccessAddedThemes,
    setMessageAddedSuccess,
} = Themes.actions;
export default Themes.reducer;