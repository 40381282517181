import { createSlice } from '@reduxjs/toolkit';

interface initialStateProps {
    pieGraphStartDate: any;
    pieGraphEndDate: any
    barGraphStartDate: any;
    barGraphEndDate: any;
}

const initialState: initialStateProps = {
    pieGraphStartDate: '',
    pieGraphEndDate: '',
    barGraphStartDate: '',
    barGraphEndDate: ''
}

const DashboardReducer = createSlice({
    name: 'DashboardReducer',
    initialState,
    reducers: {
        setPieGraphStartDate: (state, action) => {
            state.pieGraphStartDate = action?.payload
        },
        setPieGraphEndDate: (state, action) => {
            state.pieGraphEndDate = action?.payload
        },
        setBarGraphStartDate: (state, action) => {
            state.barGraphStartDate = action?.payload
        },
        setBarGraphEndDate: (state, action) => {
            state.barGraphEndDate = action?.payload
        }
    },
});

export const {
    setPieGraphStartDate,
    setPieGraphEndDate,
    setBarGraphStartDate,
    setBarGraphEndDate
} = DashboardReducer.actions;
export default DashboardReducer.reducer;