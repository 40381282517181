import { Box, Button, Grid, Modal, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ProfileImage from "../../components/ProfileImage";
import { useMediaQueryHook } from "../../hooks/useMediaQueryHook";
import HelperModule from "../../helpers/HelperModule";
import ApiServices from "../../helpers/ApiServices";
import {
  setEditSubAdminDetails,
  setSingleSubAdminDetails,
  setSubAdminAddedSuccess,
} from "../../store/Gift4dayReducer";
import { useAppDispatch } from "../../hooks";
import { enqueueSnackbar } from "notistack";

const UserDetailsModal = ({ isOpen, onClose, userDetails }: any) => {
  const { sm, md } = useMediaQueryHook();
  const dispatch = useAppDispatch();
  const [formData, setFormData] = useState<any>({
    first_name: "",
    last_name: "",
    email: "",
    phoneNumber: "",
  });
  const permissions = ["users", "transactions", "web settings", "themes"];
  const [isEdit, setIsEdit] = useState<null | string>(null);
  const [imagePrev, setImagePrev]: any = useState(null);
  const [selectedPermissions, setSelectedPermissions] = useState<any>([]);

  useEffect(() => {
    if (userDetails.isEdit) {
      setFormData(userDetails);
      setIsEdit("Edit");
      setSelectedPermissions([...userDetails.Permissions.split(",")]);
    } else {
      setFormData({
        first_name: "",
        last_name: "",
        email: "",
        phoneNumber: "",
      });
      setSelectedPermissions([]);
      setImagePrev(null);

    }
  }, [userDetails]);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    if (name === "phoneNumber") {
      if (value.length <= 10) {
        setFormData({ ...formData, [name]: value });
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handlePermissionSelection = (ele: string) => {
    if (selectedPermissions.includes(ele)) {
      setSelectedPermissions(
        selectedPermissions.filter((selected: string) => selected !== ele)
      );
    } else {
      setSelectedPermissions([...selectedPermissions, ele]);
    }
  };

  const handleEdit = () => {
    if (isEdit === "Edit") {
      setIsEdit("Save");
    } else {
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    if (formData.id && isEdit === "Save") {
      editUserApi();
    } else {
      addUserApi();
    }
  };
  const mandateFields = () => {
    if (
      formData?.first_name?.length === 0 ||
      formData?.last_name?.length === 0 ||
      formData?.email?.length === 0 ||
      formData?.phoneNumber?.length === 0
    ) {
      return false;
    }
    return true;
  };
  const addUserApi = async () => {
    if (mandateFields()) {
      const newFormData = new FormData();
      newFormData.append("first_name", formData.first_name);
      newFormData.append("last_name", formData.last_name);
      newFormData.append("phoneNumber", formData.phoneNumber);
      newFormData.append("email", formData.email);
      newFormData.append("Permissions", selectedPermissions.join(","));
      if (typeof formData.imageUrl === "object") {
        newFormData.append("file", formData.imageUrl);
      }
      const token = sessionStorage.getItem("token");
      try {
        const response = await HelperModule().postFormDataMethod(
          ApiServices.base_URL + ApiServices.create_subAdmin,
          newFormData,
          { Authorization: token }
        );
        if (response.success) {
          setFormData({});
          dispatch(setSingleSubAdminDetails({ isOpen: false }));
          dispatch(setSubAdminAddedSuccess(true));
        }
        else {
          enqueueSnackbar(response.response.data.message, {
            variant: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
            style: { fontFamily: "Poppins", fontWeight: "500" },
          });
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      enqueueSnackbar("Please fill all Fields", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        style: { fontFamily: "Poppins", fontWeight: "500" },
      });
    }
  };

  const editUserApi = async () => {
    const newFormData = new FormData();
    newFormData.append("id", formData.id);
    newFormData.append("first_name", formData.first_name);
    newFormData.append("last_name", formData.last_name);
    newFormData.append("phoneNumber", formData.phoneNumber);
    newFormData.append("email", formData.email);
    newFormData.append("Permissions", selectedPermissions.join(","));
    if (typeof formData.imageUrl === "object") {
      newFormData.append("file", formData.imageUrl);
    }
    const token = sessionStorage.getItem("token");
    try {
      const response = await HelperModule().patchMethodFormData(
        ApiServices.base_URL + ApiServices.edit_subAdmin,
        newFormData,
        { Authorization: token }
      );
      if (response?.success) {
        dispatch(setSingleSubAdminDetails({ isOpen: false }));
        dispatch(setEditSubAdminDetails({ isOpen: true }));
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Modal
      open={isOpen}
      onClose={() => dispatch(setSingleSubAdminDetails({ isOpen: false }))}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 3,
          minWidth: !sm ? 300 : 500,
          maxWidth: 400,
        }}
      >
        <Typography fontSize={14}>Add User</Typography>
        <Box mt={1}>
          <ProfileImage
            imagePrev={imagePrev}
            handleSetFormDataCallback={setFormData}
            handleImagePrevCallback={setImagePrev}
            image={formData.imageUrl}
          />
          <Box marginTop={3}>
            <form>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Typography fontSize={"12px"}>
                    First Name <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    fullWidth
                    disabled={isEdit === "Edit" ? true : false}
                    name="first_name"
                    value={formData.first_name}
                    onChange={handleChange}
                    required
                    size="small"
                    variant="outlined"
                    sx={{
                      "& fieldset": { border: "none" },
                      border: "1px solid lightgrey",
                      borderRadius: "10px",
                      paddingY: "4px",
                    }}
                    inputProps={{
                      style: {
                        padding: "3px 10px",
                        fontSize: "14px",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography fontSize={"12px"}>
                    Last Name <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    fullWidth
                    disabled={isEdit === "Edit" ? true : false}
                    name="last_name"
                    value={formData.last_name}
                    onChange={handleChange}
                    required
                    size="small"
                    variant="outlined"
                    sx={{
                      "& fieldset": { border: "none" },
                      border: "1px solid lightgrey",
                      borderRadius: "10px",
                      paddingY: "4px",
                    }}
                    inputProps={{
                      style: {
                        padding: "3px 10px",
                        fontSize: "14px",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography fontSize={"12px"}>
                    Email <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    fullWidth
                    disabled={isEdit === "Edit" ? true : false}
                    type="email"
                    name="email"
                    size="small"
                    variant="outlined"
                    sx={{
                      "& fieldset": { border: "none" },
                      border: "1px solid lightgrey",
                      borderRadius: "10px",
                      paddingY: "4px",
                    }}
                    inputProps={{
                      style: {
                        padding: "3px 10px",
                        fontSize: "14px",
                      },
                    }}
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography fontSize={"12px"}>
                    Phone Number <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    fullWidth
                    disabled={isEdit === "Edit" ? true : false}
                    type="number"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    required
                    size="small"
                    variant="outlined"
                    sx={{
                      "& fieldset": { border: "none" },
                      border: "1px solid lightgrey",
                      borderRadius: "10px",
                      paddingY: "4px",
                    }}
                    inputProps={{
                      style: {
                        padding: "3px 10px",
                        fontSize: "14px",
                      },
                      maxLength: 10,
                    }}
                  />
                </Grid>
              </Grid>
            </form>
          </Box>
          <Box mt={2}>
            <Typography fontSize={14}>Permissions</Typography>
            <Box display="flex" flexWrap={"wrap"} gap={2} mt={1}>
              {permissions?.map((ele: any) => (
                <Box
                  py={"5px"}
                  px={"12px"}
                  fontSize={12}
                  bgcolor={
                    selectedPermissions?.includes(ele.toLowerCase()) ||
                      formData?.Permissions?.includes(ele.toLowerCase())
                      ? "rgba(242, 234, 255, 1)"
                      : "rgba(248, 248, 248, 1)"
                  }
                  color={
                    selectedPermissions?.includes(ele.toLowerCase()) ||
                      formData?.Permissions?.includes(ele.toLowerCase())
                      ? "rgba(108, 62, 185, 1)"
                      : "rgba(115, 115, 115, 1)"
                  }
                  sx={{ cursor: "pointer" }}
                  borderRadius={1}
                  onClick={() => handlePermissionSelection(ele)}
                >
                  {ele}
                </Box>
              ))}
            </Box>
            <Box marginTop={3}>
              <Button
                sx={{
                  textTransform: "none",
                  bgcolor: "rgba(242, 234, 255, 1)",
                  color: "#6C3EB9",
                  marginRight: "10px",
                  borderRadius: "10px",
                  padding: "5px",
                  fontSize: "12px",
                  "&:hover": {
                    bgcolor: "rgba(242, 234, 255, 1)", // Maintain the same background color on hover
                    border: "none",
                  },
                  "&:focus": {
                    outline: "none", // Remove the focus outline
                  },
                }}
                onClick={handleEdit}
              >
                {userDetails.Role === "SubAdmin" ? isEdit : "Add"}
              </Button>
              <Button
                sx={{
                  textTransform: "none",
                  color: "red",
                  fontSize: "12px",
                }}
                onClick={() => {
                  setImagePrev(null);
                  dispatch(setSingleSubAdminDetails({ isOpen: false }));
                }}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default UserDetailsModal;
