import { Box, Button, Typography } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useMediaQueryHook } from "../../hooks/useMediaQueryHook";
import addIcon from "../../assets/svgs/plus.svg";
import HelperModule from "../../helpers/HelperModule";
import ApiServices from "../../helpers/ApiServices";
import { useNavigate, useParams } from "react-router-dom";
import ThemesList from "./ThemesList";
import { useAppDispatch, useAppSelector } from "../../hooks";
import CustomConfirmModal from "../../components/CustomConfirmModal";
import MessagesList from "./MessagesList";
import OccasionCard from "./OccasionCard";
import {
  setCreateMessageModal,
  setCreateThemeModal,
  setDeleteOccasionModal,
  setDeleteOccasionSuccessModal,
  setDeleteThemeOrMessageModal,
  setDeleteThemeSuccess,
  setMessageAddedSuccess,
  setoccasionStatusModal,
  setOccasionSuccessStatusModal,
  setOccationDetails,
  setSuccessAddedThemes,
  setUpdateThemeOrMessageModal,
  setUpdateThemeOrMessageModalSuccess,
} from "../../store/ThemesReducer";
import AddThemeModal from "./AddThemeModal";
import AddMessageModal from "./AddMessageModal";
import CustomSuccesfulModal from "../../components/CustomSuccesfulModal";

const OccationDetails = () => {
  const { md } = useMediaQueryHook();
  const {
    deleteThemeOrMessageModal,
    successfullAddedTheme,
    occasionStatusModal,
    occasionSuccessStatusModal,
    deleteOccasionModal,
    deleteOccasionSuccessModal,
    messageAddedSuccess,
    successDeleteThemeModal,
    updateThemeOrMessageModal,
    updateThemeOrMessageModalSuccess,
    editOccasionModal
  } = useAppSelector((state) => state.Themes);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [buttonStatus, setButtonStatus] = useState("Themes");
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      getOccationDetails();
    }
  }, [successfullAddedTheme, messageAddedSuccess, editOccasionModal]);

  const updateOccasionStatus = async () => {
    let token = sessionStorage.getItem("token");
    const payload = {
      id: occasionStatusModal.id,
      status: occasionStatusModal.status === "Active" ? "Inactive" : "Active",
    };

    try {
      let response = await HelperModule().patchMethodHeaders(
        ApiServices.base_URL + ApiServices.update_occasion_status,
        payload,
        { Authorization: token }
      );
      if (response.success) {
        dispatch(setoccasionStatusModal({ isOpen: false, ...response.data }));
        dispatch(setOccasionSuccessStatusModal(true));
        getOccationDetails();
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const updateThemeOrMessageStatus = async () => {
    let token = sessionStorage.getItem("token");
    const payload = {
      id: updateThemeOrMessageModal.id,
      status:
        updateThemeOrMessageModal.status === "Active" ? "Inactive" : "Active",
      key: updateThemeOrMessageModal.type,
    };

    try {
      let response = await HelperModule().patchMethodHeaders(
        ApiServices.base_URL + ApiServices.update_theme_and_message,
        payload,
        { Authorization: token }
      );
      if (response.success) {
        dispatch(
          setUpdateThemeOrMessageModal({ isOpen: false, ...response.data })
        );
        dispatch(setUpdateThemeOrMessageModalSuccess(true));
        getOccationDetails();
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const deleteOccasion = async (id: any) => {
    let token = sessionStorage.getItem("token");
    try {
      let response = await HelperModule().deleteMethod(
        ApiServices.base_URL + `occasion?id=${id}`,
        { Authorization: token }
      );
      console.log("response: ", response);
      if (response.success) {
        dispatch(setDeleteOccasionModal({ isOpen: false }));
        dispatch(setDeleteOccasionSuccessModal(true));
        navigate("/themes");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddThemeOrMessageModal = () => {
    if (buttonStatus === "Themes") {
      dispatch(setCreateThemeModal(true));
    } else {
      dispatch(setCreateMessageModal(true));
    }
  };

  const getOccationDetails = async () => {
    let token = sessionStorage.getItem("token");
    try {
      let response = await HelperModule().getMethodWithHeaders(
        ApiServices.base_URL + `/occasion_details?id=${id}`,
        { Authorization: token }
      );
      if (response.success) {
        dispatch(setOccationDetails(response.data));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteThemeOrMessage = async () => {
    let token = sessionStorage.getItem("token");
    try {
      let response = await HelperModule().deleteMethod(
        ApiServices.base_URL +
        `/deleteThemeMessage?id=${deleteThemeOrMessageModal.id}&key=${deleteThemeOrMessageModal.type}`,
        { Authorization: token }
      );
      if (response.success) {
        dispatch(setDeleteThemeOrMessageModal({ isOpen: false }));
        dispatch(setDeleteThemeSuccess(true));
        getOccationDetails();
      }
    } catch (error) {
      console.log(error);
    }
  };
  console.log(updateThemeOrMessageModal, "updateThemeOrMessageModal");
  return (
    <Fragment>
      {/* for update the status of the occasion */}
      <CustomConfirmModal
        open={occasionStatusModal.isOpen}
        onClose={() => dispatch(setoccasionStatusModal(false))}
        title="Confirm"
        content={`Are you sure ${occasionStatusModal.status === "Active" ? "inactive" : "active"
          } this User?`}
        confirmText="Yes"
        cancelText="No"
        onConfirm={updateOccasionStatus} // trigger change status of the user API
      />

      {/* update status Success modal */}
      <CustomSuccesfulModal
        isOpen={occasionSuccessStatusModal}
        onClose={() => dispatch(setOccasionSuccessStatusModal(false))}
        title="Successful"
        content={`The Occasion has been ${occasionStatusModal.status}`}
      />

      {/* for delete the occasion */}
      <CustomConfirmModal
        open={deleteOccasionModal.isOpen}
        onClose={() => dispatch(setDeleteOccasionModal({ isOpen: false }))}
        title="Delete"
        content="Are you sure you want to delete?"
        confirmText="Yes"
        cancelText="No"
        onConfirm={() => deleteOccasion(deleteOccasionModal.id)}
      />

      {/* Delete Occasion success modal */}
      <CustomSuccesfulModal
        isOpen={deleteOccasionSuccessModal}
        onClose={() => dispatch(setDeleteOccasionSuccessModal(false))}
        title="Successful"
        content={`The Occasion has been deleted`}
      />
      <AddThemeModal />

      {/* theme added successfully */}
      <CustomSuccesfulModal
        isOpen={successfullAddedTheme}
        onClose={() => dispatch(setSuccessAddedThemes(false))}
        title="Successful"
        content={"The Theme has added successfully"}
      />
      {/* Theme and message delete modal */}
      <CustomConfirmModal
        open={deleteThemeOrMessageModal.isOpen}
        onClose={() =>
          dispatch(setDeleteThemeOrMessageModal({ isOpen: false }))
        }
        title="Delete"
        content={`Are you sure you want Delete the ${deleteThemeOrMessageModal.type}.`}
        confirmText="Yes"
        cancelText="No"
        onConfirm={deleteThemeOrMessage}
      />
      {/* theme and message delete success Modal */}
      <CustomSuccesfulModal
        isOpen={successDeleteThemeModal}
        onClose={() => dispatch(setDeleteThemeSuccess(false))}
        title="Delete"
        content={`The ${deleteThemeOrMessageModal.type} has Deleted successfully`}
      />

      {/* for update the status of the theme and message */}
      <CustomConfirmModal
        open={updateThemeOrMessageModal.isOpen}
        onClose={() =>
          dispatch(setUpdateThemeOrMessageModal({ isOpen: false }))
        }
        title="Confirm"
        content={`Are you sure ${updateThemeOrMessageModal.status === "Active" ? "inactive" : "active"
          } this ${updateThemeOrMessageModal.type}?`}
        confirmText="Yes"
        cancelText="No"
        onConfirm={updateThemeOrMessageStatus} // trigger change status of the user API
      />
      {/* update status Success modal */}
      <CustomSuccesfulModal
        isOpen={updateThemeOrMessageModalSuccess}
        onClose={() => dispatch(setUpdateThemeOrMessageModalSuccess(false))}
        title="Successful"
        content={`The ${updateThemeOrMessageModal.type} has been ${updateThemeOrMessageModal.status}`}
      />

      <AddMessageModal />

      <CustomSuccesfulModal
        isOpen={messageAddedSuccess}
        onClose={() => dispatch(setMessageAddedSuccess(false))}
        title="Successful"
        content={`The ${updateThemeOrMessageModal.type} has Deleted successfully`}
      />


      <Box
        marginTop={11}
        minHeight={"85vh"}
        padding={!md ? 1 : 0}
        overflow={"scrollable"}
      >
        <Box display={"flex"} alignItems={"center"} gap={1}>
          <Box
            style={{
              background: "white",
              borderRadius: "50%",
              height: "25px",
              width: "20px",
              textAlign: "center",
              cursor: "pointer",
            }}
            onClick={() => navigate(-1)}
          >
            <Typography>&lt;</Typography>
          </Box>
          <Typography fontSize={"14px"}>Back</Typography>
        </Box>
        <Box mt={2} mb={1}>
          <OccasionCard />
        </Box>
        <Box display="flex" justifyContent={"space-between"}>
          <Box display="flex" gap={2} marginY={1}>
            <Button
              sx={{
                fontSize: "12px",
                background: buttonStatus === "Themes" ? "#f2eaff" : "white",
                border:
                  buttonStatus === "Themes" ? "white" : "1px solid #6c3eb9",
                color: "#6c3eb9",
                textTransform: "none",
              }}
              onClick={() => setButtonStatus("Themes")}
            >
              Themes
            </Button>
            <Button
              sx={{
                fontSize: "12px",
                background: buttonStatus === "Messages" ? "#f2eaff" : "white",
                border:
                  buttonStatus === "Messages" ? "white" : "1px solid #6c3eb9",
                textTransform: "none",
                color: "#6c3eb9",
              }}
              onClick={() => setButtonStatus("Messages")}
            >
              Messages
            </Button>
          </Box>
          <Box>
            <Button
              variant="outlined"
              sx={{
                marginTop: "10px",
                bgcolor: "rgb(108,62,185)",
                textTransform: "none",
                color: "#fff",
                border: "none",
                "&:hover": {
                  bgcolor: "rgb(82, 40, 153)", // Maintain the same background color on hover
                  border: "none",
                },
                "&:focus": {
                  outline: "none", // Remove the focus outline
                },
              }}
              onClick={handleAddThemeOrMessageModal}
              startIcon={<img src={addIcon} alt="add" width={20} />}
            >
              {buttonStatus === "Themes" ? "Add Themes" : "Add Messages"}
            </Button>
          </Box>
        </Box>
        {/* list of Themes  */}
        {buttonStatus === "Themes" && <ThemesList />}
        {buttonStatus === "Messages" && <MessagesList />}
      </Box>
    </Fragment>
  );
};

export default OccationDetails;
