import { createSlice } from "@reduxjs/toolkit";

interface initialStateProps {
  sectionData: any;
}

const initialState: initialStateProps = {
  sectionData: [],
};

const WebSettings = createSlice({
  name: "WebSettings",
  initialState,
  reducers: {
    setSectionData: (state, action) => {
      state.sectionData = action?.payload;
    },
  },
});

export const { setSectionData } = WebSettings.actions;
export default WebSettings.reducer;
