import { Box, Dialog, DialogContent, Modal, Typography } from "@mui/material";
import React from "react";
import { useMediaQueryHook } from "../hooks/useMediaQueryHook";

const CustomSuccesfulModal = ({ isOpen, onClose, title, content }: any) => {
  const { md } = useMediaQueryHook();
  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 3,
          minWidth: !md ? 300 : 200,
          maxWidth: 600,
          borderRadius: 2,
        }}
      >
        <Box padding={2}>
          <Box padding={1}>
            <Typography
              style={{
                textAlign: "center",
                fontSize: "22px",
                fontWeight: "bold",
                color: title === "Successful" ? "#6C3EB9" : "#FF7676",
              }}
            >
              {title}
            </Typography>
            <Typography padding={1.5}>{content}</Typography>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default CustomSuccesfulModal;
