import { Box, Typography } from "@mui/material";
import React, { Fragment } from "react";
import deleteIcon from "../../assets/svgs/trash.svg";
import { useAppDispatch } from "../../hooks";
import {
  setDeleteThemeOrMessageModal,
  setUpdateThemeOrMessageModal,
} from "../../store/ThemesReducer";
const ThemeCard = ({ cardDetails }: any) => {
  const dispatch = useAppDispatch();
  return (
    <Fragment>
      <Box padding={1.5} bgcolor={"white"} borderRadius={2}>
        <Box height={"120px"} mb={1}>
          <img
            src={cardDetails.theme_url}
            alt=""
            width={"100%"}
            height={"100%"}
            style={{ borderRadius: "10px" }}
          />
        </Box>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Box>
            <Typography fontSize={"12px"} color={"#6C3EB9"}>
              {cardDetails.theme_name}
            </Typography>
            <Box
              width="fit-content"
              fontSize={"11px"}
              color={
                cardDetails.status === "Active"
                  ? "rgba(0, 171, 17, 1)"
                  : "rgba(255, 74, 74, 1)"
              }
              style={{ cursor: "pointer" }}
              onClick={() =>
                dispatch(
                  setUpdateThemeOrMessageModal({
                    isOpen: true,
                    type: "Themes",
                    id: cardDetails.id,
                    status: cardDetails.status,
                  })
                )
              }
            >
              {cardDetails.status}
            </Box>
          </Box>
          <Box
            style={{ cursor: "pointer" }}
            onClick={() =>
              dispatch(
                setDeleteThemeOrMessageModal({
                  isOpen: true,
                  id: cardDetails.id,
                  type: "Themes",
                })
              )
            }
          >
            <img src={deleteIcon} alt="trash" width={"20px"} />
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

export default ThemeCard;
