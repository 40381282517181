import { Box, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useMediaQueryHook } from "../../hooks/useMediaQueryHook";
import HelperModule from "../../helpers/HelperModule";
import ApiServices from "../../helpers/ApiServices";

const Footer = ({
  handleChangeCallback,
  data,
  handleAddFilesCallback,
}: any) => {
  const { md } = useMediaQueryHook();

  const handleChange = (index: any, key: any, value: any) => {
    handleChangeCallback((prevData: any) => {
      const newData: any = [...prevData];
      newData[index][key] = value;
      return newData;
    });
  };
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const token = await sessionStorage.getItem("token");
    try {
      const response = await HelperModule().getMethodWithHeaders(
        ApiServices.base_URL +
        ApiServices.section_details +
        "?search_param=footer",
        { Authorization: token }
      );
      if (response.success) {
        handleChangeCallback(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Box
      width={!md ? "89%" : "70%"}
      maxHeight={"64vh"}
      overflow={"scroll"}
      paddingX={!md ? 1 : 2}
      mx={"auto"}
      marginLeft={!md ? 2 : "auto"}
      sx={{
        "&::-webkit-scrollbar": {
          width: "0px", // Remove scrollbar width
          background: "transparent", // Optional: Hide scrollbar track
        },
      }}
    >
      {data?.map((ele: any, ind: any) => (
        <Box key={ind}>
          <Box marginTop={1}>
            <Typography fontSize={"14px"}>Privicy Policy</Typography>
            <TextField
              fullWidth
              onChange={(e) =>
                handleChange(ind, "PrivacyPolicy", e.target.value)
              }
              value={ele?.PrivacyPolicy}
              name="PrivacyPolicy"
              size="small"
              variant="outlined"
              sx={{
                "& fieldset": { border: "none" },
                border: "1px solid grey",
              }}
              inputProps={{
                style: {
                  padding: "3px 10px",
                  fontSize: "14px",
                },
              }}
            />
          </Box>
          <Box marginTop={1.5}>
            <Typography fontSize={"14px"}>Terms & Conditions</Typography>
            <TextField
              name="TermsAndConditions"
              fullWidth
              onChange={(e) =>
                handleChange(ind, "TermsAndConditions", e.target.value)
              }
              value={ele?.TermsAndConditions}
              size="small"
              variant="outlined"
              sx={{
                "& fieldset": { border: "none" },
                border: "1px solid grey",
              }}
              inputProps={{
                style: {
                  padding: "3px 10px",
                  fontSize: "14px",
                },
              }}
            />
          </Box>
          <Box marginTop={1.5}>
            <Typography fontSize={"14px"}>Refund Policy</Typography>
            <TextField
              name="RefundPolicy"
              fullWidth
              onChange={(e) =>
                handleChange(ind, "RefundPolicy", e.target.value)
              }
              value={ele?.RefundPolicy}
              size="small"
              variant="outlined"
              sx={{
                "& fieldset": { border: "none" },
                border: "1px solid grey",
              }}
              inputProps={{
                style: {
                  padding: "3px 10px",
                  fontSize: "14px",
                },
              }}
            />
          </Box>
          <Box marginTop={1.5}>
            <Typography fontSize={"14px"}>Cookie Policy</Typography>
            <TextField
              name="CookiePolicy"
              fullWidth
              onChange={(e) =>
                handleChange(ind, "CookiePolicy", e.target.value)
              }
              value={ele?.CookiePolicy}
              size="small"
              variant="outlined"
              sx={{
                "& fieldset": { border: "none" },
                border: "1px solid grey",
              }}
              inputProps={{
                style: {
                  padding: "3px 10px",
                  fontSize: "14px",
                },
              }}
            />
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default Footer;
