import { Box, Typography } from "@mui/material";
import React, { Fragment } from "react";
import deleteIcon from "../../assets/svgs/trash.svg";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  setDeleteThemeOrMessageModal,
  setUpdateThemeOrMessageModal,
} from "../../store/ThemesReducer";
import { useMediaQueryHook } from "../../hooks/useMediaQueryHook";
const MessagesList = () => {
  const { md } = useMediaQueryHook();
  const dispatch = useAppDispatch();
  const { occasionDetails } = useAppSelector((state) => state.Themes);
  return (
    <Fragment>
      <Typography padding={1}>Messages ({occasionDetails.Messages?.length})</Typography>
      <Box
        bgcolor={"white"}
        borderRadius={2}
        width={!md ? "100%" : "50%"}
        padding={!md ? 0 : 2}
        display={"flex"}
        flexDirection={"column"}
        gap={2}
      >
        {occasionDetails?.Messages?.map((message: any, index: number) => (
          <Box
            key={message.id}
            bgcolor={"rgba(248, 248, 248, 1)"}
            display="flex"
            justifyContent={"space-between"}
            alignItems={"center"}
            p={1.5}
            borderRadius={2}
          >
            <Typography fontSize={"13px"}>{message.text_message}</Typography>
            <Box display={"flex"} gap={2}>
              <Box
                display={"flex"}
                alignItems={"center"}
                px={"15px"}
                py={"1px"}
                fontSize={"12px"}
                borderRadius={3}
                bgcolor={
                  message.status === "Active"
                    ? "rgba(230, 255, 233, 1)"
                    : "rgba(255, 74, 74, 0.08)"
                }
                color={
                  message.status === "Active"
                    ? "rgba(0, 171, 17, 1)"
                    : "rgba(255, 74, 74, 1)"
                }
                style={{ cursor: "pointer" }}
                onClick={() =>
                  dispatch(
                    setUpdateThemeOrMessageModal({
                      isOpen: true,
                      id: message.id,
                      type: "Message",
                      status: message.status,
                    })
                  )
                }
              >
                {message.status}
              </Box>
              <Box
                bgcolor={"rgba(255, 74, 74, 0.08)"}
                display={"flex"}
                alignItems={"center"}
                p={"2px"}
                borderRadius={1}
                style={{ cursor: "pointer" }}
                onClick={() =>
                  dispatch(
                    setDeleteThemeOrMessageModal({
                      isOpen: true,
                      id: message.id,
                      type: "Message",
                    })
                  )
                }
              >
                <img src={deleteIcon} alt="trash" width={15} />
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    </Fragment>
  );
};

export default MessagesList;
