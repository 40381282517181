import React, { Fragment, useEffect, useState } from "react";
import { Box, Popover, Typography } from "@mui/material";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import {
  setPieGraphEndDate,
  setPieGraphStartDate,
} from "../store/DashboardReducer";
import { useAppDispatch, useAppSelector } from "../hooks";

function CustomDateRangePicker() {
  const [onRange, setRange] = useState<null | HTMLElement>(null);
  const dispatch = useAppDispatch();
  const { pieGraphStartDate, pieGraphEndDate } = useAppSelector(
    (state) => state.Dashboard
  );

  const handleRange = (event: any) => {
    setRange(event.currentTarget);
  };

  const formatDate = (date: any) => {
    return date ? new Intl.DateTimeFormat("en-US").format(date) : "";
  };

  return (
    <Fragment>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          padding: "3px 10px",
        }}
      >
        <Box
          className="OverviewMenu"
          onClick={(event: any) => handleRange(event)}
          display="flex"
          alignItems={"center"}
          justifyContent={"space-between"}
          width={"90%"}
          style={{ cursor: "pointer" }}
        >
          <Typography
            variant="subtitle2"
            sx={{ fontWeight: 500, lineHeight: "16px", color: "#a3a3a3" }}
          >
            {pieGraphStartDate && pieGraphEndDate
              ? `${formatDate(pieGraphStartDate)} - ${formatDate(
                  pieGraphEndDate
                )}`
              : "Select Date"}
          </Typography>
          <KeyboardArrowDownRoundedIcon
            sx={{ width: "20px", height: "20px", color: "#a3a3a3" }}
          />
        </Box>
      </div>

      <Popover
        open={Boolean(onRange)}
        anchorEl={onRange}
        onClose={() => setRange(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Box sx={{ p: 0.8, width: "100%" }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar
              onChange={(date: any) => {
                if (
                  !pieGraphStartDate ||
                  (pieGraphStartDate && pieGraphEndDate)
                ) {
                  // If no start date selected or both start and end dates are selected, set the new start date
                  dispatch(setPieGraphStartDate(date));
                  dispatch(setPieGraphEndDate(null)); // You may want to uncomment this line if you want to reset the end date as well
                } else if (date.isAfter(pieGraphStartDate)) {
                  // If only start date is selected and the selected date is after the start date, set end date
                  dispatch(setPieGraphEndDate(date));
                  setRange(null);
                } else {
                  // If only start date is selected and selected date is before or equal to start date, update start date
                  dispatch(setPieGraphStartDate(date));
                  dispatch(setPieGraphEndDate(null)); // You may want to uncomment this line if you want to reset the end date as well
                }
              }}
              disableFuture={true}
              showDaysOutsideCurrentMonth={true}
              renderLoading={() => (
                <span data-mui-test="loading-progress">...</span>
              )}
            />
          </LocalizationProvider>
        </Box>
      </Popover>
    </Fragment>
  );
}

export default CustomDateRangePicker;
