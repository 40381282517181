import { Box, Button, Grid, Modal, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  setEditOccasionModal,
  setSuccessfullEditModal,
} from "../../store/ThemesReducer";
import uploadImgIcon from "../../assets/svgs/uploadImg.svg";
import "../../App.css";
import HelperModule from "../../helpers/HelperModule";
import ApiServices from "../../helpers/ApiServices";
import { useMediaQueryHook } from "../../hooks/useMediaQueryHook";
import { enqueueSnackbar } from "notistack";
import { useParams } from "react-router-dom";
const EditOccasionModal = () => {
  const { id } = useParams()
  const { occasionDetails } = useAppSelector((state) => state.Themes);
  const { editOccasionModal } = useAppSelector((state) => state.Themes);
  const dispatch = useAppDispatch();
  const { sm, md } = useMediaQueryHook();
  const [occasionName, setOccasionName] = useState<string>("");
  const [thumbnail, setThumbnail] = useState<any>('');



  useEffect(() => {
    setThumbnail(occasionDetails?.Occasion_detail?.thumbnail_url);
    setOccasionName(occasionDetails?.Occasion_detail?.name)
  }, [editOccasionModal])
  const handleSubmit = async () => {
    const token = sessionStorage.getItem("token");
    let idString:any = id?.toString()
    const formData = new FormData();

    formData.append('id', idString);
    formData.append("occasion_name", occasionName);
    formData.append('file', thumbnail);
    formData.append('status', occasionDetails?.Occasion_detail?.status)
   

    try {
      const response = await HelperModule().patchMethodFormData(
        ApiServices.base_URL + ApiServices.update_occasion_status,
        formData,
        { Authorization: token }
      );
      console.log(response, 'response')

      if (response.success) {
        dispatch(setEditOccasionModal(false));
        dispatch(setSuccessfullEditModal(true));
        setThumbnail(null);
      }
      else {
        enqueueSnackbar(response.response.data.message, {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          style: { fontFamily: "Poppins", fontWeight: "500" },
        });
      }
    } catch (error: any) {
      console.log(error);
    }
  };
  return (
    <Modal
      open={editOccasionModal}
      onClose={() => { dispatch(setEditOccasionModal(false)); setThumbnail('') }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 3,
          minWidth: !sm ? 300 : 500,
          maxWidth: 500,
          borderRadius: 2,
        }}
      >
        <Typography fontSize={14}>Edit Occasion</Typography>
        <Box mt={1}>
          <Box>
            <Typography fontSize={"10px"}>Occasion Name</Typography>
            <TextField
              //   fullWidth
              // disabled={!isEdit}
              value={occasionName}
              type="text"
              onChange={(e: any) => setOccasionName(e.target.value)}
              placeholder="type here..."
              required
              size="small"
              variant="outlined"
              sx={{
                "& fieldset": { border: "none" },
                border: "1px solid lightgrey",
                borderRadius: "10px",
                paddingY: "4px",
              }}
              inputProps={{
                style: {
                  padding: "3px 10px",
                  fontSize: "14px",
                },
              }}
            />
          </Box>
          <Box mt={2} display={!md ? "block" : "flex"} gap={2}>
            <Box width={!md ? "99%" : "50%"}>
              <Typography fontSize={"12px"}>Thumbnail</Typography>
              <Box position={"relative"} mt={1}>
                <Box
                  sx={{
                    border: "1px dashed grey",
                    borderRadius: 1,
                    height: "50px",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    paddingY: "5px",
                  }}
                >
                  <Box
                    display="flex"
                    justifyContent={"center"}
                    alignItems={"center"}
                    width="100%"
                  >
                    {thumbnail ? <Box sx={{
                      overflow: 'hidden',
                      width: '100%',
                      height: "60px",
                    }}>
                      <img src={typeof thumbnail === 'object' ? window.URL.createObjectURL(thumbnail) : thumbnail} alt="" width="100%" height={'60px'} style={{ objectFit: 'cover' }} />
                    </Box> : <Box textAlign={"center"} width={"100%"}>
                      <img
                        src={uploadImgIcon}
                        alt=""
                        width={"40px"}
                        height={"30px"}
                      />
                      <Typography mt={-1} fontSize={"10px"}>
                        Upload Thumbnail
                      </Typography>
                    </Box>}
                  </Box>
                  <TextField
                    type="file"
                    sx={{
                      position: "absolute",
                      //   height: "100px",
                      opacity: "0",
                    }}
                    onChange={(e: any) => setThumbnail(e.target.files[0])}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box marginTop={2} gap={2} display={"flex"}>
            <Button
              sx={{
                bgcolor: "rgba(242, 234, 255, 1)",
                textTransform: "none",
                color: "rgb(108,62,185)",
                border: "none",
                borderRadius: "15px",
                padding: "2px",
                fontSize: "12px",
                "&:hover": {
                  bgcolor: "rgb(82, 40, 153)",
                  border: "none",
                  color: "white",
                },
                "&:focus": {
                  outline: "none",
                },
              }}
              onClick={handleSubmit}
            >
              save
            </Button>
            <Button
              sx={{
                textTransform: "none",
                color: "red",
                border: "none",
                fontSize: "12px",
                padding: "0px",
                "&:hover": {
                  bgcolor: "rgba(242, 234, 255, 1)",
                  border: "none",
                  color: "red",
                },
                "&:focus": {
                  outline: "none",
                },
              }}
              onClick={() => { dispatch(setEditOccasionModal(false)); setThumbnail('') }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal >
  );
};

export default EditOccasionModal;
