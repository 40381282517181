import { Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

import "./customTable.css";
import moment from "moment";
interface TableColumn {
  id: string;
  label: string;
  sortable?: boolean;
}

interface DynamicTableProps {
  columns: TableColumn[];
  data: any;
  title?: string;
  callback?: any;
  // viewCallback?: any;
}
const EventsTable: React.FC<DynamicTableProps> = ({
  columns,
  data,
  title,
  callback,
  // viewCallback,
}) => {
  const navigate = useNavigate();
  return (
    <table>
      <thead>
        <tr style={{ background: "white" }}>
          {columns.map((column) => (
            <th key={column.id} style={{ textAlign: "center",whiteSpace:'nowrap' }}>
              <Typography fontSize={14}>{column.label}</Typography>
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="tableBody">
        {data.map((row: any, rowIndex: any) => (
          <tr key={rowIndex}>
            {columns.map((column) => (
              <td
                key={column.id}
                style={{
                  textAlign: "center",
                  whiteSpace:'nowrap'
                }}
              >
                {title === "Events" && column.id === "view" ? (
                  <Typography
                    bgcolor="#6C3EB9"
                    textAlign={"center"}
                    fontSize={12}
                    padding="4px"
                    borderRadius={3}
                    color="white"
                    onClick={() => callback(`/event/${row.id}`)} // need to pass the id of the event to navigate the event details page
                    style={{ cursor: "pointer" }}
                  >
                    View
                  </Typography>
                ) : title === "Events" && column.id === "eventName" ? (
                  <div
                    onClick={() => callback(`/event/${row.id}`)}
                    style={{
                      textDecoration: "none",
                      color: "rgba(108, 62, 185, 1)",
                    }}
                  >
                    <Typography fontSize={13}>{row[column.id]}</Typography>
                  </div>
                ) : title === "Events" && column.id === "eventDateTime" ? (
                  <Typography fontSize={13}>
                    {moment(row[column.id]).format("DDMMM, hhA").toUpperCase()}
                  </Typography>
                ) : (
                  <Typography fontSize={13}>{row[column.id]}</Typography>
                )}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default EventsTable;
