import { Box, Typography } from "@mui/material";
import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  setDeleteOccasionModal,
  setoccasionStatusModal,
} from "../../store/ThemesReducer";
import deleteIcon from "../../assets/svgs/trash.svg";

interface TableColumn {
  id: string;
  label: string;
  sortable?: boolean;
}
interface DynamicTableProps {
  columns: TableColumn[];
  title?: string;
}
const OccasionTable: React.FC<DynamicTableProps> = ({
  columns,
  title,
}: any) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { occasionList } = useAppSelector((state) => state.Themes);

  return (
    <Fragment>
      {occasionList.length > 0 ? (
        <table>
          <thead>
            <tr style={{ background: "white" }}>
              {columns.map((column: any) => (
                <th key={column.id} style={{ textAlign: "center" }}>
                  <Typography fontSize={14}>{column.label}</Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="tableBody">
            {occasionList?.map((row: any, rowIndex: any) => (
              <tr key={rowIndex}>
                {columns?.map((column: any) => (
                  <td
                    key={column.id}
                    style={{
                      textAlign: "center",
                    }}
                  >
                    {title === "occasions" && column.id === "status" ? (
                      <Fragment>
                        <Box
                          bgcolor={
                            row[column.id] === "Active"
                              ? "rgba(230, 255, 233, 1)"
                              : "rgba(255, 74, 74, 0.08)"
                          }
                          color={
                            row[column.id] === "Active"
                              ? "rgba(0, 171, 17, 1)"
                              : "rgba(255, 74, 74, 1)"
                          }
                          textAlign={"center"}
                          fontSize={12}
                          paddingY="2px"
                          borderRadius={3}
                          border={1}
                          width={"70px"}
                          margin={"auto"}
                          // height="20px"
                          onClick={() =>
                            dispatch(
                              setoccasionStatusModal({
                                isOpen: true,
                                ...row,
                              })
                            )
                          }
                          style={{ cursor: "pointer" }}
                        >
                          {row[column.id]}
                        </Box>
                      </Fragment>
                    ) : title === "occasions" && column.id === "name" ? (
                      <div
                        onClick={() => navigate(`/theme/${row.id}`)} // navigatino function to the single occasion
                        style={{
                          textDecoration: "none",
                          color: "rgba(108, 62, 185, 1)",
                          cursor: "pointer",
                        }}
                      >
                        <Typography fontSize={13}>{row[column.id]}</Typography>
                      </div>
                    ) : title === "occasions" && column.id === "delete" ? (
                      <Box
                        bgcolor={"rgba(255, 74, 74, 0.08)"}
                        display={"flex"}
                        alignItems={"center"}
                        p={"2px"}
                        borderRadius={1}
                        style={{ cursor: "pointer" }}
                        width={"fit-content"}
                        onClick={() =>
                          dispatch(
                            setDeleteOccasionModal({
                              isOpen: true,
                              ...row,
                            })
                          )
                        }
                      >
                        <img src={deleteIcon} alt="trash" width={15} />
                      </Box>
                    ) : (
                      <Typography fontSize={13}>{row[column.id]}</Typography>
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <Typography
          width={"100%"}
          textAlign={"center"}
          color="#A3A3A3"
          mt={2}
        >
          No Occasions created
        </Typography>
      )}
    </Fragment>
  );
};

export default OccasionTable;
