import { Box, Typography } from "@mui/material";
import React from "react";
import { VictoryAxis, VictoryBar, VictoryChart } from "victory";


const BarGraph = ({ md, barData }: any) => {
  // Calculate the minimum and maximum values of Y-axis from your data
  const minY = Math.min(...barData.map((item: any) => item.y));
  const maxY = Math.max(...barData.map((item: any) => item.y));

  // Generate ticks dynamically based on the data range
  const ticks = Array.from({ length: maxY - minY + 1 }, (_, index) => minY + index);

  return (
    <Box
      style={{
        width: "100%",
        height: "270px",
        background: "white",
        // padding: "8px 0px",
        borderRadius: "10px",
      }}
    >

      <VictoryChart
        singleQuadrantDomainPadding={{ x: true }}
        domainPadding={10}
        domain={{ x: [0.6, 7] }}
        padding={{ top: 0, bottom: 60, left: !md ? 35 : 0, right: 0 }}
        width={580}
      >
        <VictoryAxis style={{ axis: { stroke: "transparent" } }} />
        <VictoryAxis
          dependentAxis
          tickValues={ticks}
          style={{
            axis: { stroke: "transparent" },
            grid: { stroke: "lightgrey", strokeDasharray: "4, 5" },
          }}
        />
        <VictoryBar
          data={barData}
          barWidth={35}
          x="x"
          y="y"
          style={{
            data: { fill: "rgba(108, 62, 185, 1)", borderEndStartRadius: 15 },
          }}
          cornerRadius={{ topRight: 5, topLeft: 5 }}
        />
      </VictoryChart>
    </Box>
  );
};

export default BarGraph;
