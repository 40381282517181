import { Box, Typography } from "@mui/material";
import React, { Fragment } from "react";
import deleteIcon from "../../assets/svgs/trash.svg";
import editIcon from "../../assets/svgs/editIcon.svg"
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  setEditOccasionModal,
  setDeleteOccasionModal,
  setoccasionStatusModal,
  setSuccessfullEditModal,
} from "../../store/ThemesReducer";
import EditOccasionModal from "./EditOccasionModel";
import { useParams } from "react-router-dom";
import { useMediaQueryHook } from "../../hooks/useMediaQueryHook";
import CustomSuccesfulModal from "../../components/CustomSuccesfulModal";
const OccasionCard = () => {
  const { md } = useMediaQueryHook();
  const { occasionDetails, successfullEditModal } = useAppSelector((state) => state.Themes);
  console.log('occasionDetails: ', occasionDetails);
  const dispatch = useAppDispatch();
  const { id } = useParams();
  return (
    <Fragment>
      <EditOccasionModal />
      <CustomSuccesfulModal
        isOpen={successfullEditModal}
        onClose={() => dispatch(setSuccessfullEditModal(false))}
        title="Successful"
        content={`The Occasion has been Updated`}
      />
      <Box
        bgcolor={"white"}
        p={!md ? 2 : 4}
        width={!md ? "91%" : "50%"}
        borderRadius={2}
      >
        <Box
          display={!md ? "block" : "flex"}
          gap={3}
          alignItems={"center"}
          bgcolor={"#f8f8f8"}
          p={3}
          borderRadius={2}
        >
          <Box>
            <img
              src={occasionDetails?.Occasion_detail?.thumbnail_url}
              alt="thumnail"
              width={"70px"}
              height={"70px"}
              style={{
                borderRadius: "50%",
              }}
            />
          </Box>
          <Box width={"80%"}>
            <Box display={"flex"} justifyContent={"space-between"} width={"100%"}>
              <Typography
                fontSize={"18px"}
                color={"rgba(108, 62, 185, 1)"}
                fontWeight={"bold"}
              >
                {occasionDetails?.Occasion_detail?.name}
              </Typography>
              <Box display={"flex"} gap={3}>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  px={"15px"}
                  py={"1px"}
                  fontSize={"12px"}
                  borderRadius={3}
                  bgcolor={
                    occasionDetails?.Occasion_detail?.status === "Active"
                      ? "rgba(230, 255, 233, 1)"
                      : "rgba(255, 74, 74, 0.08)"
                  }
                  color={
                    occasionDetails?.Occasion_detail?.status === "Active"
                      ? "rgba(0, 171, 17, 1)"
                      : "rgba(255, 74, 74, 1)"
                  }
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    dispatch(
                      setoccasionStatusModal({
                        isOpen: true,
                        status: occasionDetails?.Occasion_detail?.status,
                        id,
                      })
                    );
                  }}
                >
                  {occasionDetails?.Occasion_detail?.status}
                </Box>
                <Box
                  bgcolor={"rgba(255, 74, 74, 0.08)"}
                  display={"flex"}
                  alignItems={"center"}
                  p={"2px"}
                  borderRadius={1}
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    dispatch(
                      setDeleteOccasionModal({
                        isOpen: true,
                        id,
                      })
                    )
                  }
                >
                  <img src={deleteIcon} alt="trash" width={15} />
                </Box>
                <Box
                  bgcolor={"rgba(255, 74, 74, 0.08)"}
                  display={"flex"}
                  alignItems={"center"}
                  p={"2px"}
                  borderRadius={1}
                  style={{ cursor: "pointer" }}
                  // onClick={() =>
                  //   dispatch(
                  //     setDeleteOccasionModal({
                  //       isOpen: true,
                  //       id,
                  //     })
                  //   )
                  // }
                  onClick={() => dispatch(setEditOccasionModal(true))}
                >
                  <img src={editIcon} alt="edit" width={15} />
                </Box>
              </Box>
            </Box>
            <Box display={"flex"} gap={2} marginTop={2}>
              <Box>
                <Typography fontSize={"12px"} color={"rgba(48, 48, 48, 0.48)"}>
                  Themes
                </Typography>
                <Typography
                  fontSize={"12px"}
                  textAlign={"center"}
                  fontWeight={600}
                >
                  {occasionDetails?.Themes?.length}
                </Typography>
              </Box>
              <Box>
                <Typography fontSize={"12px"} color={"rgba(48, 48, 48, 0.48)"}>
                  Messages
                </Typography>
                <Typography
                  fontSize={"12px"}
                  textAlign={"center"}
                  fontWeight={600}
                >
                  {occasionDetails?.Messages?.length}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

export default OccasionCard;
