import React, { Fragment } from "react";
import NotificationCard from "./NotificationCard";
import { Box, Button, Typography } from "@mui/material";
import { useMediaQueryHook } from "../../hooks/useMediaQueryHook";
import { useAppDispatch, useAppSelector } from "../../hooks";
import HelperModule from "../../helpers/HelperModule";
import ApiServices from "../../helpers/ApiServices";
import { setNotifications } from "../../store/ProfileReducer";

const Notifications = () => {
  const { md } = useMediaQueryHook();
  const dispatch = useAppDispatch();
  const { notifications } = useAppSelector((state) => state.Profile);
  const sortData = (data: any) => {
    const sortedData = data.sort((a: any, b: any) => {
      const dateA: any = new Date(a.createdAt);
      const dateB: any = new Date(b.createdAt);
      return dateB - dateA;
    });
    return sortedData;
  };
  const readNotifications = async () => {
    let token = sessionStorage.getItem("token");

    try {
      const response = await HelperModule().patchMethod(
        ApiServices.base_URL + ApiServices.notifications,
        {},
        { Authorization: token }
      );
      if (response.success) {
        dispatch(setNotifications(sortData(response.data)));
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Fragment>
      <Box
        marginTop={11}
        minHeight={"85vh"}
        padding={!md ? 1 : 0}
        overflow={"scroll"}
        bgcolor={"white"}
        width={!md ? '95%' : "85%"}
        p={!md ? 1 : 2}
        borderRadius={2}
        className="hide-scrollbar"
      >
        <Box display={"flex"} justifyContent={"space-between"}>
          <Typography>Notifications</Typography>
          <Button
            sx={{
              bgcolor: "rgba(242, 234, 255, 1)",
              textTransform: "none",
              padding: "4px",
              width: "20%",
              color: "#6C3EB9",
              fontSize: "12px",
              "&:hover": {
                bgcolor: "rgba(242, 234, 255, 1)",
                border: "none",
              },
              "&:focus": {
                outline: "none",
              },
            }}
            onClick={readNotifications}
          >
            Mark All as Read
          </Button>
        </Box>
        {notifications.map((notification: any, ind: number) => (
          <NotificationCard notification={notification} />
        ))}
      </Box>
    </Fragment>
  );
};

export default Notifications;
