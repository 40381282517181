import { Box, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useMediaQueryHook } from "../../hooks/useMediaQueryHook";
import PictureComponent from "../../components/PictureComponent";
import { friends } from "./assets";
import { useNavigate } from "react-router-dom";
import ButtonAuth from "../../components/ButtonAuth";
import { emailSchema } from "../../helpers/loginValidatoin";
import HelperModule from "../../helpers/HelperModule";
import ApiServices from "../../helpers/ApiServices";
import { enqueueSnackbar } from "notistack";

const ForgotPassword = () => {
  const { md, sm } = useMediaQueryHook();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  const [validEmail, setValidEmail] = useState({
    email: null,
  });
  const handleEmailChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setEmail(e.target.value);
  };
  const validateEmail = () => {
    try {
      emailSchema.parse({ email });
      setValidEmail({ email: null });
      return true;
    } catch (error: any) {
      if (error.formErrors) {
        setValidEmail(error.formErrors.fieldErrors);
        return false;
      }
    }
  };
  const handleSendOtp = async () => {
    if (validateEmail()) {
      try {
        const response = await HelperModule().postMethod(
          ApiServices.base_URL + ApiServices.send_otp,
          { email }
        );
        if (response.success) {
          enqueueSnackbar("OTP sent Successfully", {
            variant: "success",
            anchorOrigin: { vertical: "top", horizontal: "right" },
            style: { fontFamily: "Poppins", fontWeight: "500" },
          });
          navigate("/otp_verification", { state: { email } });
        }
        else {
          enqueueSnackbar(response.response.data.message, {
            variant: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
            style: { fontFamily: "Poppins", fontWeight: "500" },
          });
        }
      } catch (error) {
        console.error(error);
      }
    }
  };
  const handleKeyPress = (e: any) => {
    if (e.key === "Enter") {
      // Trigger handleLogin when Enter key is pressed
      handleSendOtp();
    }
  };
  return (
    <Box
      display="flex"
      minHeight={!md ? "50%" : "100vh"}
      flexDirection={!md ? "column" : "row"}
    >
      <PictureComponent md={md} imgSrc={friends} />
      <Box
        paddingRight={!md ? 0 : 1}
        width={!md ? "100%" : "55%"}
        minHeight={!md ? "50%" : "100vh"}
        sx={{
          background: "#FFF",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          // paddingTop: !md ? 0 : "5rem",
          margin: "auto",
          marginTop: !md ? "25px" : "0",
          paddingY: !sm ? 'auto' : !md ? '10px' : 'auto'
        }}
      >
        <Box width={!md ? "90%" : "70%"}>
          <Box
            sx={{
              width: "100%",
              textAlignLast: !md ? "center" : "left",
            }}
          >
            <Typography
              style={{
                fontSize: "22px",
                color: "rgba(16, 81, 142, 1)",
              }}
            >
              Forgot Password
            </Typography>
            <Typography
              sx={{
                color: "#737373",
                marginTop: "0.4rem",
                marginBottom: "1rem",
                fontSize: "0.9rem",
                width: !md ? "100%" : "90%",
              }}
            >
              Enter your email and we'll send you a code to get back into your
              account.
            </Typography>
          </Box>
          <Box
            mt={1}
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "0.6rem",
              marginTop: !md ? "10px" : "20px",
            }}
          >
            <TextField
              placeholder="Enter your Email"
              fullWidth
              value={email}
              onChange={handleEmailChange}
              variant="outlined"
              name="email"
              type="email"
              sx={{
                // width: !md ? "23rem" : "30rem",
                borderRadius: "0.4rem",
              }}
              InputProps={{
                style: { borderRadius: 14, padding: !sm ? 2 : !md ? 4 : 0 },
              }}
              onKeyDown={handleKeyPress}
            />
            {validEmail?.email && (
              <Typography style={{ color: "red", fontSize: "13px" }}>
                {validEmail?.email[0]}
              </Typography>
            )}
          </Box>
          <ButtonAuth md={md} text="Next" onClick={handleSendOtp} />
        </Box>
      </Box>
    </Box>
  );
};

export default ForgotPassword;
