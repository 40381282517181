import React from "react";
import { Box, Typography, Button, Modal } from "@mui/material";
import { useMediaQueryHook } from "../hooks/useMediaQueryHook";

interface CustomDialogProps {
  open: boolean;
  onClose?: () => void;
  title: string;
  content: string;
  confirmText: string;
  cancelText: string;
  onConfirm: () => void;
}
const CustomConfirmModal: React.FC<CustomDialogProps> = ({
  open,
  onClose,
  title,
  content,
  confirmText,
  cancelText,
  onConfirm,
}) => {
  const { md } = useMediaQueryHook();
  return (
    <Modal open={open} onClose={onClose}>
      {/* <DialogContent> */}
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 3,
          minWidth: !md ? 300 : 400,
          maxWidth: 600,
          borderRadius: 2,
        }}
      >
        <Box padding={2}>
          <Box padding={1}>
            <Typography
              style={{
                textAlign: "center",
                fontSize: "22px",
                fontWeight: "bold",
                color: "#FF7676",
              }}
            >
              {title}
            </Typography>
            <Typography padding={1.5} textAlign={"center"}>
              {content}
            </Typography>
            <Box
              margin="auto"
              width={"80%"}
              display="flex"
              justifyContent={"space-between"}
            >
              <Button
                onClick={onConfirm}
                sx={{
                  bgcolor: "rgba(242, 234, 255, 1)",
                  textTransform: "none",
                  padding: "2px",
                  width: "45%",
                  color: "#6C3EB9",
                  "&:hover": {
                    bgcolor: "rgba(242, 234, 255, 1)",
                    border: "none",
                  },
                  "&:focus": {
                    outline: "none",
                  },
                }}
              >
                {confirmText}
              </Button>
              <Button
                onClick={onClose}
                sx={{
                  border: "1.5px solid #6C3EB9",
                  textTransform: "none",
                  padding: "2px",
                  width: "45%",
                  color: "#6C3EB9",
                  "&:hover": {
                    bgcolor: "transparent",
                    border: "1.5px solid #6C3EB9",
                  },
                  "&:focus": {
                    outline: "none",
                  },
                }}
              >
                {cancelText}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* </DialogContent> */}
    </Modal>
  );
};

export default CustomConfirmModal;
