import { Box, Button, Pagination, Typography } from "@mui/material";
import React, { Fragment, useEffect } from "react";
import addIcon from "../../assets/svgs/plus.svg";
import { useMediaQueryHook } from "../../hooks/useMediaQueryHook";
import OccasionTable from "./OccasionTable";
import {
  setAddOccasionModal,
  setDeleteOccasionModal,
  setDeleteOccasionSuccessModal,
  setOccasionList,
  setoccasionStatusModal,
  setOccasionSuccessStatusModal,
  setSuccessfullAddModal,
} from "../../store/ThemesReducer";
import { useAppDispatch, useAppSelector } from "../../hooks";
import AddOccasionModal from "./AddOccasionModal";
import HelperModule from "../../helpers/HelperModule";
import ApiServices from "../../helpers/ApiServices";
import CustomSuccesfulModal from "../../components/CustomSuccesfulModal";
import CustomConfirmModal from "../../components/CustomConfirmModal";
import SearchBar from "../../components/SearchBar";

const columns = [
  { id: "id", label: "S.NO" },
  { id: "name", label: "Occasion" },
  { id: "messages", label: "Messages" },
  { id: "themes", label: "Themes" },
  { id: "status", label: "Status" },
  { id: "delete", label: "" },
];

const Themes = () => {
  const { md } = useMediaQueryHook();
  const dispatch = useAppDispatch();
  const { occasionList } = useAppSelector((state) => state.Themes);
  const {
    successfullAddModal,
    occasionStatusModal,
    occasionSuccessStatusModal,
    deleteOccasionModal,
    deleteOccasionSuccessModal,
  } = useAppSelector((state) => state.Themes);

  const [searchItem, setSearchItem] = React.useState<any>('')
  const [pagination, setPagination] = React.useState<any>({})
  const [currentPage, setCurrentPage] = React.useState<any>(1)

  useEffect(() => {
    getOccasionList();
  }, [successfullAddModal, occasionStatusModal, currentPage, searchItem]);

  const handleChange = (event: any, value: any) => {
    setCurrentPage(value);
  };
  const handleInput = (query: any) => {
    setSearchItem(query.toLowerCase());
  };
  const getOccasionList = async () => {
    const token = sessionStorage.getItem("token");
    try {
      let response = await HelperModule().getMethodWithHeaders(
        ApiServices.base_URL + ApiServices.get_occasions + `?page=${currentPage}&search=${searchItem}`,
        { Authorization: token }
      );
      if (response.success) {
        dispatch(setOccasionList(response.data));
        setPagination(response.pagination)
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteOccasion = async (id: any) => {
    let token = sessionStorage.getItem("token");
    try {
      let response = await HelperModule().deleteMethod(
        ApiServices.base_URL + `occasion?id=${id}`,
        { Authorization: token }
      );
      if (response.success) {
        getOccasionList();
        dispatch(setDeleteOccasionModal({ isOpen: false }));
        dispatch(setDeleteOccasionSuccessModal(true));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateOccasionStatus = async () => {
    let token = sessionStorage.getItem("token");
    const payload = {
      id: occasionStatusModal.id,
      status: occasionStatusModal.status === "Active" ? "Inactive" : "Active",
    };

    try {
      let response = await HelperModule().patchMethodHeaders(
        ApiServices.base_URL + ApiServices.update_occasion_status,
        payload,
        { Authorization: token }
      );
      if (response.success) {
        dispatch(setoccasionStatusModal({ isOpen: false, ...response.data }));
        dispatch(setOccasionSuccessStatusModal(true));
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  return (
    <Fragment>
      <AddOccasionModal />
      <CustomSuccesfulModal
        isOpen={successfullAddModal}
        onClose={() => dispatch(setSuccessfullAddModal(false))}
        title="Successful"
        content="The Occasion had added successfully"
      />

      {/* for update the status of the occasion */}
      <CustomConfirmModal
        open={occasionStatusModal.isOpen}
        onClose={() => dispatch(setoccasionStatusModal({ isOpen: false }))}
        title="Confirm"
        content={`Are you sure ${occasionStatusModal.status === "Active" ? "inactive" : "active"
          } this Occasion?`}
        confirmText="Yes"
        cancelText="No"
        onConfirm={updateOccasionStatus} // trigger change status of the user API
      />

      {/* update status Success modal */}
      <CustomSuccesfulModal
        isOpen={occasionSuccessStatusModal}
        onClose={() => dispatch(setOccasionSuccessStatusModal(false))}
        title="Successful"
        content={`The Occasion has been ${occasionStatusModal.status}`}
      />

      {/* for delete the occasion */}
      <CustomConfirmModal
        open={deleteOccasionModal.isOpen}
        onClose={() => dispatch(setDeleteOccasionModal({ isOpen: false }))}
        title="Delete"
        content="Are you sure you want to delete?"
        confirmText="Yes"
        cancelText="No"
        onConfirm={() => deleteOccasion(deleteOccasionModal.id)}
      />

      {/* Delete Occasion success modal */}
      <CustomSuccesfulModal
        isOpen={deleteOccasionSuccessModal}
        onClose={() => dispatch(setDeleteOccasionSuccessModal(false))}
        title="Successful"
        content={`The Occasion has been deleted`}
      />

      <Box
        // display={!md ? "block" : "flex"}
        marginTop={11}
        minHeight={"85vh"}
        padding={!md ? 1 : 0}
        overflow={"scrollable"}
      >
        <Button
          variant="outlined"
          sx={{
            // marginTop: "10px",
            bgcolor: "rgb(108,62,185)",
            textTransform: "none",
            color: "#fff",
            border: "none",
            "&:hover": {
              bgcolor: "rgb(82, 40, 153)", // Maintain the same background color on hover
              border: "none",
            },
            "&:focus": {
              outline: "none", // Remove the focus outline
            },
          }}
          onClick={() => dispatch(setAddOccasionModal(true))}
          startIcon={<img src={addIcon} alt="add" width={20} />}
        >
          Add Occasion
        </Button>
        <Box
          width={!md ? "99%" : "95%"}
          mt={2}
          bgcolor={"white"}
          p={!md ? 0.5 : 2}
          borderRadius={1.5}
        >
          <Box display="flex" justifyContent={"space-between"} alignItems={'center'}>
            <Box width={'50%'}>
              <Typography fontSize={!md ? '12px' : '16px'}>Occasion List ({occasionList.length})</Typography>
            </Box>
            <Box width={!md ? '40%' : '25%'}>

              <SearchBar placeholder="Search" onChange={handleInput} />
            </Box>
          </Box>
          <Box width={'100%'} overflow={'scroll'} className="hide-scrollbar">
            <OccasionTable columns={columns} title="occasions" />
          </Box>
          <Pagination
            count={
              pagination.totalPages
            }
            page={currentPage}
            onChange={handleChange}
          />
        </Box>
      </Box>
    </Fragment>
  );
};

export default Themes;
