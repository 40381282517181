import React from "react";
import { useMediaQueryHook } from "../../hooks/useMediaQueryHook";
import { Box, Typography } from "@mui/material";
import PictureComponent from "../../components/PictureComponent";
import friends from "../../assets/freinds.png";
import Otp from "./OtpInput";
import { Link, useLocation } from "react-router-dom";
const OtpVerification = () => {
  const { md } = useMediaQueryHook();
  const location = useLocation();
  const [profileDetails, setProfileDetails] = React.useState<any>({})
  // const profileDetails = JSON.parse(localStorage.getItem('profile_details') || '');
  const email = location?.state?.email;
  // const email = location.state;
  React.useEffect(() => {
    let profileDetails = localStorage.getItem('profile_details')
    if (profileDetails) {
      setProfileDetails(JSON.parse(profileDetails))
    }
  }, [])
  return (
    <Box
      display="flex"
      minHeight="100vh"
      flexDirection={!md ? "column" : "row"}
    >
      <PictureComponent md={md} imgSrc={friends} />
      <Box
        paddingRight={!md ? 0 : 1}
        width={!md ? "100%" : "55%"}
        sx={{
          background: "#FFF",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          // paddingTop: !md ? 0 : "5rem",
          marginTop: !md ? "25px" : "",
        }}
      >
        <Box width={!md ? "90%" : "70%"}>
          <Box
            sx={{
              width: "100%",
              textAlignLast: !md ? "center" : "left",
            }}
          >
            <Typography
              style={{
                fontSize: "22px",
                color: "rgba(16, 81, 142, 1)",
              }}
            >
              OTP Verification
            </Typography>
            <Typography
              sx={{
                color: "#737373",
                marginTop: "0.4rem",
                marginBottom: "1rem",
                fontSize: "0.9rem",
                width: !md ? "100%" : "90%",
                textAlign: !md ? "center" : "left",
              }}
            >
              Enter the verification code we sent to{" "}
              <b>{email ? email : profileDetails?.email}</b>{" "}
              <span>
                <Link to="/forgot">Edit</Link>
              </span>
            </Typography>
          </Box>
          <Otp email={email ? email : profileDetails?.email} />
        </Box>
      </Box>
    </Box>
  );
};

export default OtpVerification;
