import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Login from "../pages/login/Login";
import ForgotPassword from "../pages/login/ForgotPassword";
import OtpVerification from "../pages/login/OtpVerfication";
import ResetPassword from "../pages/login/ResetPassword";
import { useAppSelector } from "../hooks";

const AuthRoutes = () => {
  const emailChange = localStorage.getItem("emailChange");
  const passwordChange = localStorage.getItem("passwordChange");
  return (
    <>
      <Routes>
        <Route path="/otp_verification" element={<OtpVerification />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot" element={<ForgotPassword />} />
        <Route path="/reset_password" element={<ResetPassword />} />
        <Route path="/" element={<Navigate to={"/login"} replace={true} />} />
        {emailChange ? (
          <Route
            path="*"
            element={<Navigate to={"/otp_verification"} replace={true} />}
          />
        ) : (
          <Route path="*" element={<Navigate to={"/login"} replace={true} />} />
        )}
        {passwordChange ? (
          <Route
            path="*"
            element={<Navigate to={"/otp_verification"} replace={true} />}
          />
        ) : (
          <Route path="*" element={<Navigate to={"/login"} replace={true} />} />
        )}
      </Routes>
    </>
  );
};

export default AuthRoutes;
